import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** Email Custom scalar type */
  Email: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptQuotationInput = {
  message: Scalars['String'];
  terms: Scalars['Boolean'];
  address?: Maybe<ReservationAddress>;
};

export type AccountBlockedError = {
  __typename?: 'AccountBlockedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type AccountingCode = {
  __typename?: 'AccountingCode';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  hasCommission: Scalars['Boolean'];
  isContra: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type AccountingCodeFilterInput = {
  enabled?: Maybe<Scalars['Boolean']>;
};

export type AddHandoverFileInput = {
  type: HandoverFilesEnum;
  fileId: Scalars['String'];
  reading?: Maybe<Scalars['Float']>;
  terms?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
};

export type AddNoteResult = InvalidHandoverError | HandoverAccessError | HandoverNote;

export type AddNoteToHandoverInput = {
  type: NoteTypeEnum;
  fileId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type AddReservationLineItemInput = {
  accountId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  description: Scalars['String'];
  contraId?: Maybe<Scalars['String']>;
};

export type AddReservationNotesInput = {
  reservationId: Scalars['String'];
  note: Scalars['String'];
  sendNotification: Scalars['Boolean'];
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type AddReservationTransactionInput = {
  amount: Scalars['Int'];
  type: TransactionType;
  reason: Scalars['String'];
  transactionDate: Scalars['DateTime'];
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressTypes>;
  postalCode?: Maybe<Scalars['String']>;
};

export type AddressDto = {
  streetNumber?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  placeFormattedAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type AddressInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  addressType?: Maybe<AddressTypes>;
};

/** Address types */
export enum AddressTypes {
  Home = 'Home',
  Work = 'Work',
  Other = 'Other'
}

export type AggregateHandoverNote = {
  __typename?: 'AggregateHandoverNote';
  count: Scalars['Float'];
};

export type AggregateProfileNote = {
  __typename?: 'AggregateProfileNote';
  count: Scalars['Float'];
};

export type AggregateReservation = {
  __typename?: 'AggregateReservation';
  count: Scalars['Float'];
};

export type AggregateReservationNote = {
  __typename?: 'AggregateReservationNote';
  count: Scalars['Float'];
};

export type AggregateReview = {
  __typename?: 'AggregateReview';
  count: Scalars['Float'];
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  count: Scalars['Int'];
};

export type AggregateVehicle = {
  __typename?: 'AggregateVehicle';
  count: Scalars['Float'];
  totalPages?: Maybe<Scalars['Float']>;
  currentPage?: Maybe<Scalars['Float']>;
};

export type Amount = {
  __typename?: 'Amount';
  currencyCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountFloat?: Maybe<Scalars['Float']>;
  formatted?: Maybe<Scalars['String']>;
  formattedNoDecimals?: Maybe<Scalars['String']>;
  amountInCents?: Maybe<Scalars['Int']>;
};

export type AppproveHandoverFileResult = InvalidHandoverError | HandoverAccessError | HandoverFileNotFoundError | HandoverFile;

export type ApproveUserInput = {
  field: RentalProfiles;
  status: UserStatus;
  reason: Scalars['String'];
};

export type ApproveVehicleImageInput = {
  ids: Array<Scalars['String']>;
};

export type AuthTokens = {
  __typename?: 'AuthTokens';
  accessToken: Scalars['String'];
  accessTokenExpiry: Scalars['DateTime'];
  refreshToken: Scalars['String'];
  refreshTokenExpiry: Scalars['DateTime'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bankName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  accountName: Scalars['String'];
  branchCode: Scalars['String'];
};

export type BankAccountUpdate = {
  bankName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
};

export type BaseOutput = {
  __typename?: 'BaseOutput';
  success: Scalars['Boolean'];
};

export type BlockUserInput = {
  reason: Scalars['String'];
};

export type BookingNotAllowedError = {
  __typename?: 'BookingNotAllowedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type CrsMeta = {
  __typename?: 'CRSMeta';
  responseMessage: Scalars['String'];
  identityNumber: Scalars['String'];
  score: Scalars['Int'];
  risk: Scalars['String'];
  provider: Scalars['String'];
  verifiedAt: Scalars['DateTime'];
};

export type CancelationTimeHasAlreadyExpiredError = {
  __typename?: 'CancelationTimeHasAlreadyExpiredError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type CardToken = {
  __typename?: 'CardToken';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gateway: Scalars['String'];
  cardType: Scalars['String'];
  lastFourDigits: Scalars['String'];
  signature?: Maybe<Scalars['String']>;
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  expiryDate?: Maybe<Scalars['DateTime']>;
  default: Scalars['Boolean'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: TypeEnum;
};

export type City = {
  __typename?: 'City';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type?: Maybe<PlaceType>;
  address?: Maybe<Scalars['JSON']>;
};

export type ConfigArgDefinitionGql = {
  __typename?: 'ConfigArgDefinitionGQL';
  name: Scalars['String'];
  type: Scalars['String'];
  required: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type ConfigArgInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigArgs = {
  __typename?: 'ConfigArgs';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  code: Scalars['String'];
  args: Array<ConfigArgs>;
};

export type ConfigurableOperationDefinitionGql = {
  __typename?: 'ConfigurableOperationDefinitionGQL';
  code: Scalars['String'];
  description: Scalars['String'];
  args: Array<ConfigArgDefinitionGql>;
};

export type ConfigurableOperationInput = {
  code: Scalars['String'];
  arguments: Array<ConfigArgInput>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  couponCode: Scalars['String'];
  perCustomerUsageLimit: Scalars['Float'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  conditions?: Maybe<Array<ConfigurableOperation>>;
  actions?: Maybe<Array<ConfigurableOperation>>;
  priorityScore: Scalars['Float'];
};

export type CouponCodeInvalidError = {
  __typename?: 'CouponCodeInvalidError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
  couponCode: Scalars['String'];
};

export type CreateAccountInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  terms: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  countryCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  password: Scalars['String'];
};

export type CreateAccountingCodeInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  hasCommission: Scalars['Boolean'];
  isContra: Scalars['Boolean'];
};

export type CreateCardTokenInput = {
  gateway: Scalars['String'];
  cardType: Scalars['String'];
  cardNumber: Scalars['String'];
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  signature?: Maybe<Scalars['String']>;
  expiryDate: Scalars['DateTime'];
  default?: Maybe<Scalars['Boolean']>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  type: TypeEnum;
};

export type CreateCouponInput = {
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  couponCode: Scalars['String'];
  perCustomerUsageLimit: Scalars['Int'];
  conditions: Array<ConfigurableOperationInput>;
  actions: Array<ConfigurableOperationInput>;
};

export type CreateDisputeInput = {
  type?: Maybe<ZendeskTicketType>;
  subject: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Array<Scalars['String']>>;
  includeOthers?: Maybe<Scalars['Boolean']>;
};

export type CreateHandoverResult = ReservationNotFoundError | InvalidHandoverError | HandoverAccessError | Handover;

export type CreatePlaceInput = {
  address?: Maybe<AddressDto>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PlaceType>;
};

export type CreateReviewInput = {
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
  categories: Array<ReviewCategoriesInput>;
};

export type CreateVehicleCategoryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateVehicleDocumentInput = {
  type: VehicleDocumentTypeEnum;
  file: Scalars['Upload'];
  vehicleId: Scalars['ID'];
};

export type CreateVehicleFeatureInput = {
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type CreateVehicleInput = {
  name: Scalars['String'];
  licence_no: Scalars['String'];
  vin: Scalars['String'];
  categoryId: Scalars['String'];
  color: VehicleColor;
  description?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Int']>;
  transmission?: Maybe<VehicleTransmission>;
  featureIds?: Maybe<Array<Scalars['String']>>;
  transunionId: Scalars['String'];
  documents: Array<VehicleFilesUpload>;
  fueltype: VehicleFuel;
  tracker?: Maybe<Scalars['Boolean']>;
};

export type CreateVehicleNoteInput = {
  note: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  vehicleId: Scalars['String'];
  fileIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateVehiclePlaceInput = {
  streetNumber?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  placeFormattedAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  type?: Maybe<PlaceType>;
  enabled?: Maybe<Scalars['Boolean']>;
  deliveryFee?: Maybe<Scalars['Int']>;
};

export type CredentialsTakenError = {
  __typename?: 'CredentialsTakenError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type DailyAttributes = {
  __typename?: 'DailyAttributes';
  vehicleId: Scalars['String'];
  date: Scalars['DateTime'];
  isAvailable: Scalars['Boolean'];
  isBooked?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower?: Maybe<Scalars['DateTime']>;
  upper?: Maybe<Scalars['DateTime']>;
};

export type DateRangeFilter = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type DeleteHandoverResult = InvalidHandoverError | HandoverAccessError | DeletedResult;

export type DeleteNoteResult = InvalidHandoverError | HandoverAccessError;

export type DeleteProfileNoteResult = ProfileNoteNotFoundError | InvalidProfileNoteError | ProfileNote;

export type DeletedResult = {
  __typename?: 'DeletedResult';
  deleted: Scalars['Boolean'];
};

export enum DocumentType {
  Passport = 'PASSPORT',
  IdBook = 'ID_BOOK',
  IdCard = 'ID_CARD',
  Selfie = 'SELFIE',
  DriversLicense = 'DRIVERS_LICENSE',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  OtherDocument = 'OTHER_DOCUMENT'
}

export type DocumentVerificationFailure = {
  __typename?: 'DocumentVerificationFailure';
  error: Scalars['String'];
};

export type DocumentVerificationResponseUnion = BaseOutput | DocumentVerificationSuccess | DocumentVerificationFailure;

export type DocumentVerificationSuccess = {
  __typename?: 'DocumentVerificationSuccess';
  error: Scalars['String'];
};


export type EmailVerificationInput = {
  /** This is only neccessary where we have to resend the verification link */
  email?: Maybe<Scalars['Email']>;
};

export type ExportUsersInput = {
  status?: Maybe<UserStatus>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type ExportUsersResult = {
  __typename?: 'ExportUsersResult';
  message: Scalars['String'];
};

export type ExportVehicleResult = {
  __typename?: 'ExportVehicleResult';
  message: Scalars['String'];
};

export type FvsMeta = {
  __typename?: 'FVSMeta';
  verified: Scalars['Boolean'];
  verificationScore: Scalars['Float'];
  imageExists: Scalars['Boolean'];
  verifiedAt: Scalars['DateTime'];
};

export type FileEntity = {
  __typename?: 'FileEntity';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  meta?: Maybe<FileMetaType>;
  userId?: Maybe<Scalars['String']>;
};

export type FileMetaType = {
  __typename?: 'FileMetaType';
  ext?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type FileTypeInput = {
  fileType: HandoverFilesEnum;
};

export type ForbidenError = {
  __typename?: 'ForbidenError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['Email'];
};

export type ForgotPasswordResult = ForgotPasswordSuccess;

export type ForgotPasswordSuccess = {
  __typename?: 'ForgotPasswordSuccess';
  success: Scalars['Boolean'];
};

export type GetVehicleDailyInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type GetVehicleRecommendationPrice = {
  __typename?: 'GetVehicleRecommendationPrice';
  price: Scalars['Int'];
  min: Scalars['Int'];
  max: Scalars['Int'];
  price_label: Scalars['String'];
};

export type Handover = {
  __typename?: 'Handover';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: HandoverTypeEnum;
  files?: Maybe<Array<HandoverFile>>;
  status: HandoverStatus;
  startOdometer?: Maybe<Scalars['Float']>;
  endOdometer?: Maybe<Scalars['Float']>;
  startFuel?: Maybe<Scalars['String']>;
  endFuel?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  acceptIdentity?: Maybe<Scalars['Boolean']>;
  startRequirements: Array<Scalars['String']>;
  completeRequirements: Array<Scalars['String']>;
  checklist: Array<HandoverCheckList>;
  notes?: Maybe<HandoverNoteConnection>;
};


export type HandoverNotesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HandoverAccessError = {
  __typename?: 'HandoverAccessError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type HandoverCheckList = {
  __typename?: 'HandoverCheckList';
  name: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['Boolean'];
  required: Scalars['Boolean'];
  responsible: Scalars['String'];
  stage: Scalars['String'];
};

export type HandoverFile = {
  __typename?: 'HandoverFile';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: HandoverFilesEnum;
  handover: Handover;
  comment?: Maybe<Scalars['String']>;
  reading?: Maybe<Scalars['Float']>;
  file?: Maybe<FileEntity>;
  terms?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  isUser: Scalars['Boolean'];
};

export type HandoverFileNotFoundError = {
  __typename?: 'HandoverFileNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

/** describes the specific sections of the vehicle shown in the images */
export enum HandoverFilesEnum {
  FuelReceipt = 'FuelReceipt',
  FuelReceiptCheckout = 'FuelReceiptCheckout',
  FuelOdoDispute = 'FuelOdoDispute',
  FuelOdoDisputeCheckout = 'FuelOdoDisputeCheckout',
  FuelAndOdometer = 'FuelAndOdometer',
  FuelAndOdometerCheckout = 'FuelAndOdometerCheckout',
  DriverDetails = 'DriverDetails',
  DuringTrip = 'DuringTrip',
  VehicleImages = 'VehicleImages',
  VehicleImagesCheckout = 'VehicleImagesCheckout',
  Checklist = 'Checklist',
  ChecklistCheckout = 'ChecklistCheckout'
}

export type HandoverImage = {
  __typename?: 'HandoverImage';
  id: Scalars['String'];
  isOwner: Scalars['Boolean'];
  src: Scalars['String'];
};

export type HandoverNote = {
  __typename?: 'HandoverNote';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<NoteTypeEnum>;
  comment?: Maybe<Scalars['String']>;
  file?: Maybe<FileEntity>;
  user: User;
  isUser: Scalars['Boolean'];
};

export type HandoverNoteConnection = {
  __typename?: 'HandoverNoteConnection';
  pageInfo: PageInfo;
  edges: Array<HandoverNoteEdge>;
  aggregate: AggregateHandoverNote;
};

export type HandoverNoteEdge = {
  __typename?: 'HandoverNoteEdge';
  node: HandoverNoteWithUser;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type HandoverNoteWithUser = {
  __typename?: 'HandoverNoteWithUser';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  file?: Maybe<FileEntity>;
  profile?: Maybe<HandoverNotesUser>;
  url: Scalars['String'];
};

export type HandoverNotesUser = {
  __typename?: 'HandoverNotesUser';
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  isSelf: Scalars['Boolean'];
};

export type HandoverResult = InvalidHandoverError | Handover;

/** Stages in the Handover Process */
export enum HandoverStatus {
  Checkin = 'CHECKIN',
  Current = 'CURRENT',
  Checkout = 'CHECKOUT',
  Complete = 'COMPLETE',
  NoShow = 'NO_SHOW',
  Disputed = 'DISPUTED'
}

/** shows whether a handover is either by driver or owner */
export enum HandoverTypeEnum {
  DriverHandover = 'DRIVER_HANDOVER',
  OwnerHandover = 'OWNER_HANDOVER'
}

export type IvsMeta = {
  __typename?: 'IVSMeta';
  name: Scalars['String'];
  surname: Scalars['String'];
  photo: Scalars['String'];
  smartCardIssued: Scalars['String'];
  idNumberBlocked: Scalars['String'];
  deceasedStatus: Scalars['String'];
  maritalStatus: Scalars['String'];
  dateOfMarriage: Scalars['DateTime'];
  countryOfBirth: Scalars['String'];
  verifiedAt: Scalars['DateTime'];
};

export type ImageWithView = {
  fileId: Scalars['String'];
  view: Scalars['String'];
};

export type InvalidActionError = {
  __typename?: 'InvalidActionError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidCredentialsError = {
  __typename?: 'InvalidCredentialsError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidDurationCriteriaError = {
  __typename?: 'InvalidDurationCriteriaError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidHandoverError = {
  __typename?: 'InvalidHandoverError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidProfileNoteError = {
  __typename?: 'InvalidProfileNoteError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidReservationDatesError = {
  __typename?: 'InvalidReservationDatesError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidReservationError = {
  __typename?: 'InvalidReservationError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidVehicleCategoryError = {
  __typename?: 'InvalidVehicleCategoryError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidVehicleMethodError = {
  __typename?: 'InvalidVehicleMethodError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidVehicleSelectedError = {
  __typename?: 'InvalidVehicleSelectedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};


export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MetaInput = {
  whatsappMarketing?: Maybe<Scalars['Boolean']>;
  whatsappTransactional?: Maybe<Scalars['Boolean']>;
  additionalInfo?: Maybe<Scalars['JSON']>;
};

export type MinMax = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type ModelFilterInput = {
  /** Resticts search to vehicles manufatured after the year provided */
  after?: Maybe<Scalars['String']>;
  /** Restricts search to vehicles manufactured before this year */
  before?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: UserLoginResult;
  resendEmailVerification: User;
  verifyEmail: UserLoginResult;
  createAccount: UserRegisterResult;
  updateAccount: UserUpdateResult;
  forgotPassword: ForgotPasswordResult;
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  providerLogin: ProviderAuthResultUnion;
  providerRegister: ProviderAuthResultUnion;
  createPlace: City;
  updatePlace: City;
  deletePlace: Scalars['Boolean'];
  referFriends: Scalars['Boolean'];
  /** Download a csv export of users as per  the given filters */
  exportUsers?: Maybe<UsersExportResult>;
  removeUser: Scalars['Boolean'];
  updateUser: User;
  updateProfile: User;
  approveUser: User;
  blockUser: User;
  unblockUser: User;
  rejectUser: User;
  updateBankAccount: User;
  updateUserCommission: User;
  createCard: User;
  deleteCard: User;
  resubmitReview: User;
  creditCheck: UserResult;
  verifyFacialIdentity: UserResult;
  /** This a more general upload endpoint where we can upload any private file no matter the enitity type */
  uploadFile: FileEntity;
  /** This an upload endpoint where we can upload a public image file */
  uploadImage: FileEntity;
  createVehicle: VehicleResultUnion;
  updateVehicle: VehicleResultUnion;
  removeVehicle: VehicleResultUnion;
  addVehicleImage: VehicleResultUnion;
  approveListing: VehicleResultUnion;
  rejectListing: VehicleResultUnion;
  updateVehicleListingStatus: VehicleResultUnion;
  resubmitListing: Vehicle;
  enableVehicle: Vehicle;
  /** This mutation is best used when the vehicle entity already exists in the database */
  uploadMyVehicleDocument: Vehicle;
  updateVehicleDailyAttribute: Vehicle;
  /** Email csv export of vehicles */
  exportVehicles?: Maybe<ExportVehicleResult>;
  sendPaymentLink: StatusResponse;
  addReservationTransaction: StatusResponse;
  refundByReservationId: Transaction;
  chargeCardByReservationId: Transaction;
  generateReservationInvoice: Scalars['Boolean'];
  removeReservationLineItem: Scalars['Boolean'];
  requestReservation: ReservationResult;
  updateReservationPaymentDate: Reservation;
  updateReservationDates: ReservationResult;
  updatePaymentDate: ReservationResult;
  acceptQuote: ReservationResult;
  createPaymentReservation: PaymentResponse;
  rejectReservation: ReservationResult;
  approveReservation: ReservationResult;
  setIsDelivery: ReservationResult;
  setPaymentMethod: ReservationResult;
  setIsDeliveryAirport: ReservationResult;
  cancelReservation: ReservationResult;
  completeReservation: ReservationResult;
  updateReservationStatus: Reservation;
  applyCouponCode: ReservationResult;
  removeCouponCode: ReservationResult;
  changeReservationVehicle: Reservation;
  exportReservations: Scalars['Boolean'];
  uploadUserDocuments: DocumentVerificationResponseUnion;
  sendOtp: OtpResponseUnion;
  verifyOtp: OtpResponseUnion;
  resendOtp: OtpResponseUnion;
  uploadUserImage: FileEntity;
  deleteVehicleNote: VehicleNote;
  addVehicleNote: VehicleNote;
  approveVehicleImages: Array<VehicleImage>;
  rejectVehicleImages: Array<VehicleImage>;
  deleteVehicleImages: Scalars['Boolean'];
  createVehiclePlace: VehiclePlace;
  createVehiclePlaceByPlaceId: VehiclePlace;
  updateVehiclePlace: VehiclePlace;
  removeVehiclePlace: Scalars['Boolean'];
  createVehicleCategory: VehicleCategory;
  updateVehicleCategory: VehicleCategory;
  removeVehicleCategory: Scalars['Boolean'];
  createVehicleFeature: VehicleFeature;
  updateVehicleFeature: VehicleFeature;
  removeVehicleFeature: Scalars['Boolean'];
  createCategory: Category;
  updateCategory: Category;
  createReview: ReviewResult;
  updateReview: ReviewResult;
  /** deletes a review */
  deleteReview: Review;
  createHandover: CreateHandoverResult;
  updateHandover: UpdateHandoverResult;
  addHandoverFile: HandoverFile;
  deleteHandoverFile: DeleteHandoverResult;
  addNoteToHandover: AddNoteResult;
  updateHandoverNote: UpdateNoteResult;
  deleteHandoverNote: DeleteNoteResult;
  approveHandoverFile: AppproveHandoverFileResult;
  getNotesForFileType: NotesForFileTypeResult;
  createDispute: TicketResult;
  changeTripStatus: UpdateHandoverResult;
  /** Add a new reservation note */
  addReservationNote: ReservationNote;
  /** Update a reservation note */
  editReservationNote: ReservationNote;
  /** Delete a reservation note */
  deleteReservationNote: ReservationNote;
  createProfileNote: ProfileNoteResult;
  deleteProfileNote: DeleteProfileNoteResult;
  updateProfileNote: ProfileNote;
  createAccountingCode: AccountingCode;
  updateAccountingCode: AccountingCode;
  deleteAccountingCode: Scalars['Boolean'];
  addOrderLineItem: Array<OrderLineItem>;
  updateOrderLineItem: OrderLineItem;
  deleteOrderLineItem: Scalars['Boolean'];
  createCoupon: Coupon;
  updateCoupon: Coupon;
  removeCoupon: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationResendEmailVerificationArgs = {
  input?: Maybe<EmailVerificationInput>;
};


export type MutationVerifyEmailArgs = {
  verify: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationProviderLoginArgs = {
  input: ProviderLoginInput;
};


export type MutationProviderRegisterArgs = {
  input: ProviderRegisterInput;
};


export type MutationCreatePlaceArgs = {
  input: CreatePlaceInput;
};


export type MutationUpdatePlaceArgs = {
  input: UpdatePlaceInput;
  id: Scalars['String'];
};


export type MutationDeletePlaceArgs = {
  id: Scalars['String'];
};


export type MutationReferFriendsArgs = {
  input: ReferFriendsInput;
};


export type MutationExportUsersArgs = {
  input: ExportUsersInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  id: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};


export type MutationApproveUserArgs = {
  input: ApproveUserInput;
  id: Scalars['String'];
};


export type MutationBlockUserArgs = {
  input: BlockUserInput;
  id: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  id: Scalars['String'];
};


export type MutationRejectUserArgs = {
  input: ApproveUserInput;
  id: Scalars['String'];
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountUpdate;
  id: Scalars['String'];
};


export type MutationUpdateUserCommissionArgs = {
  commission: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationCreateCardArgs = {
  input: CreateCardTokenInput;
};


export type MutationDeleteCardArgs = {
  id: Scalars['String'];
};


export type MutationCreditCheckArgs = {
  userId: Scalars['String'];
};


export type MutationVerifyFacialIdentityArgs = {
  userId: Scalars['String'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput;
};


export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};


export type MutationRemoveVehicleArgs = {
  id: Scalars['String'];
};


export type MutationAddVehicleImageArgs = {
  file: Scalars['Upload'];
  id: Scalars['String'];
};


export type MutationApproveListingArgs = {
  id: Scalars['String'];
};


export type MutationRejectListingArgs = {
  reason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateVehicleListingStatusArgs = {
  status: VehicleStatus;
  id: Scalars['String'];
};


export type MutationResubmitListingArgs = {
  id: Scalars['String'];
};


export type MutationEnableVehicleArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationUploadMyVehicleDocumentArgs = {
  input: CreateVehicleDocumentInput;
};


export type MutationUpdateVehicleDailyAttributeArgs = {
  input: UpdateVehicleDailyAttributes;
  id: Scalars['String'];
};


export type MutationSendPaymentLinkArgs = {
  id: Scalars['String'];
};


export type MutationAddReservationTransactionArgs = {
  input: AddReservationTransactionInput;
  id: Scalars['String'];
};


export type MutationRefundByReservationIdArgs = {
  amount: Scalars['Float'];
  transactionId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationChargeCardByReservationIdArgs = {
  amount: Scalars['Float'];
  cardId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationGenerateReservationInvoiceArgs = {
  id: Scalars['String'];
};


export type MutationRemoveReservationLineItemArgs = {
  id: Scalars['String'];
};


export type MutationRequestReservationArgs = {
  input: RequestReservationInput;
};


export type MutationUpdateReservationPaymentDateArgs = {
  paymentDate: Scalars['DateTime'];
  reservationId: Scalars['String'];
};


export type MutationUpdateReservationDatesArgs = {
  input: UpdateReservationDatesInput;
  reservationId: Scalars['String'];
};


export type MutationUpdatePaymentDateArgs = {
  input: UpdatePaymentDateInput;
  reservationId: Scalars['String'];
};


export type MutationAcceptQuoteArgs = {
  input: AcceptQuotationInput;
  id: Scalars['String'];
};


export type MutationCreatePaymentReservationArgs = {
  input?: Maybe<PaymentMethodInput>;
  id: Scalars['String'];
};


export type MutationRejectReservationArgs = {
  input: ReservationApprovalInput;
};


export type MutationApproveReservationArgs = {
  input: ReservationApprovalInput;
};


export type MutationSetIsDeliveryArgs = {
  input: SetAddressInput;
  id: Scalars['String'];
};


export type MutationSetPaymentMethodArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};


export type MutationSetIsDeliveryAirportArgs = {
  id: Scalars['String'];
};


export type MutationCancelReservationArgs = {
  reason: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCompleteReservationArgs = {
  id: Scalars['String'];
};


export type MutationUpdateReservationStatusArgs = {
  input: ReservationStatusUpdateInput;
  id: Scalars['String'];
};


export type MutationApplyCouponCodeArgs = {
  couponCode: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCouponCodeArgs = {
  couponCode: Scalars['String'];
  id: Scalars['String'];
};


export type MutationChangeReservationVehicleArgs = {
  input: ReservationVehicleChangeInput;
  id: Scalars['String'];
};


export type MutationExportReservationsArgs = {
  input: ReservationReportsSearchInput;
};


export type MutationUploadUserDocumentsArgs = {
  input: VerifyDocumentInput;
};


export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};


export type MutationUploadUserImageArgs = {
  file: Scalars['Upload'];
};


export type MutationDeleteVehicleNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationAddVehicleNoteArgs = {
  input: CreateVehicleNoteInput;
  vehicleId: Scalars['String'];
};


export type MutationApproveVehicleImagesArgs = {
  input: ApproveVehicleImageInput;
};


export type MutationRejectVehicleImagesArgs = {
  input: ApproveVehicleImageInput;
};


export type MutationDeleteVehicleImagesArgs = {
  input: ApproveVehicleImageInput;
};


export type MutationCreateVehiclePlaceArgs = {
  input: CreateVehiclePlaceInput;
  vehicleId: Scalars['String'];
};


export type MutationCreateVehiclePlaceByPlaceIdArgs = {
  placeId: Scalars['String'];
  vehicleId: Scalars['String'];
};


export type MutationUpdateVehiclePlaceArgs = {
  input: UpdateVehiclePlaceInput;
  vehicleId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveVehiclePlaceArgs = {
  vehicleId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCreateVehicleCategoryArgs = {
  input: CreateVehicleCategoryInput;
};


export type MutationUpdateVehicleCategoryArgs = {
  input: UpdateVehicleCategoryInput;
};


export type MutationRemoveVehicleCategoryArgs = {
  id: Scalars['String'];
};


export type MutationCreateVehicleFeatureArgs = {
  input: CreateVehicleFeatureInput;
};


export type MutationUpdateVehicleFeatureArgs = {
  input: UpdateVehicleFeatureInput;
};


export type MutationRemoveVehicleFeatureArgs = {
  id: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  input: CreateCategoryInput;
  id: Scalars['String'];
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
  reservationId: Scalars['String'];
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
  reviewId: Scalars['String'];
};


export type MutationDeleteReviewArgs = {
  reservationId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCreateHandoverArgs = {
  reservationId: Scalars['String'];
};


export type MutationUpdateHandoverArgs = {
  input: UpdateHandoverInput;
  id: Scalars['String'];
};


export type MutationAddHandoverFileArgs = {
  input: AddHandoverFileInput;
  handoverId: Scalars['String'];
};


export type MutationDeleteHandoverFileArgs = {
  handoverFileId: Scalars['String'];
  handoverId: Scalars['String'];
};


export type MutationAddNoteToHandoverArgs = {
  input: AddNoteToHandoverInput;
  handoverId: Scalars['String'];
};


export type MutationUpdateHandoverNoteArgs = {
  input: UpdateNoteInput;
  handoverNoteId: Scalars['String'];
  handoverId: Scalars['String'];
};


export type MutationDeleteHandoverNoteArgs = {
  handoverNoteId: Scalars['String'];
  handoverId: Scalars['String'];
};


export type MutationApproveHandoverFileArgs = {
  handoverFileId: Scalars['String'];
  handoverId: Scalars['String'];
};


export type MutationGetNotesForFileTypeArgs = {
  input: FileTypeInput;
  handoverId: Scalars['String'];
};


export type MutationCreateDisputeArgs = {
  input: CreateDisputeInput;
  handoverId: Scalars['String'];
};


export type MutationChangeTripStatusArgs = {
  status: HandoverStatus;
  handoverId: Scalars['String'];
};


export type MutationAddReservationNoteArgs = {
  input: AddReservationNotesInput;
};


export type MutationEditReservationNoteArgs = {
  input: UpdateReservationNoteInput;
};


export type MutationDeleteReservationNoteArgs = {
  id: Scalars['String'];
  reservationId: Scalars['String'];
};


export type MutationCreateProfileNoteArgs = {
  input: ProfileNoteInput;
};


export type MutationDeleteProfileNoteArgs = {
  profileNoteId: Scalars['String'];
};


export type MutationUpdateProfileNoteArgs = {
  input: UpdateProfileNoteInput;
  profileNoteId: Scalars['String'];
};


export type MutationCreateAccountingCodeArgs = {
  input: CreateAccountingCodeInput;
};


export type MutationUpdateAccountingCodeArgs = {
  input: UpdateAccountingCodeInput;
  id: Scalars['String'];
};


export type MutationDeleteAccountingCodeArgs = {
  id: Scalars['String'];
};


export type MutationAddOrderLineItemArgs = {
  input: AddReservationLineItemInput;
  reservationId: Scalars['String'];
};


export type MutationUpdateOrderLineItemArgs = {
  input: UpdateReservationLineItemInput;
  id: Scalars['String'];
};


export type MutationDeleteOrderLineItemArgs = {
  id: Scalars['String'];
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
  id: Scalars['String'];
};


export type MutationRemoveCouponArgs = {
  id: Scalars['String'];
};

export type NoEmailFoundOnProfileError = {
  __typename?: 'NoEmailFoundOnProfileError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type NoHandoverNotesFoundError = {
  __typename?: 'NoHandoverNotesFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type Node = {
  nodeId: Scalars['ID'];
};

export type NoteProfile = {
  __typename?: 'NoteProfile';
  userId: Scalars['String'];
  fullName: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

/** Specifies whether the note provided is a dispute or comment on  the file */
export enum NoteTypeEnum {
  VehicleImages = 'VehicleImages',
  AdminNote = 'AdminNote',
  ChangeOfStatus = 'ChangeOfStatus'
}

export type NotesForFileTypeResult = InvalidHandoverError | HandoverAccessError | NoHandoverNotesFoundError | HandoverNote;

export enum OAuthProviderTypes {
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  reservation: Reservation;
  description: Scalars['String'];
  status: OrderLineItemStatus;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  isTaxable: Scalars['Boolean'];
  isCredit: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  accountingCode: AccountingCode;
  accountingCodeId: Scalars['String'];
  totalAmount?: Maybe<Scalars['Float']>;
  amount: Amount;
};

/** Order line item status */
export enum OrderLineItemStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export type OrderLineTotals = {
  __typename?: 'OrderLineTotals';
  balance: Amount;
  totalPaid: Amount;
  depositBalance: Amount;
  lineItemTotal: Amount;
  serviceTotal: Amount;
  payoutTotal: Amount;
  discountTotal: Amount;
  pricePerDayTotal: Amount;
  rentalTotal: Amount;
  insuranceTotal: Amount;
  deliveryTotal: Amount;
  reservationTotal: Amount;
};

export type OtpFailureOutput = {
  __typename?: 'OtpFailureOutput';
  error: Scalars['String'];
};

export type OtpNotFoundError = {
  __typename?: 'OtpNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type OtpResponseUnion = BaseOutput | OtpSuccessOutput | OtpFailureOutput | TooManyOtpAttemptsError | AccountBlockedError | OtpNotFoundError;

export type OtpSuccessOutput = {
  __typename?: 'OtpSuccessOutput';
  message?: Maybe<Scalars['String']>;
  fingerPrint?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  methodId: Scalars['String'];
  metadata: Scalars['JSON'];
  settle: Scalars['Boolean'];
};

export type PaymentMethodOutput = {
  __typename?: 'PaymentMethodOutput';
  name: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  channels: Array<Scalars['String']>;
  amount: Amount;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  method: Scalars['String'];
  metadata: Scalars['JSON'];
};

export type PickUpAndDropOffRequiredError = {
  __typename?: 'PickUpAndDropOffRequiredError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type PlaceSearchInput = {
  suburb?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  box?: Maybe<Array<Scalars['Float']>>;
};

export enum PlaceType {
  Pickup = 'pickup',
  Dropoff = 'dropoff',
  Address = 'address',
  Airport = 'airport',
  Hotel = 'hotel',
  TrainStation = 'trainStation',
  ShoppingMall = 'shoppingMall',
  Other = 'other'
}

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  isApprovedOwner: Scalars['Boolean'];
  isApprovedDriver: Scalars['Boolean'];
  commission?: Maybe<Scalars['Float']>;
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  consentAt?: Maybe<Scalars['DateTime']>;
  idnumberVerifiedAt?: Maybe<Scalars['DateTime']>;
  idnumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  altPhoneNumber?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  notificationsOptIn?: Maybe<Scalars['Boolean']>;
  whatsAppOtp?: Maybe<Scalars['Boolean']>;
  driversLicenseFirstIssued?: Maybe<Scalars['DateTime']>;
  ivs?: Maybe<IvsMeta>;
  /** An Iso Representation of The user country of origin/residence */
  country?: Maybe<Scalars['String']>;
  /** A passport number field for international users */
  passportNumber?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  altCountryCode?: Maybe<Scalars['String']>;
  fvs?: Maybe<FvsMeta>;
  crs: CrsMeta;
  recieveAdminEmails?: Maybe<Scalars['Boolean']>;
  additionalInfo?: Maybe<Scalars['JSON']>;
  addresses: Array<Address>;
  bio?: Maybe<Scalars['String']>;
  dailyReservationReport?: Maybe<Scalars['Boolean']>;
  referralCode?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
  profileNotes?: Maybe<Array<ProfileNote>>;
  isSAcitizen: Scalars['Boolean'];
  fullName: Scalars['String'];
  joined: Scalars['String'];
  joinDate: Scalars['String'];
  verifications?: Maybe<ProfileVerfications>;
};

export type ProfileNote = Node & {
  __typename?: 'ProfileNote';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nodeId: Scalars['ID'];
  note: Scalars['String'];
  profile: Profile;
  user: User;
  canReply?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<FileEntity>>;
};

export type ProfileNoteConnection = {
  __typename?: 'ProfileNoteConnection';
  pageInfo: PageInfo;
  edges: Array<ProfileNoteEdge>;
  aggregate: AggregateProfileNote;
};

export type ProfileNoteEdge = {
  __typename?: 'ProfileNoteEdge';
  node: ProfileNote;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ProfileNoteInput = {
  note: Scalars['String'];
  userId: Scalars['String'];
  canReply?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  sendNotification?: Maybe<Scalars['Boolean']>;
};

export type ProfileNoteNotFoundError = {
  __typename?: 'ProfileNoteNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ProfileNoteOrderByInput = {
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
};

export type ProfileNoteQueryInput = {
  profileId?: Maybe<Scalars['String']>;
};

export type ProfileNoteResult = ProfileNote | ProfileNoteNotFoundError | InvalidProfileNoteError | UnAuthorizedNoteActionError;

export type ProfileNoteWhereInput = {
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type ProfileUpdateInput = {
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  consent?: Maybe<Scalars['Boolean']>;
  idnumber?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  altPhoneNumber?: Maybe<Scalars['String']>;
  altCountryCode?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  review?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<UserFilesUpload>>;
  driversLicenseFirstIssued?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<MetaInput>;
  bio?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  avatar?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  referralCode?: Maybe<Scalars['String']>;
  whatsAppOtp?: Maybe<Scalars['Boolean']>;
};

export type ProfileVerfications = {
  __typename?: 'ProfileVerfications';
  country?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  drivers?: Maybe<Scalars['Boolean']>;
  facialMatch?: Maybe<Scalars['Boolean']>;
  idVerified?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditRisk?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  cellphoneVerified?: Maybe<Scalars['Boolean']>;
  idPhoto?: Maybe<Scalars['String']>;
  idName?: Maybe<Scalars['String']>;
  idSurname?: Maybe<Scalars['String']>;
  martialStatus?: Maybe<Scalars['String']>;
  deceasedStatus?: Maybe<Scalars['String']>;
  facialMatchScore?: Maybe<Scalars['String']>;
};

export type ProviderAlreadyAssignedError = {
  __typename?: 'ProviderAlreadyAssignedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ProviderAuthResultUnion = AuthTokens | CredentialsTakenError | ProviderNotRegisteredError | ProviderAlreadyAssignedError | NoEmailFoundOnProfileError;

export type ProviderLoginInput = {
  accessToken: Scalars['String'];
  provider: OAuthProviderTypes;
};

export type ProviderNotRegisteredError = {
  __typename?: 'ProviderNotRegisteredError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ProviderRegisterInput = {
  accessToken: Scalars['String'];
  provider: OAuthProviderTypes;
  referralCode?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  refreshToken: UserLoginResult;
  findPlace: City;
  findPlaces: Array<City>;
  getUsers: UsersResult;
  migrateReferralCodes: Scalars['Boolean'];
  getUser?: Maybe<User>;
  getUserCards: Array<CardToken>;
  getTransactions: TransactionConnection;
  getTransactionById?: Maybe<Transaction>;
  getVehicleValue: Scalars['JSON'];
  allVehicles: VehicleConnection;
  searchVehicles: VehicleConnection;
  vehicles: VehicleConnection;
  vehicle: Vehicle;
  getUserVehicle?: Maybe<Vehicle>;
  myVehicles?: Maybe<Array<Vehicle>>;
  getVehicleReviews?: Maybe<ReviewConnection>;
  getPaymentMethods: Array<PaymentMethodOutput>;
  processReservation: Scalars['Boolean'];
  migratePayout: Scalars['Boolean'];
  processPayout: Scalars['Boolean'];
  getReservation: ReservationResult;
  reservations: ReservationConnection;
  reservationsWithReviews: ReservationConnection;
  getVehicleNotes: Array<VehicleNote>;
  vehicleMakes: Array<VehicleMakes>;
  vehicleModels: Array<VehicleModels>;
  vehicleVariants: Array<VehicleModel>;
  vehicleYears: Array<VehicleModel>;
  vehiclePlaces: Array<VehiclePlace>;
  findPlacesByProvince: Array<City>;
  vehicleCategories: Array<VehicleCategory>;
  vehicleCategory: VehicleCategory;
  vehicleFeatures: Array<VehicleFeature>;
  vehicleFeature: VehicleFeature;
  getCategories: Array<Category>;
  getReviews: Array<ReservationReview>;
  /** queries reviews made by user */
  myReviews: ReviewConnection;
  /** queries reviews made to the driver */
  getOwnersReviews: ReviewConnection;
  /** queries reviews made to the user */
  getReviewsToUser: ReviewConnection;
  getHandoverById: HandoverResult;
  handoverImages: Array<HandoverImage>;
  getReservationNotes?: Maybe<ReservationNoteConnection>;
  getProfileNotes?: Maybe<ProfileNoteConnection>;
  getAccountingCodes?: Maybe<Array<AccountingCode>>;
  getReservationLineItems: Array<OrderLineItem>;
  coupons: Array<Coupon>;
  coupon: Coupon;
  couponConditions: Array<ConfigurableOperationDefinitionGql>;
  couponActions: Array<ConfigurableOperationDefinitionGql>;
};


export type QueryFindPlaceArgs = {
  id: Scalars['String'];
};


export type QueryGetUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  sortBy?: Maybe<Scalars['String']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QueryGetUserCardsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTransactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByInput>;
};


export type QueryGetTransactionByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetVehicleValueArgs = {
  id: Scalars['String'];
};


export type QueryAllVehiclesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<VehicleOrderByInput>;
};


export type QuerySearchVehiclesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<VehicleOrderByInput>;
  input: SearchVehicleInput;
};


export type QueryVehiclesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<VehicleOrderByInput>;
};


export type QueryVehicleArgs = {
  id: Scalars['String'];
};


export type QueryGetUserVehicleArgs = {
  id: Scalars['String'];
};


export type QueryGetVehicleReviewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  vehicleId: Scalars['String'];
};


export type QueryGetPaymentMethodsArgs = {
  id: Scalars['String'];
};


export type QueryGetReservationArgs = {
  id: Scalars['String'];
};


export type QueryReservationsArgs = {
  input?: Maybe<ReservationsQueryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
};


export type QueryReservationsWithReviewsArgs = {
  input?: Maybe<ReservationsQueryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
};


export type QueryGetVehicleNotesArgs = {
  vehicleId: Scalars['String'];
};


export type QueryVehicleMakesArgs = {
  year: Scalars['String'];
};


export type QueryVehicleModelsArgs = {
  make: Scalars['String'];
  year: Scalars['String'];
};


export type QueryVehicleVariantsArgs = {
  model: Scalars['String'];
  make: Scalars['String'];
  year: Scalars['String'];
};


export type QueryVehiclePlacesArgs = {
  vehicleId: Scalars['String'];
};


export type QueryFindPlacesByProvinceArgs = {
  province: Scalars['String'];
};


export type QueryVehicleCategoriesArgs = {
  query?: Maybe<VehicleCategoryPaginationArgs>;
};


export type QueryVehicleCategoryArgs = {
  id: Scalars['String'];
};


export type QueryVehicleFeaturesArgs = {
  query?: Maybe<VehicleFeaturePaginationArgs>;
};


export type QueryVehicleFeatureArgs = {
  id: Scalars['String'];
};


export type QueryGetCategoriesArgs = {
  input: TypeInput;
};


export type QueryGetReviewsArgs = {
  reservationId: Scalars['String'];
};


export type QueryMyReviewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetOwnersReviewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
};


export type QueryGetReviewsToUserArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
};


export type QueryGetHandoverByIdArgs = {
  id: Scalars['String'];
};


export type QueryHandoverImagesArgs = {
  type: HandoverFilesEnum;
  handoverId: Scalars['String'];
};


export type QueryGetReservationNotesArgs = {
  input: QueryReservationNotesInput;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReservationNoteOrderByInput>;
  where?: Maybe<ReservationNoteWhereInput>;
};


export type QueryGetProfileNotesArgs = {
  input: ProfileNoteQueryInput;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProfileNoteOrderByInput>;
  where?: Maybe<ProfileNoteWhereInput>;
};


export type QueryGetAccountingCodesArgs = {
  input?: Maybe<AccountingCodeFilterInput>;
};


export type QueryGetReservationLineItemsArgs = {
  reservationId: Scalars['String'];
};


export type QueryCouponArgs = {
  id: Scalars['String'];
};

export type QueryReservationNotesInput = {
  reservationId: Scalars['String'];
};

export type RadiusSearchInput = {
  lng: Scalars['Float'];
  lat: Scalars['Float'];
  distance?: Maybe<Scalars['Int']>;
};

export type RecommendationPrice = {
  __typename?: 'RecommendationPrice';
  price: Amount;
  min: Amount;
  max: Amount;
};

export type ReferFriendsInput = {
  emails: Array<Scalars['String']>;
};

export enum RentalProfiles {
  Owner = 'owner',
  Driver = 'driver'
}

export type Renter = {
  __typename?: 'Renter';
  fullName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type RequestReservationInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  vehicleId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  isDropoff?: Maybe<Scalars['Boolean']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ReservationStatus;
  startDate: Scalars['DateTime'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  dailykilometers?: Maybe<Scalars['Float']>;
  isDelivery: Scalars['Boolean'];
  locationId?: Maybe<Scalars['String']>;
  isDeliveryAirport: Scalars['Boolean'];
  deliveryFee: Scalars['Float'];
  rejectionNote?: Maybe<Scalars['String']>;
  vehicleRetail?: Maybe<Scalars['Float']>;
  driverNote?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  cancelationPeriod?: Maybe<Scalars['DateTime']>;
  nextReminderDate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['JSON']>;
  notes: Array<ReservationNote>;
  transactions: Array<Transaction>;
  handover?: Maybe<Handover>;
  orderLineItems?: Maybe<Array<OrderLineItem>>;
  couponCodes: Array<Scalars['String']>;
  isBooked: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  getTotal: Amount;
  payout?: Maybe<Amount>;
  dates: ReservationDatesDto;
  endDate: Scalars['DateTime'];
  pricePerDay: Amount;
  depositTotal: Amount;
  insurancePerDay: Amount;
  duration: Scalars['Int'];
  /** Front end display of line items before confirmation */
  lineItems: Array<ReservationLineItems>;
  payments: Array<Transaction>;
  user: ReservationUser;
  information: Scalars['JSON'];
  vehicle: Vehicle;
  orderLineTotals?: Maybe<OrderLineTotals>;
  vehicleUser: ReservationUser;
  isOwner: Scalars['Boolean'];
  rejectionNotePrivate?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<ReservationReview>>;
  scopes?: Maybe<Array<Scalars['String']>>;
  expiry?: Maybe<Scalars['Int']>;
  verifications?: Maybe<Array<UserVerification>>;
};


export type ReservationDatesArgs = {
  format?: Maybe<Scalars['String']>;
};


export type ReservationPaymentsArgs = {
  status?: Maybe<Scalars['String']>;
};

export type ReservationAddress = {
  type?: Maybe<PlaceType>;
  streetNumber?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  placeFormattedAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type ReservationAlreadyProcessedError = {
  __typename?: 'ReservationAlreadyProcessedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationAlreadyReviewed = {
  __typename?: 'ReservationAlreadyReviewed';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationApprovalInput = {
  id: Scalars['String'];
  reason: Scalars['String'];
  privateReason: Scalars['String'];
};

export type ReservationConnection = {
  __typename?: 'ReservationConnection';
  pageInfo: PageInfo;
  edges: Array<ReservationEdge>;
  aggregate: AggregateReservation;
};

export type ReservationDates = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type ReservationDatesDto = {
  __typename?: 'ReservationDatesDto';
  isoStartDate?: Maybe<Scalars['String']>;
  isoEndDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startFullDate?: Maybe<Scalars['String']>;
  endFullDate?: Maybe<Scalars['String']>;
  formatStartDate?: Maybe<Scalars['String']>;
  formatEndDate?: Maybe<Scalars['String']>;
  namedMonthStartDate?: Maybe<Scalars['String']>;
  namedMonthEndDate?: Maybe<Scalars['String']>;
};

export type ReservationDoesNotExistError = {
  __typename?: 'ReservationDoesNotExistError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationEdge = {
  __typename?: 'ReservationEdge';
  node: Reservation;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ReservationExpiredError = {
  __typename?: 'ReservationExpiredError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationIncompleteError = {
  __typename?: 'ReservationIncompleteError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationLineItems = {
  __typename?: 'ReservationLineItems';
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['String'];
  icon: Scalars['String'];
  eyebrow: Scalars['String'];
  main: Scalars['Boolean'];
};

export type ReservationNotFoundError = {
  __typename?: 'ReservationNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReservationNote = Node & {
  __typename?: 'ReservationNote';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nodeId: Scalars['ID'];
  note: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  files?: Maybe<Array<FileEntity>>;
  isDriver: Scalars['Boolean'];
  isOwner?: Maybe<Scalars['Boolean']>;
  reservation: Reservation;
  profile?: Maybe<NoteProfile>;
};

export type ReservationNoteConnection = {
  __typename?: 'ReservationNoteConnection';
  pageInfo: PageInfo;
  edges: Array<ReservationNoteEdge>;
  aggregate: AggregateReservationNote;
};

export type ReservationNoteEdge = {
  __typename?: 'ReservationNoteEdge';
  node: ReservationNote;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ReservationNoteOrderByInput = {
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
};

export type ReservationNoteWhereInput = {
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type ReservationOrderByInput = {
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  startDate?: Maybe<Order>;
  endDate?: Maybe<Order>;
};

export type ReservationReportsSearchInput = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<ReservationStatus>;
};

export type ReservationResult = Reservation | InvalidReservationError | VehicleUnAvailableError | ReservationDoesNotExistError | InvalidActionError | ReservationExpiredError | InvalidReservationDatesError | VehicleIsBookedForThisPeriodError | InvalidDurationCriteriaError | ReservationAlreadyProcessedError | UnAuthorizedAccessError | InvalidVehicleMethodError | PickUpAndDropOffRequiredError | BookingNotAllowedError | CancelationTimeHasAlreadyExpiredError | CouponCodeInvalidError;

export type ReservationReview = {
  __typename?: 'ReservationReview';
  id: Scalars['String'];
  type: ReviewLabel;
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
  categories: Array<ReviewCategory>;
  user: Reviewer;
  overall?: Maybe<Scalars['Float']>;
  reviewed: Scalars['String'];
};

export enum ReservationStatus {
  Quoted = 'QUOTED',
  Requested = 'REQUESTED',
  Accepted = 'ACCEPTED',
  Reserved = 'RESERVED',
  Confirmed = 'CONFIRMED',
  PendingPayment = 'PENDING_PAYMENT',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Invoiced = 'INVOICED',
  UnderAssessment = 'UNDER_ASSESSMENT'
}

export type ReservationStatusUpdateInput = {
  status: ReservationStatus;
  reason: Scalars['String'];
};

export enum ReservationTimePeriod {
  Past = 'PAST',
  Active = 'ACTIVE',
  Future = 'FUTURE'
}

export type ReservationUser = {
  __typename?: 'ReservationUser';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  age?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
};

export type ReservationVehicleChangeInput = {
  vehicleId: Scalars['String'];
  reason: Scalars['String'];
  recalculateCost: Scalars['Boolean'];
};

export type ReservationWhereInput = {
  status?: Maybe<StringFieldComparison>;
  timePeriod?: Maybe<ReservationTimePeriod>;
  createdAt?: Maybe<DateFieldComparison>;
  startDate?: Maybe<DateFieldComparison>;
  endDate?: Maybe<DateFieldComparison>;
  paymentDate?: Maybe<DateFieldComparison>;
  vehicleName?: Maybe<StringFieldComparison>;
  ownerName?: Maybe<StringFieldComparison>;
  renterName?: Maybe<StringFieldComparison>;
};

export type ReservationsQueryInput = {
  vehicleId: Scalars['String'];
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  overall?: Maybe<Scalars['Float']>;
  categories: Array<ReviewCategory>;
  reservation: Reservation;
  user: User;
};

export type ReviewCategoriesInput = {
  name: Scalars['String'];
  rating: Scalars['Float'];
  comments: Scalars['String'];
};

export type ReviewCategory = {
  __typename?: 'ReviewCategory';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  rating: Scalars['Float'];
  comments: Scalars['String'];
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  pageInfo: PageInfo;
  edges: Array<ReviewEdge>;
  aggregate: AggregateReview;
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  node: VehicleReviewWithUser;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

/** labels the review as per the user type targeted */
export enum ReviewLabel {
  Owner = 'OWNER',
  Driver = 'DRIVER'
}

export type ReviewNotFoundError = {
  __typename?: 'ReviewNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReviewResult = Review | ReviewTimeExpiredError | ReservationIncompleteError | UnAuthorizedActionError | ReservationAlreadyReviewed | ReviewNotFoundError;

export type ReviewTimeExpiredError = {
  __typename?: 'ReviewTimeExpiredError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReviewUser = {
  __typename?: 'ReviewUser';
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type Reviewer = {
  __typename?: 'Reviewer';
  fullName: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Driver = 'DRIVER',
  Staff = 'STAFF',
  Any = 'ANY',
  User = 'USER'
}

export type SearchVehicleInput = {
  ids?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  featureIds?: Maybe<Array<Scalars['String']>>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<Order>;
  sortField?: Maybe<Scalars['String']>;
  place?: Maybe<PlaceSearchInput>;
  odometer?: Maybe<MinMax>;
  dailyKm?: Maybe<MinMax>;
  price?: Maybe<MinMax>;
  fuelType?: Maybe<Array<VehicleFuel>>;
  model?: Maybe<ModelFilterInput>;
  transmission?: Maybe<VehicleTransmission>;
  isDelivery?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  dates?: Maybe<DateRangeFilter>;
  location?: Maybe<RadiusSearchInput>;
  isMapSearch?: Maybe<Scalars['Boolean']>;
  northEastLatitude?: Maybe<Scalars['Float']>;
  northEastLongitude?: Maybe<Scalars['Float']>;
  southWestLatitude?: Maybe<Scalars['Float']>;
  southWestLongitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Int']>;
};

export type SetAddressInput = {
  locationId?: Maybe<Scalars['String']>;
  isDropoff?: Maybe<Scalars['Boolean']>;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type TicketResult = {
  __typename?: 'TicketResult';
  url: Scalars['String'];
  id: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TooManyOtpAttemptsError = {
  __typename?: 'TooManyOtpAttemptsError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nodeId: Scalars['ID'];
  method: Scalars['String'];
  amount: Scalars['Float'];
  transactionId: Scalars['String'];
  reservationId: Scalars['String'];
  user?: Maybe<User>;
  status: TransactionStatus;
  type: TransactionType;
  transactionDate: Scalars['DateTime'];
  response?: Maybe<Scalars['JSON']>;
  declineReason?: Maybe<Scalars['String']>;
  amountFormatted?: Maybe<Amount>;
  reservation: Reservation;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  pageInfo: PageInfo;
  edges: Array<TransactionEdge>;
  aggregate: AggregateTransaction;
};

export type TransactionDates = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  node: Transaction;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type TransactionOrderByInput = {
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  startDate?: Maybe<Order>;
  endDate?: Maybe<Order>;
};

/** Enums for the transaction status */
export enum TransactionStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

/** Enums for the transaction status */
export enum TransactionType {
  Payment = 'PAYMENT',
  Deposit = 'DEPOSIT',
  Refund = 'REFUND',
  Payout = 'PAYOUT'
}

export type TransactionWhereInput = {
  status?: Maybe<StringFieldComparison>;
  vehicleName?: Maybe<StringFieldComparison>;
  reservationDates?: Maybe<ReservationDates>;
  transactionDates?: Maybe<TransactionDates>;
};

/** Improve enum */
export enum TypeEnum {
  Driver = 'Driver',
  Owner = 'Owner'
}

export type TypeInput = {
  type: TypeEnum;
};

export type UnAuthorizedAccessError = {
  __typename?: 'UnAuthorizedAccessError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type UnAuthorizedActionError = {
  __typename?: 'UnAuthorizedActionError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type UnAuthorizedNoteActionError = {
  __typename?: 'UnAuthorizedNoteActionError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateAccountInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  terms: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  countryCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
};

export type UpdateAccountingCodeInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  hasCommission: Scalars['Boolean'];
  isContra: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type UpdateCouponInput = {
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  couponCode?: Maybe<Scalars['String']>;
  perCustomerUsageLimit?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Array<ConfigurableOperationInput>>;
  actions?: Maybe<Array<ConfigurableOperationInput>>;
};

export type UpdateHandoverInput = {
  checklist?: Maybe<Scalars['JSON']>;
  odometer?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  acceptIdentity?: Maybe<Scalars['Boolean']>;
  status?: Maybe<HandoverStatus>;
  reason?: Maybe<Scalars['String']>;
};

export type UpdateHandoverResult = InvalidHandoverError | Handover;

export type UpdateNoteInput = {
  fileId?: Maybe<Scalars['String']>;
  type?: Maybe<NoteTypeEnum>;
  fileType?: Maybe<HandoverFilesEnum>;
  reason?: Maybe<Scalars['String']>;
};

export type UpdateNoteResult = InvalidHandoverError | HandoverAccessError | HandoverNote;

export type UpdatePaymentDateInput = {
  paymentDate: Scalars['DateTime'];
};

export type UpdatePlaceInput = {
  address?: Maybe<AddressDto>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PlaceType>;
};

export type UpdateProfileNoteInput = {
  note?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  canReply?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  sendNotification?: Maybe<Scalars['Boolean']>;
};

export type UpdateReservationDatesInput = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type UpdateReservationLineItemInput = {
  accountId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  contraId?: Maybe<Scalars['String']>;
};

export type UpdateReservationNoteInput = {
  reservationId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  sendNotification?: Maybe<Scalars['Boolean']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  noteId: Scalars['String'];
};

export type UpdateReviewInput = {
  categories: Array<ReviewCategoriesInput>;
  publicComment?: Maybe<Scalars['String']>;
  privateComment?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['Email']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<UserStatus>;
  profile?: Maybe<ProfileUpdateInput>;
  role?: Maybe<Role>;
  recieveAdminEmails?: Maybe<Scalars['Boolean']>;
  dailyReservationReport?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleCategoryInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateVehicleDailyAttributes = {
  /** DateRange for the days unavailable */
  dates?: Maybe<Array<Scalars['DateTime']>>;
  status?: Maybe<VehicleDailyAttributesStatus>;
  privateNote?: Maybe<Scalars['String']>;
};

export type UpdateVehicleFeatureInput = {
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateVehicleInput = {
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  color?: Maybe<VehicleColor>;
  description?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Int']>;
  transmission?: Maybe<VehicleTransmission>;
  featureIds?: Maybe<Array<Scalars['String']>>;
  transunionId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<VehicleFilesUpload>>;
  fueltype?: Maybe<VehicleFuel>;
  tracker?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  licence_no?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  is_deliver_airport?: Maybe<Scalars['Boolean']>;
  delivery_fee_airport?: Maybe<Scalars['Int']>;
  is_deliver_renter?: Maybe<Scalars['Boolean']>;
  delivery_fee_renter?: Maybe<Scalars['Int']>;
  delivery_radius?: Maybe<Scalars['Int']>;
  is_allow_pets?: Maybe<Scalars['Boolean']>;
  is_show_location?: Maybe<Scalars['Boolean']>;
  is_collect?: Maybe<Scalars['Boolean']>;
  is_delivery?: Maybe<Scalars['Boolean']>;
  max_kilometers_per_day?: Maybe<Scalars['Int']>;
  maximum_days?: Maybe<Scalars['Int']>;
  minimum_days?: Maybe<Scalars['Int']>;
  roadworthy_at?: Maybe<Scalars['DateTime']>;
  service_at?: Maybe<Scalars['DateTime']>;
  price_per_day?: Maybe<Scalars['Int']>;
  price_in_cents_retail?: Maybe<Scalars['Int']>;
  t_c_accepted_at?: Maybe<Scalars['DateTime']>;
  imageIds?: Maybe<Array<Scalars['String']>>;
  vehicleImages?: Maybe<Array<ImageWithView>>;
  is_drive_on_unpaved_roads?: Maybe<Scalars['Boolean']>;
  is_weekends_only?: Maybe<Scalars['Boolean']>;
  is_weekdays_only?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
  address?: Maybe<CreateVehiclePlaceInput>;
};

export type UpdateVehiclePlaceInput = {
  streetNumber?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  placeFormattedAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  type?: Maybe<PlaceType>;
  enabled?: Maybe<Scalars['Boolean']>;
  deliveryFee?: Maybe<Scalars['Int']>;
};


export type User = Node & {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nodeId: Scalars['ID'];
  email?: Maybe<Scalars['Email']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  /** @deprecated split into two profiles */
  isApproved?: Maybe<Scalars['Boolean']>;
  status: UserStatus;
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<Profile>;
  bankAccount?: Maybe<BankAccount>;
  blockReason?: Maybe<Scalars['String']>;
  isBlocked: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  reviewAt?: Maybe<Scalars['DateTime']>;
  age?: Maybe<Scalars['Int']>;
  getDriverAge?: Maybe<Scalars['Int']>;
  getLicenceAge?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<UserDocument>>;
  cards?: Maybe<Array<CardToken>>;
  tripsCount?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Transaction>>;
  vehicles?: Maybe<VehicleConnection>;
  reservations?: Maybe<ReservationConnection>;
  vehicleReservations?: Maybe<ReservationConnection>;
};


export type UserVehiclesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<VehicleOrderByInput>;
};


export type UserReservationsArgs = {
  input?: Maybe<ReservationsQueryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
};


export type UserVehicleReservationsArgs = {
  input?: Maybe<ReservationsQueryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
  fileId: Scalars['ID'];
  type: DocumentType;
  verified: Scalars['Boolean'];
  file?: Maybe<FileEntity>;
  status: Scalars['String'];
};

export type UserFilesUpload = {
  type: DocumentType;
  fileId: Scalars['String'];
};

export type UserLoginResult = AuthTokens | InvalidCredentialsError | AccountBlockedError;

export type UserOutputBase = {
  __typename?: 'UserOutputBase';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['Email'];
  phoneNumber: Scalars['String'];
  role: Role;
  phoneNumberVerifiedAt: Scalars['Boolean'];
  emailAddressVerifiedAt: Scalars['Boolean'];
  idNumberVerifiedAt: Scalars['Boolean'];
  status: UserStatus;
  country?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
};

export type UserRegisterInvalidInputError = {
  __typename?: 'UserRegisterInvalidInputError';
  message?: Maybe<Scalars['String']>;
};

export type UserRegisterResult = UserRegisterSuccess | UserRegisterInvalidInputError;

export type UserRegisterSuccess = {
  __typename?: 'UserRegisterSuccess';
  accessToken: Scalars['String'];
  accessTokenExpiry: Scalars['DateTime'];
  refreshToken: Scalars['String'];
  refreshTokenExpiry: Scalars['DateTime'];
  user: User;
};

export type UserResult = {
  __typename?: 'UserResult';
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export enum UserStatus {
  Pending = 'PENDING',
  Review = 'REVIEW',
  Active = 'ACTIVE',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Rejected = 'REJECTED'
}

export type UserUpdateResult = UserUpdateSuccess | UserRegisterInvalidInputError;

export type UserUpdateSuccess = {
  __typename?: 'UserUpdateSuccess';
  user: User;
};

export type UserVerification = {
  __typename?: 'UserVerification';
  label: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type UserWhereInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  country?: Maybe<StringFieldComparison>;
  referralCode?: Maybe<Scalars['String']>;
};

export type UsersExportResult = ExportUsersResult | ForbidenError;

export type UsersResult = {
  __typename?: 'UsersResult';
  users: Array<User>;
  totalCount: Scalars['Int'];
};

export type Vehicle = Node & {
  __typename?: 'Vehicle';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nodeId: Scalars['ID'];
  licence_no: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  listingStatus: VehicleStatus;
  color: VehicleColor;
  approvalStatus: VehicleApprovalStatus;
  rejectionReason?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  vin: Scalars['String'];
  odometer: Scalars['Float'];
  fueltype: VehicleFuel;
  transmission: VehicleTransmission;
  /** @deprecated use amount version pricePerDay */
  price_per_day?: Maybe<Scalars['Float']>;
  /** @deprecated use amount version retailPrice */
  price_in_cents_retail?: Maybe<Scalars['Float']>;
  is_deliver_airport?: Maybe<Scalars['Boolean']>;
  /** @deprecated use amount version deliveryFeeAirport */
  delivery_fee_airport?: Maybe<Scalars['Float']>;
  is_deliver_renter?: Maybe<Scalars['Boolean']>;
  /** @deprecated use amount version deliveryFeeRenter */
  delivery_fee_renter?: Maybe<Scalars['Float']>;
  delivery_radius?: Maybe<Scalars['Float']>;
  daily_kilometers_included?: Maybe<Scalars['Float']>;
  max_kilometers_per_day?: Maybe<Scalars['Float']>;
  minimum_days: Scalars['Int'];
  maximum_days?: Maybe<Scalars['Float']>;
  is_drive_on_unpaved_roads?: Maybe<Scalars['Boolean']>;
  is_delivery?: Maybe<Scalars['Boolean']>;
  is_collect?: Maybe<Scalars['Boolean']>;
  is_allow_pets?: Maybe<Scalars['Boolean']>;
  is_show_location?: Maybe<Scalars['Boolean']>;
  is_weekends_only: Scalars['Boolean'];
  is_weekdays_only: Scalars['Boolean'];
  /** time of acceptance (Terms for lisiting this vehicle on RMR) */
  t_c_accepted_at?: Maybe<Scalars['DateTime']>;
  mapLat?: Maybe<Scalars['Float']>;
  mapLng?: Maybe<Scalars['Float']>;
  details: VehicleModel;
  features: Array<VehicleFeature>;
  category: VehicleCategory;
  images: Array<VehicleImage>;
  places?: Maybe<Array<VehiclePlace>>;
  tracker: Scalars['Boolean'];
  referenceNo?: Maybe<Scalars['String']>;
  dailyAttributes?: Maybe<Array<VehicleDailyAttribute>>;
  stats?: Maybe<VehicleSummary>;
  /** @deprecated use images instead */
  assets: Array<FileEntity>;
  documents?: Maybe<Array<VehicleDocument>>;
  user: VehicleUser;
  defaultName: Scalars['String'];
  /** @deprecated use recommendedPrice with amount field */
  recommendedPricePerDay: GetVehicleRecommendationPrice;
  recommendedPrice: RecommendationPrice;
  retailPrice: Amount;
  deliveryFeeAirport: Amount;
  deliveryFeeRenter: Amount;
  pricePerDay: Amount;
  daily?: Maybe<Array<DailyAttributes>>;
  detailsList?: Maybe<Array<VehicleDetails>>;
  addresses: Array<VehiclePlace>;
};


export type VehicleDailyArgs = {
  input: GetVehicleDailyInput;
};

export enum VehicleApprovalStatus {
  Pending = 'pending',
  Review = 'review',
  Approved = 'approved',
  Rejected = 'rejected'
}

export type VehicleCategory = {
  __typename?: 'VehicleCategory';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type VehicleCategoryPaginationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum VehicleColor {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Gold = 'gold',
  White = 'white',
  Black = 'black',
  Grey = 'grey',
  Silver = 'silver',
  Other = 'other'
}

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  pageInfo: PageInfo;
  edges: Array<VehicleEdge>;
  aggregate: AggregateVehicle;
};

export type VehicleDailyAttribute = {
  __typename?: 'VehicleDailyAttribute';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  status: VehicleDailyAttributesStatus;
  privateNote?: Maybe<Scalars['String']>;
};

/** Enum for blocked or unblocked */
export enum VehicleDailyAttributesStatus {
  Block = 'block',
  Unblock = 'unblock'
}

export type VehicleDetails = {
  __typename?: 'VehicleDetails';
  icon?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type VehicleDocument = {
  __typename?: 'VehicleDocument';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fileId: Scalars['ID'];
  type: VehicleDocumentTypeEnum;
  file: FileEntity;
  verified?: Maybe<Scalars['Boolean']>;
};

/** Enums for the various documents required in order to list a vehicle */
export enum VehicleDocumentTypeEnum {
  LicenseDisk = 'LICENSE_DISK',
  RoadworthyCertificate = 'ROADWORTHY_CERTIFICATE',
  TrackerDocumentation = 'TRACKER_DOCUMENTATION',
  Other = 'OTHER'
}

export type VehicleEdge = {
  __typename?: 'VehicleEdge';
  node: Vehicle;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type VehicleFeature = {
  __typename?: 'VehicleFeature';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type VehicleFeaturePaginationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VehicleFilesUpload = {
  type: VehicleDocumentTypeEnum;
  fileId: Scalars['String'];
};

export enum VehicleFuel {
  Petrol = 'petrol',
  Diesel = 'diesel',
  HybridPetrol = 'hybridPetrol',
  HybridDiesel = 'hybridDiesel',
  Electric = 'electric'
}

export type VehicleImage = {
  __typename?: 'VehicleImage';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fileId: Scalars['String'];
  view?: Maybe<Scalars['String']>;
  file: FileEntity;
  position: Scalars['Int'];
  approved?: Maybe<Scalars['Boolean']>;
};

export type VehicleIsBookedForThisPeriodError = {
  __typename?: 'VehicleIsBookedForThisPeriodError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleIsNotApprovedError = {
  __typename?: 'VehicleIsNotApprovedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleMakes = {
  __typename?: 'VehicleMakes';
  make: Scalars['String'];
  year: Scalars['String'];
};

export type VehicleModel = {
  __typename?: 'VehicleModel';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  variant: Scalars['String'];
  mmCode: Scalars['String'];
  hash: Scalars['String'];
  dataset: Scalars['String'];
};

export type VehicleModels = {
  __typename?: 'VehicleModels';
  model: Scalars['String'];
  year: Scalars['String'];
};

export type VehicleNotFoundError = {
  __typename?: 'VehicleNotFoundError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleNotRejectedError = {
  __typename?: 'VehicleNotRejectedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleNote = {
  __typename?: 'VehicleNote';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  note: Scalars['String'];
  user?: Maybe<User>;
  isAdmin: Scalars['Boolean'];
  vehicle?: Maybe<Vehicle>;
  files?: Maybe<Array<FileEntity>>;
};

export type VehicleOrderByInput = {
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type VehiclePlace = {
  __typename?: 'VehiclePlace';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  streetNumber?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  type?: Maybe<PlaceType>;
  deliveryFeeAmount: Amount;
};

export type VehicleResultUnion = Vehicle | VehicleNotFoundError | InvalidVehicleCategoryError | InvalidVehicleSelectedError | VehicleIsNotApprovedError | VehicleNotRejectedError | VehicleUnAuthorizedError;

export type VehicleReviewWithUser = {
  __typename?: 'VehicleReviewWithUser';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  profile?: Maybe<ReviewUser>;
  reservationId?: Maybe<Scalars['String']>;
};

export enum VehicleStatus {
  Scheduled = 'scheduled',
  Listed = 'listed',
  Unlisted = 'unlisted'
}

export type VehicleSummary = {
  __typename?: 'VehicleSummary';
  trips?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};

export enum VehicleTransmission {
  Manual = 'manual',
  Automatic = 'automatic'
}

export type VehicleUnAuthorizedError = {
  __typename?: 'VehicleUnAuthorizedError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleUnAvailableError = {
  __typename?: 'VehicleUnAvailableError';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type VehicleUser = {
  __typename?: 'VehicleUser';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  idnumber?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  isSAcitizen?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  altPhoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  isApprovedOwner?: Maybe<Scalars['Boolean']>;
  isApprovedDriver?: Maybe<Scalars['Boolean']>;
  status?: Maybe<UserStatus>;
  id: Scalars['String'];
  isBlocked: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type VehicleWhereInput = {
  approvalStatus?: Maybe<VehicleApprovalStatus>;
  listingStatus?: Maybe<VehicleStatus>;
  name?: Maybe<Scalars['String']>;
};

export type VerifyDocumentInput = {
  type: DocumentType;
  file: Scalars['Upload'];
};

export type VerifyOtpInput = {
  fingerPrint: Scalars['String'];
  code: Scalars['String'];
};

export enum ZendeskTicketType {
  Incident = 'INCIDENT',
  Task = 'TASK',
  Problem = 'PROBLEM',
  Question = 'QUESTION'
}

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename: 'AuthTokens' }
    & { token: AuthTokens['accessToken'], expiry: AuthTokens['accessTokenExpiry'] }
  ) | (
    { __typename: 'InvalidCredentialsError' }
    & Pick<InvalidCredentialsError, 'errorCode' | 'message'>
  ) | (
    { __typename: 'AccountBlockedError' }
    & Pick<AccountBlockedError, 'errorCode' | 'message'>
  ) }
);

export type RefreshTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenQuery = (
  { __typename?: 'Query' }
  & { refreshToken: (
    { __typename?: 'AuthTokens' }
    & { token: AuthTokens['accessToken'], expiry: AuthTokens['accessTokenExpiry'] }
  ) | { __typename?: 'InvalidCredentialsError' } | { __typename?: 'AccountBlockedError' } }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename: 'UserRegisterSuccess' }
    & { token: UserRegisterSuccess['accessToken'], expiry: UserRegisterSuccess['accessTokenExpiry'] }
    & { user: (
      { __typename?: 'User' }
      & UserFieldsFragment
    ) }
  ) | (
    { __typename: 'UserRegisterInvalidInputError' }
    & Pick<UserRegisterInvalidInputError, 'message'>
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename: 'UserUpdateSuccess' }
    & { user: (
      { __typename?: 'User' }
      & UserFieldsFragment
    ) }
  ) | (
    { __typename: 'UserRegisterInvalidInputError' }
    & Pick<UserRegisterInvalidInputError, 'message'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ForgotPasswordSuccess' }
    & Pick<ForgotPasswordSuccess, 'success'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type SendOtpMutationVariables = Exact<{ [key: string]: never; }>;


export type SendOtpMutation = (
  { __typename?: 'Mutation' }
  & { sendOtp: (
    { __typename: 'BaseOutput' }
    & Pick<BaseOutput, 'success'>
  ) | (
    { __typename: 'OtpSuccessOutput' }
    & Pick<OtpSuccessOutput, 'fingerPrint'>
  ) | (
    { __typename: 'OtpFailureOutput' }
    & Pick<OtpFailureOutput, 'error'>
  ) | (
    { __typename: 'TooManyOtpAttemptsError' }
    & Pick<TooManyOtpAttemptsError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'AccountBlockedError' }
    & Pick<AccountBlockedError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'OtpNotFoundError' }
    & Pick<OtpNotFoundError, 'message' | 'errorCode'>
  ) }
);

export type ResendOtpMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendOtpMutation = (
  { __typename?: 'Mutation' }
  & { resendOtp: (
    { __typename: 'BaseOutput' }
    & Pick<BaseOutput, 'success'>
  ) | (
    { __typename: 'OtpSuccessOutput' }
    & Pick<OtpSuccessOutput, 'fingerPrint'>
  ) | (
    { __typename: 'OtpFailureOutput' }
    & Pick<OtpFailureOutput, 'error'>
  ) | (
    { __typename: 'TooManyOtpAttemptsError' }
    & Pick<TooManyOtpAttemptsError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'AccountBlockedError' }
    & Pick<AccountBlockedError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'OtpNotFoundError' }
    & Pick<OtpNotFoundError, 'message' | 'errorCode'>
  ) }
);

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput;
}>;


export type VerifyOtpMutation = (
  { __typename?: 'Mutation' }
  & { verifyOtp: (
    { __typename: 'BaseOutput' }
    & Pick<BaseOutput, 'success'>
  ) | (
    { __typename: 'OtpSuccessOutput' }
    & Pick<OtpSuccessOutput, 'fingerPrint'>
  ) | (
    { __typename: 'OtpFailureOutput' }
    & Pick<OtpFailureOutput, 'error'>
  ) | (
    { __typename: 'TooManyOtpAttemptsError' }
    & Pick<TooManyOtpAttemptsError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'AccountBlockedError' }
    & Pick<AccountBlockedError, 'message' | 'errorCode'>
  ) | (
    { __typename: 'OtpNotFoundError' }
    & Pick<OtpNotFoundError, 'message' | 'errorCode'>
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  verify: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename?: 'AuthTokens' }
    & Pick<AuthTokens, 'refreshToken' | 'accessToken' | 'accessTokenExpiry' | 'refreshTokenExpiry'>
  ) | (
    { __typename?: 'InvalidCredentialsError' }
    & Pick<InvalidCredentialsError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'AccountBlockedError' }
    & Pick<AccountBlockedError, 'errorCode' | 'message'>
  ) }
);

export type ResendEmailVerificationMutationVariables = Exact<{
  input: EmailVerificationInput;
}>;


export type ResendEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & { resendEmailVerification: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type ApproveListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApproveListingMutation = (
  { __typename?: 'Mutation' }
  & { approveListing: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'approvalStatus' | 'listingStatus'>
    & VehicleError_Vehicle_Fragment
  ) | (
    { __typename?: 'VehicleNotFoundError' }
    & VehicleError_VehicleNotFoundError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleCategoryError' }
    & VehicleError_InvalidVehicleCategoryError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleSelectedError' }
    & VehicleError_InvalidVehicleSelectedError_Fragment
  ) | (
    { __typename?: 'VehicleIsNotApprovedError' }
    & VehicleError_VehicleIsNotApprovedError_Fragment
  ) | (
    { __typename?: 'VehicleNotRejectedError' }
    & VehicleError_VehicleNotRejectedError_Fragment
  ) | (
    { __typename?: 'VehicleUnAuthorizedError' }
    & VehicleError_VehicleUnAuthorizedError_Fragment
  ) }
);

export type RejectListingMutationVariables = Exact<{
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
}>;


export type RejectListingMutation = (
  { __typename?: 'Mutation' }
  & { rejectListing: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'approvalStatus' | 'rejectionReason' | 'rejectedAt'>
  ) | { __typename?: 'VehicleNotFoundError' } | { __typename?: 'InvalidVehicleCategoryError' } | { __typename?: 'InvalidVehicleSelectedError' } | { __typename?: 'VehicleIsNotApprovedError' } | { __typename?: 'VehicleNotRejectedError' } | { __typename?: 'VehicleUnAuthorizedError' } }
);

export type UpdateVehicleListingStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status: VehicleStatus;
}>;


export type UpdateVehicleListingStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleListingStatus: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'listingStatus'>
    & VehicleError_Vehicle_Fragment
  ) | (
    { __typename?: 'VehicleNotFoundError' }
    & VehicleError_VehicleNotFoundError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleCategoryError' }
    & VehicleError_InvalidVehicleCategoryError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleSelectedError' }
    & VehicleError_InvalidVehicleSelectedError_Fragment
  ) | (
    { __typename?: 'VehicleIsNotApprovedError' }
    & VehicleError_VehicleIsNotApprovedError_Fragment
  ) | (
    { __typename?: 'VehicleNotRejectedError' }
    & VehicleError_VehicleNotRejectedError_Fragment
  ) | (
    { __typename?: 'VehicleUnAuthorizedError' }
    & VehicleError_VehicleUnAuthorizedError_Fragment
  ) }
);

export type ResubmitListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResubmitListingMutation = (
  { __typename?: 'Mutation' }
  & { resubmitListing: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'approvalStatus'>
  ) }
);

export type EnableVehicleMutationVariables = Exact<{
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type EnableVehicleMutation = (
  { __typename?: 'Mutation' }
  & { enableVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'enabled'>
  ) }
);

export type UpdateVehicleMutationVariables = Exact<{
  input: UpdateVehicleInput;
}>;


export type UpdateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicle: (
    { __typename?: 'Vehicle' }
    & AllVehicleFieldsFragment
    & VehicleError_Vehicle_Fragment
  ) | (
    { __typename?: 'VehicleNotFoundError' }
    & VehicleError_VehicleNotFoundError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleCategoryError' }
    & VehicleError_InvalidVehicleCategoryError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleSelectedError' }
    & VehicleError_InvalidVehicleSelectedError_Fragment
  ) | (
    { __typename?: 'VehicleIsNotApprovedError' }
    & VehicleError_VehicleIsNotApprovedError_Fragment
  ) | (
    { __typename?: 'VehicleNotRejectedError' }
    & VehicleError_VehicleNotRejectedError_Fragment
  ) | (
    { __typename?: 'VehicleUnAuthorizedError' }
    & VehicleError_VehicleUnAuthorizedError_Fragment
  ) }
);

export type ProfileFieldsFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'createdAt' | 'firstName' | 'lastName' | 'fullName' | 'idnumber' | 'phoneNumber' | 'countryCode' | 'country' | 'isSAcitizen' | 'consentAt' | 'whatsapp' | 'whatsAppOtp' | 'altPhoneNumber' | 'altCountryCode' | 'gender' | 'phoneVerifiedAt' | 'idnumberVerifiedAt' | 'passportNumber' | 'isApprovedOwner' | 'isApprovedDriver' | 'avatar' | 'commission' | 'dateOfBirth' | 'joined' | 'joinDate' | 'driversLicenseFirstIssued' | 'additionalInfo' | 'bio' | 'referralCode'>
  & { verifications?: Maybe<(
    { __typename?: 'ProfileVerfications' }
    & Pick<ProfileVerfications, 'country' | 'facialMatch' | 'idVerified' | 'creditScore' | 'creditRisk' | 'emailVerified' | 'cellphoneVerified' | 'idPhoto' | 'idName' | 'idSurname' | 'martialStatus' | 'deceasedStatus' | 'facialMatchScore' | 'passport' | 'drivers'>
  )>, addresses: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'suburb' | 'city' | 'latitude' | 'longitude' | 'postalCode' | 'province' | 'country' | 'addressType'>
  )> }
);

export type UserDocumentFieldsFragment = (
  { __typename?: 'UserDocument' }
  & Pick<UserDocument, 'id' | 'userId' | 'fileId' | 'type' | 'verified' | 'status'>
  & { file?: Maybe<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url' | 'key'>
    & { preview: FileEntity['url'] }
    & { meta?: Maybe<(
      { __typename?: 'FileMetaType' }
      & Pick<FileMetaType, 'ext'>
    )> }
  )> }
);

export type AdminUserDetailsFragment = (
  { __typename?: 'VehicleUser' }
  & Pick<VehicleUser, 'createdAt' | 'updatedAt' | 'email' | 'idnumber' | 'passportNumber' | 'phoneNumber' | 'altPhoneNumber' | 'country' | 'isSAcitizen' | 'role' | 'isApprovedOwner' | 'isApprovedDriver' | 'status'>
);

export type CardTokenFieldsFragment = (
  { __typename?: 'CardToken' }
  & Pick<CardToken, 'id' | 'createdAt' | 'updatedAt' | 'gateway' | 'cardType' | 'lastFourDigits' | 'expiryMonth' | 'expiryYear' | 'expiryDate' | 'default'>
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'emailVerifiedAt' | 'termsAcceptedAt' | 'role' | 'status' | 'fullName' | 'isVerified' | 'isBlocked' | 'blockReason'>
  & { cards?: Maybe<Array<(
    { __typename?: 'CardToken' }
    & CardTokenFieldsFragment
  )>>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & ProfileFieldsFragment
  )>, documents?: Maybe<Array<(
    { __typename?: 'UserDocument' }
    & UserDocumentFieldsFragment
  )>> }
);

export type HandoverNoteFieldsFragment = (
  { __typename?: 'HandoverNote' }
  & Pick<HandoverNote, 'id' | 'createdAt' | 'type' | 'comment' | 'isUser'>
  & { user: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type HandoverFileFieldsFragment = (
  { __typename?: 'HandoverFile' }
  & Pick<HandoverFile, 'id' | 'type' | 'url'>
  & { file?: Maybe<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url'>
  )> }
);

export type HandoverFieldsFragment = (
  { __typename?: 'Handover' }
  & Pick<Handover, 'id' | 'status' | 'startOdometer' | 'endOdometer' | 'startFuel' | 'endFuel' | 'terms' | 'acceptIdentity' | 'startRequirements' | 'completeRequirements'>
  & { files?: Maybe<Array<(
    { __typename?: 'HandoverFile' }
    & HandoverFileFieldsFragment
  )>>, checklist: Array<(
    { __typename?: 'HandoverCheckList' }
    & Pick<HandoverCheckList, 'name' | 'required' | 'label' | 'value' | 'responsible' | 'stage'>
  )> }
);

export type ReservationUserFieldsFragment = (
  { __typename?: 'ReservationUser' }
  & Pick<ReservationUser, 'id' | 'firstName' | 'lastName' | 'fullName' | 'rating' | 'age' | 'dob' | 'avatar' | 'email' | 'cellPhone' | 'joinDate'>
);

export type VehicleTableFieldsFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'id' | 'nodeId' | 'createdAt' | 'updatedAt' | 'licence_no' | 'name' | 'enabled' | 'listingStatus' | 'approvalStatus'>
  & { images: Array<(
    { __typename?: 'VehicleImage' }
    & VehicleImageFieldsFragment
  )>, user: (
    { __typename?: 'VehicleUser' }
    & Pick<VehicleUser, 'fullName'>
  ) }
);

export type VehicleImageFieldsFragment = (
  { __typename?: 'VehicleImage' }
  & Pick<VehicleImage, 'id' | 'fileId' | 'view' | 'position' | 'approved'>
  & { file: (
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'key' | 'url'>
  ) }
);

export type AmountFieldFragment = (
  { __typename?: 'Amount' }
  & Pick<Amount, 'formatted' | 'formattedNoDecimals' | 'amount' | 'currencyCode' | 'amountFloat'>
);

type VehicleError_Vehicle_Fragment = { __typename?: 'Vehicle' };

type VehicleError_VehicleNotFoundError_Fragment = (
  { __typename?: 'VehicleNotFoundError' }
  & Pick<VehicleNotFoundError, 'errorCode' | 'message'>
);

type VehicleError_InvalidVehicleCategoryError_Fragment = (
  { __typename?: 'InvalidVehicleCategoryError' }
  & Pick<InvalidVehicleCategoryError, 'errorCode' | 'message'>
);

type VehicleError_InvalidVehicleSelectedError_Fragment = (
  { __typename?: 'InvalidVehicleSelectedError' }
  & Pick<InvalidVehicleSelectedError, 'errorCode' | 'message'>
);

type VehicleError_VehicleIsNotApprovedError_Fragment = (
  { __typename?: 'VehicleIsNotApprovedError' }
  & Pick<VehicleIsNotApprovedError, 'errorCode' | 'message'>
);

type VehicleError_VehicleNotRejectedError_Fragment = (
  { __typename?: 'VehicleNotRejectedError' }
  & Pick<VehicleNotRejectedError, 'errorCode' | 'message'>
);

type VehicleError_VehicleUnAuthorizedError_Fragment = (
  { __typename?: 'VehicleUnAuthorizedError' }
  & Pick<VehicleUnAuthorizedError, 'errorCode' | 'message'>
);

export type VehicleErrorFragment = VehicleError_Vehicle_Fragment | VehicleError_VehicleNotFoundError_Fragment | VehicleError_InvalidVehicleCategoryError_Fragment | VehicleError_InvalidVehicleSelectedError_Fragment | VehicleError_VehicleIsNotApprovedError_Fragment | VehicleError_VehicleNotRejectedError_Fragment | VehicleError_VehicleUnAuthorizedError_Fragment;

export type SearchVehicleFieldsFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'id' | 'defaultName' | 'name' | 'max_kilometers_per_day' | 'is_deliver_airport' | 'is_deliver_renter'>
  & { lat: Vehicle['mapLat'], lng: Vehicle['mapLng'] }
  & { images: Array<(
    { __typename?: 'VehicleImage' }
    & VehicleImageFieldsFragment
  )>, pricePerDay: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), stats?: Maybe<(
    { __typename?: 'VehicleSummary' }
    & Pick<VehicleSummary, 'reviews' | 'trips'>
    & { ratings: VehicleSummary['rating'] }
  )>, addresses: Array<(
    { __typename?: 'VehiclePlace' }
    & Pick<VehiclePlace, 'city' | 'state'>
  )> }
);

export type VehicleUserFieldsFragment = (
  { __typename?: 'VehicleUser' }
  & Pick<VehicleUser, 'id' | 'isBlocked' | 'firstName' | 'lastName' | 'fullName' | 'joinDate' | 'avatar' | 'rating'>
  & AdminUserDetailsFragment
);

export type AllVehicleFieldsFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'description' | 'vin' | 'odometer' | 'fueltype' | 'tracker' | 'transmission' | 'color' | 'rejectionReason' | 'rejectedAt' | 'price_per_day' | 'is_weekends_only' | 'is_weekdays_only' | 'price_in_cents_retail' | 'is_deliver_airport' | 'is_deliver_renter' | 'delivery_fee_airport' | 'delivery_fee_renter' | 'delivery_radius' | 'daily_kilometers_included' | 'max_kilometers_per_day' | 'minimum_days' | 'maximum_days' | 'is_drive_on_unpaved_roads' | 'is_allow_pets' | 'is_collect' | 'is_delivery' | 'is_show_location' | 't_c_accepted_at'>
  & { lat: Vehicle['mapLng'], lng: Vehicle['mapLat'] }
  & { stats?: Maybe<(
    { __typename?: 'VehicleSummary' }
    & Pick<VehicleSummary, 'reviews' | 'trips' | 'rating'>
  )>, details: (
    { __typename?: 'VehicleModel' }
    & Pick<VehicleModel, 'id' | 'year' | 'make' | 'model' | 'variant'>
  ), assets: Array<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id'>
  )>, recommendedPricePerDay: (
    { __typename?: 'GetVehicleRecommendationPrice' }
    & Pick<GetVehicleRecommendationPrice, 'price' | 'min' | 'max' | 'price_label'>
  ), recommendedPrice: (
    { __typename?: 'RecommendationPrice' }
    & { price: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), min: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), max: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ) }
  ), addresses: Array<(
    { __typename?: 'VehiclePlace' }
    & VehiclePlaceFieldsFragment
  )>, user: (
    { __typename?: 'VehicleUser' }
    & VehicleUserFieldsFragment
  ), features: Array<(
    { __typename?: 'VehicleFeature' }
    & Pick<VehicleFeature, 'id' | 'name' | 'icon'>
  )>, category: (
    { __typename?: 'VehicleCategory' }
    & Pick<VehicleCategory, 'id' | 'name'>
  ), pricePerDay: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), retailPrice: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), deliveryFeeAirport: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), deliveryFeeRenter: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), documents?: Maybe<Array<(
    { __typename?: 'VehicleDocument' }
    & Pick<VehicleDocument, 'id' | 'fileId' | 'type'>
    & { file: (
      { __typename?: 'FileEntity' }
      & Pick<FileEntity, 'id' | 'userId' | 'key' | 'url'>
    ) }
  )>>, detailsList?: Maybe<Array<(
    { __typename?: 'VehicleDetails' }
    & Pick<VehicleDetails, 'icon' | 'label' | 'description'>
  )>> }
  & VehicleTableFieldsFragment
);

export type VehiclePlaceFieldsFragment = (
  { __typename?: 'VehiclePlace' }
  & Pick<VehiclePlace, 'id' | 'createdAt' | 'updatedAt' | 'streetNumber' | 'addressLine1' | 'addressLine2' | 'googlePlaceId' | 'city' | 'suburb' | 'state' | 'country' | 'postalCode' | 'deliveryFee' | 'lat' | 'lng' | 'name' | 'enabled' | 'type'>
  & { deliveryFeeAmount: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ) }
);

export type PageInfoFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
);

export type UserStatusUpdateResFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'status' | 'isBlocked' | 'blockReason'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'isApprovedOwner' | 'isApprovedDriver'>
  )> }
);

export type DailyAttributesFieldsFragment = (
  { __typename?: 'VehicleDailyAttribute' }
  & Pick<VehicleDailyAttribute, 'id' | 'date' | 'status' | 'privateNote'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & BankAccountFieldsFragment
    )>, vehicles?: Maybe<(
      { __typename?: 'VehicleConnection' }
      & { edges: Array<(
        { __typename?: 'VehicleEdge' }
        & { node: (
          { __typename?: 'Vehicle' }
          & VehicleTableFieldsFragment
        ) }
      )> }
    )> }
    & UserFieldsFragment
  ) }
);

export type MeCheckinQueryVariables = Exact<{ [key: string]: never; }>;


export type MeCheckinQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type MyBookingsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
}>;


export type MyBookingsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { reservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & PagedReservationsWithVehicleFragment
    )> }
  ) }
);

export type MyVehicleReservationsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
  input?: Maybe<ReservationsQueryInput>;
}>;


export type MyVehicleReservationsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { vehicleReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & PagedReservationsWithVehicleFragment
    )> }
  ) }
);

export type GetUsersQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  sortBy?: Maybe<Scalars['String']>;
  where?: Maybe<UserWhereInput>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: (
    { __typename?: 'UsersResult' }
    & Pick<UsersResult, 'totalCount'>
    & { users: Array<(
      { __typename?: 'User' }
      & { bankAccount?: Maybe<(
        { __typename?: 'BankAccount' }
        & BankAccountFieldsFragment
      )> }
      & UserFieldsFragment
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & BankAccountFieldsFragment
    )>, profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileFieldsFragment
    )>, vehicles?: Maybe<(
      { __typename?: 'VehicleConnection' }
      & { edges: Array<(
        { __typename?: 'VehicleEdge' }
        & { node: (
          { __typename?: 'Vehicle' }
          & VehicleTableFieldsFragment
        ) }
      )> }
    )> }
    & UserFieldsFragment
  )> }
);

export type GetUserReservationsQueryVariables = Exact<{
  userId: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
}>;


export type GetUserReservationsQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { reservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & PagedReservationsWithVehicleFragment
    )> }
  )> }
);

export type GetAllVehiclesQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<VehicleOrderByInput>;
}>;


export type GetAllVehiclesQuery = (
  { __typename?: 'Query' }
  & { allVehicles: (
    { __typename?: 'VehicleConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), aggregate: (
      { __typename?: 'AggregateVehicle' }
      & Pick<AggregateVehicle, 'count' | 'totalPages' | 'currentPage'>
    ), edges: Array<(
      { __typename?: 'VehicleEdge' }
      & { node: (
        { __typename?: 'Vehicle' }
        & AllVehicleFieldsFragment
      ) }
    )> }
  ) }
);

export type GetVehiclesQueryVariables = Exact<{
  input: SearchVehicleInput;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetVehiclesQuery = (
  { __typename?: 'Query' }
  & { searchVehicles: (
    { __typename?: 'VehicleConnection' }
    & { aggregate: (
      { __typename?: 'AggregateVehicle' }
      & Pick<AggregateVehicle, 'count' | 'totalPages' | 'currentPage'>
    ), pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<(
      { __typename?: 'VehicleEdge' }
      & { node: (
        { __typename?: 'Vehicle' }
        & SearchVehicleFieldsFragment
      ) }
    )> }
  ) }
);

export type GetVehicleReviewsQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetVehicleReviewsQuery = (
  { __typename?: 'Query' }
  & { getVehicleReviews?: Maybe<(
    { __typename?: 'ReviewConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), aggregate: (
      { __typename?: 'AggregateReview' }
      & Pick<AggregateReview, 'count'>
    ), edges: Array<(
      { __typename?: 'ReviewEdge' }
      & { node: (
        { __typename?: 'VehicleReviewWithUser' }
        & Pick<VehicleReviewWithUser, 'id' | 'createdAt' | 'updatedAt' | 'comment' | 'note' | 'rating'>
        & { profile?: Maybe<(
          { __typename?: 'ReviewUser' }
          & Pick<ReviewUser, 'name' | 'avatar'>
        )> }
      ) }
    )> }
  )> }
);

export type GetVehicleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetVehicleQuery = (
  { __typename?: 'Query' }
  & { vehicle: (
    { __typename?: 'Vehicle' }
    & AllVehicleFieldsFragment
  ) }
);

export type GetUserVehicleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserVehicleQuery = (
  { __typename?: 'Query' }
  & { getUserVehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & AllVehicleFieldsFragment
  )> }
);

export type GetViewerVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerVehiclesQuery = (
  { __typename?: 'Query' }
  & { myVehicles?: Maybe<Array<(
    { __typename?: 'Vehicle' }
    & VehicleTableFieldsFragment
  )>> }
);

export type GetVehicleYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleYearsQuery = (
  { __typename?: 'Query' }
  & { vehicleYears: Array<(
    { __typename?: 'VehicleModel' }
    & Pick<VehicleModel, 'year'>
  )> }
);

export type GetVehicleMakesQueryVariables = Exact<{
  year: Scalars['String'];
}>;


export type GetVehicleMakesQuery = (
  { __typename?: 'Query' }
  & { vehicleMakes: Array<(
    { __typename?: 'VehicleMakes' }
    & Pick<VehicleMakes, 'make'>
  )> }
);

export type GetVehicleModelsQueryVariables = Exact<{
  year: Scalars['String'];
  make: Scalars['String'];
}>;


export type GetVehicleModelsQuery = (
  { __typename?: 'Query' }
  & { vehicleModels: Array<(
    { __typename?: 'VehicleModels' }
    & Pick<VehicleModels, 'model'>
  )> }
);

export type GetVehicleVariantsQueryVariables = Exact<{
  year: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
}>;


export type GetVehicleVariantsQuery = (
  { __typename?: 'Query' }
  & { vehicleVariants: Array<(
    { __typename?: 'VehicleModel' }
    & Pick<VehicleModel, 'id' | 'variant'>
  )> }
);

export type GetVehicleCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleCategoriesQuery = (
  { __typename?: 'Query' }
  & { vehicleCategories: Array<(
    { __typename?: 'VehicleCategory' }
    & Pick<VehicleCategory, 'id' | 'name' | 'description'>
  )> }
);

export type GetVehicleFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleFeaturesQuery = (
  { __typename?: 'Query' }
  & { vehicleFeatures: Array<(
    { __typename?: 'VehicleFeature' }
    & Pick<VehicleFeature, 'id' | 'name' | 'icon'>
  )> }
);

export type CreateVehicleMutationVariables = Exact<{
  input: CreateVehicleInput;
}>;


export type CreateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { createVehicle: (
    { __typename?: 'Vehicle' }
    & AllVehicleFieldsFragment
    & VehicleError_Vehicle_Fragment
  ) | (
    { __typename?: 'VehicleNotFoundError' }
    & VehicleError_VehicleNotFoundError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleCategoryError' }
    & VehicleError_InvalidVehicleCategoryError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleSelectedError' }
    & VehicleError_InvalidVehicleSelectedError_Fragment
  ) | (
    { __typename?: 'VehicleIsNotApprovedError' }
    & VehicleError_VehicleIsNotApprovedError_Fragment
  ) | (
    { __typename?: 'VehicleNotRejectedError' }
    & VehicleError_VehicleNotRejectedError_Fragment
  ) | (
    { __typename?: 'VehicleUnAuthorizedError' }
    & VehicleError_VehicleUnAuthorizedError_Fragment
  ) }
);

export type GetVehicleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetVehicleByIdQuery = (
  { __typename?: 'Query' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & AllVehicleFieldsFragment
  )> }
);

export type FileFieldsFragment = (
  { __typename: 'FileEntity' }
  & Pick<FileEntity, 'id' | 'userId' | 'url' | 'key'>
  & { meta?: Maybe<(
    { __typename?: 'FileMetaType' }
    & Pick<FileMetaType, 'filename'>
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'FileEntity' }
    & FileFieldsFragment
  ) }
);

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'FileEntity' }
    & FileFieldsFragment
  ) }
);

export type UploadUserImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadUserImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadUserImage: (
    { __typename: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url'>
  ) }
);

export type ApproveUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: ApproveUserInput;
}>;


export type ApproveUserMutation = (
  { __typename?: 'Mutation' }
  & { approveUser: (
    { __typename?: 'User' }
    & UserStatusUpdateResFragment
  ) }
);

export type RejectUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: ApproveUserInput;
}>;


export type RejectUserMutation = (
  { __typename?: 'Mutation' }
  & { rejectUser: (
    { __typename?: 'User' }
    & UserStatusUpdateResFragment
  ) }
);

export type BlockUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: BlockUserInput;
}>;


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { blockUser: (
    { __typename?: 'User' }
    & UserStatusUpdateResFragment
  ) }
);

export type UnblockUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & { unblockUser: (
    { __typename?: 'User' }
    & UserStatusUpdateResFragment
  ) }
);

export type ApproveVehicleImagesMutationVariables = Exact<{
  input: ApproveVehicleImageInput;
}>;


export type ApproveVehicleImagesMutation = (
  { __typename?: 'Mutation' }
  & { approveVehicleImages: Array<(
    { __typename?: 'VehicleImage' }
    & VehicleImageFieldsFragment
  )> }
);

export type RejectVehicleImagesMutationVariables = Exact<{
  input: ApproveVehicleImageInput;
}>;


export type RejectVehicleImagesMutation = (
  { __typename?: 'Mutation' }
  & { rejectVehicleImages: Array<(
    { __typename?: 'VehicleImage' }
    & VehicleImageFieldsFragment
  )> }
);

export type DeleteVehicleImageMutationVariables = Exact<{
  input: ApproveVehicleImageInput;
}>;


export type DeleteVehicleImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVehicleImages'>
);

export type UpdateVehicleDailyAttibuteMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateVehicleDailyAttributes;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type UpdateVehicleDailyAttibuteMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleDailyAttribute: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
    & { daily?: Maybe<Array<(
      { __typename?: 'DailyAttributes' }
      & Pick<DailyAttributes, 'vehicleId' | 'date' | 'isAvailable' | 'isBooked' | 'note'>
    )>> }
  ) }
);

export type LineItemFieldsFragment = (
  { __typename?: 'ReservationLineItems' }
  & Pick<ReservationLineItems, 'title' | 'description' | 'icon' | 'price' | 'main'>
  & { priceEyebrow: ReservationLineItems['eyebrow'] }
);

export type ReservationReviewFieldsFragment = (
  { __typename?: 'ReservationReview' }
  & Pick<ReservationReview, 'id' | 'type' | 'publicComment' | 'privateComment' | 'overall' | 'reviewed'>
  & { categories: Array<(
    { __typename?: 'ReviewCategory' }
    & Pick<ReviewCategory, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'rating' | 'comments'>
  )>, user: (
    { __typename?: 'Reviewer' }
    & Pick<Reviewer, 'fullName' | 'avatar'>
  ) }
);

export type ReservationFieldsFragment = (
  { __typename?: 'Reservation' }
  & Pick<Reservation, 'id' | 'isOwner' | 'rejectionNote' | 'rejectionNotePrivate' | 'driverNote' | 'address' | 'locationId' | 'paymentDate' | 'scopes' | 'startDate' | 'endDate' | 'status' | 'duration' | 'isConfirmed' | 'isBooked' | 'deliveryFee' | 'isDelivery' | 'isDeliveryAirport' | 'dailykilometers' | 'createdAt' | 'updatedAt' | 'couponCodes'>
  & { verifications?: Maybe<Array<(
    { __typename?: 'UserVerification' }
    & Pick<UserVerification, 'label' | 'type' | 'value'>
  )>>, pricePerDay: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), lineItems: Array<(
    { __typename?: 'ReservationLineItems' }
    & LineItemFieldsFragment
  )>, getTotal: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), payout?: Maybe<(
    { __typename?: 'Amount' }
    & AmountFieldFragment
  )>, dates: (
    { __typename?: 'ReservationDatesDto' }
    & Pick<ReservationDatesDto, 'namedMonthStartDate' | 'namedMonthEndDate' | 'isoEndDate' | 'isoStartDate' | 'startDate' | 'endDate' | 'startFullDate' | 'endFullDate' | 'formatEndDate' | 'formatStartDate'>
  ), insurancePerDay: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), depositTotal: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), user: (
    { __typename?: 'ReservationUser' }
    & ReservationUserFieldsFragment
  ), vehicleUser: (
    { __typename?: 'ReservationUser' }
    & ReservationUserFieldsFragment
  ), vehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'name' | 'defaultName' | 'description' | 'color' | 'fueltype' | 'tracker' | 'transmission' | 'max_kilometers_per_day' | 'maximum_days' | 'minimum_days' | 'is_allow_pets' | 'is_collect' | 'is_deliver_renter' | 'is_deliver_airport'>
    & { addresses: Array<(
      { __typename?: 'VehiclePlace' }
      & VehiclePlaceFieldsFragment
    )>, deliveryFeeRenter: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), details: (
      { __typename?: 'VehicleModel' }
      & Pick<VehicleModel, 'year' | 'make' | 'model' | 'variant'>
    ), features: Array<(
      { __typename?: 'VehicleFeature' }
      & Pick<VehicleFeature, 'name'>
    )>, category: (
      { __typename?: 'VehicleCategory' }
      & Pick<VehicleCategory, 'name'>
    ), images: Array<(
      { __typename?: 'VehicleImage' }
      & VehicleImageFieldsFragment
    )>, pricePerDay: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), user: (
      { __typename?: 'VehicleUser' }
      & VehicleUserFieldsFragment
    ), stats?: Maybe<(
      { __typename?: 'VehicleSummary' }
      & Pick<VehicleSummary, 'trips' | 'reviews'>
      & { ratings: VehicleSummary['rating'] }
    )> }
  ), reviews?: Maybe<Array<(
    { __typename?: 'ReservationReview' }
    & ReservationReviewFieldsFragment
  )>>, handover?: Maybe<(
    { __typename?: 'Handover' }
    & HandoverFieldsFragment
  )>, orderLineTotals?: Maybe<(
    { __typename?: 'OrderLineTotals' }
    & { balance: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), totalPaid: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), depositBalance: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), serviceTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), payoutTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), lineItemTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), discountTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), pricePerDayTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), rentalTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), insuranceTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), deliveryTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), reservationTotal: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ) }
  )>, payments: Array<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'type' | 'amount' | 'method' | 'createdAt' | 'transactionDate'>
    & { amountFormatted?: Maybe<(
      { __typename?: 'Amount' }
      & Pick<Amount, 'formatted'>
    )> }
  )> }
);

type ReservationErrors_Reservation_Fragment = { __typename?: 'Reservation' };

type ReservationErrors_InvalidReservationError_Fragment = (
  { __typename?: 'InvalidReservationError' }
  & Pick<InvalidReservationError, 'errorCode' | 'message'>
);

type ReservationErrors_VehicleUnAvailableError_Fragment = (
  { __typename?: 'VehicleUnAvailableError' }
  & Pick<VehicleUnAvailableError, 'errorCode' | 'message'>
);

type ReservationErrors_ReservationDoesNotExistError_Fragment = (
  { __typename?: 'ReservationDoesNotExistError' }
  & Pick<ReservationDoesNotExistError, 'errorCode' | 'message'>
);

type ReservationErrors_InvalidActionError_Fragment = (
  { __typename?: 'InvalidActionError' }
  & Pick<InvalidActionError, 'errorCode' | 'message'>
);

type ReservationErrors_ReservationExpiredError_Fragment = (
  { __typename?: 'ReservationExpiredError' }
  & Pick<ReservationExpiredError, 'errorCode' | 'message'>
);

type ReservationErrors_InvalidReservationDatesError_Fragment = (
  { __typename?: 'InvalidReservationDatesError' }
  & Pick<InvalidReservationDatesError, 'errorCode' | 'message'>
);

type ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment = (
  { __typename?: 'VehicleIsBookedForThisPeriodError' }
  & Pick<VehicleIsBookedForThisPeriodError, 'errorCode' | 'message'>
);

type ReservationErrors_InvalidDurationCriteriaError_Fragment = (
  { __typename?: 'InvalidDurationCriteriaError' }
  & Pick<InvalidDurationCriteriaError, 'errorCode' | 'message'>
);

type ReservationErrors_ReservationAlreadyProcessedError_Fragment = (
  { __typename?: 'ReservationAlreadyProcessedError' }
  & Pick<ReservationAlreadyProcessedError, 'errorCode' | 'message'>
);

type ReservationErrors_UnAuthorizedAccessError_Fragment = (
  { __typename?: 'UnAuthorizedAccessError' }
  & Pick<UnAuthorizedAccessError, 'errorCode' | 'message'>
);

type ReservationErrors_InvalidVehicleMethodError_Fragment = (
  { __typename?: 'InvalidVehicleMethodError' }
  & Pick<InvalidVehicleMethodError, 'errorCode' | 'message'>
);

type ReservationErrors_PickUpAndDropOffRequiredError_Fragment = { __typename?: 'PickUpAndDropOffRequiredError' };

type ReservationErrors_BookingNotAllowedError_Fragment = { __typename?: 'BookingNotAllowedError' };

type ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment = { __typename?: 'CancelationTimeHasAlreadyExpiredError' };

type ReservationErrors_CouponCodeInvalidError_Fragment = { __typename?: 'CouponCodeInvalidError' };

export type ReservationErrorsFragment = ReservationErrors_Reservation_Fragment | ReservationErrors_InvalidReservationError_Fragment | ReservationErrors_VehicleUnAvailableError_Fragment | ReservationErrors_ReservationDoesNotExistError_Fragment | ReservationErrors_InvalidActionError_Fragment | ReservationErrors_ReservationExpiredError_Fragment | ReservationErrors_InvalidReservationDatesError_Fragment | ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment | ReservationErrors_InvalidDurationCriteriaError_Fragment | ReservationErrors_ReservationAlreadyProcessedError_Fragment | ReservationErrors_UnAuthorizedAccessError_Fragment | ReservationErrors_InvalidVehicleMethodError_Fragment | ReservationErrors_PickUpAndDropOffRequiredError_Fragment | ReservationErrors_BookingNotAllowedError_Fragment | ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment | ReservationErrors_CouponCodeInvalidError_Fragment;

export type PagedReservationFieldsFragment = (
  { __typename?: 'Reservation' }
  & Pick<Reservation, 'id' | 'status' | 'createdAt' | 'startDate' | 'endDate' | 'duration'>
  & { dates: (
    { __typename?: 'ReservationDatesDto' }
    & Pick<ReservationDatesDto, 'namedMonthStartDate' | 'namedMonthEndDate' | 'isoEndDate' | 'isoStartDate' | 'startDate' | 'endDate' | 'startFullDate' | 'endFullDate' | 'formatEndDate' | 'formatStartDate'>
  ), getTotal: (
    { __typename?: 'Amount' }
    & AmountFieldFragment
  ), payout?: Maybe<(
    { __typename?: 'Amount' }
    & AmountFieldFragment
  )>, user: (
    { __typename?: 'ReservationUser' }
    & Pick<ReservationUser, 'id' | 'fullName'>
  ), vehicle: (
    { __typename?: 'Vehicle' }
    & { user: (
      { __typename?: 'VehicleUser' }
      & Pick<VehicleUser, 'id'>
    ) }
  ), reviews?: Maybe<Array<(
    { __typename?: 'ReservationReview' }
    & ReservationReviewFieldsFragment
  )>> }
);

export type PagedReservationsFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & PagedReservationFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateReservation' }
    & Pick<AggregateReservation, 'count'>
  ) }
);

export type PagedReservationsWithReviewsFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & { reviews?: Maybe<Array<(
        { __typename?: 'ReservationReview' }
        & Pick<ReservationReview, 'id' | 'publicComment' | 'overall' | 'type'>
      )>>, vehicle: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'name'>
      ) }
      & PagedReservationFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateReservation' }
    & Pick<AggregateReservation, 'count'>
  ) }
);

export type PagedReservationsWithVehicleFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & { vehicle: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'name'>
        & { images: Array<(
          { __typename?: 'VehicleImage' }
          & { file: (
            { __typename?: 'FileEntity' }
            & Pick<FileEntity, 'url'>
          ) }
        )>, user: (
          { __typename?: 'VehicleUser' }
          & Pick<VehicleUser, 'id'>
        ) }
      ) }
      & PagedReservationFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateReservation' }
    & Pick<AggregateReservation, 'count'>
  ) }
);

export type PagedReservationsWithVehicleNameFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & { vehicle: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'name'>
      ) }
      & PagedReservationFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateReservation' }
    & Pick<AggregateReservation, 'count'>
  ) }
);

export type GetReservationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReservationQuery = (
  { __typename?: 'Query' }
  & { getReservation: (
    { __typename?: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type ReservationsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
  input?: Maybe<ReservationsQueryInput>;
}>;


export type ReservationsQuery = (
  { __typename?: 'Query' }
  & { reservations: (
    { __typename?: 'ReservationConnection' }
    & PagedReservationsWithVehicleNameFragment
  ) }
);

export type ReservationsWithReviewsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReservationWhereInput>;
  orderBy?: Maybe<ReservationOrderByInput>;
  input?: Maybe<ReservationsQueryInput>;
}>;


export type ReservationsWithReviewsQuery = (
  { __typename?: 'Query' }
  & { reservationsWithReviews: (
    { __typename?: 'ReservationConnection' }
    & PagedReservationsWithReviewsFragment
  ) }
);

export type RequestReservationMutationVariables = Exact<{
  input: RequestReservationInput;
}>;


export type RequestReservationMutation = (
  { __typename?: 'Mutation' }
  & { requestReservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
    & { pricePerDay: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), lineItems: Array<(
      { __typename?: 'ReservationLineItems' }
      & LineItemFieldsFragment
    )> }
    & ReservationErrors_Reservation_Fragment
  ) | (
    { __typename?: 'InvalidReservationError' }
    & ReservationErrors_InvalidReservationError_Fragment
  ) | (
    { __typename?: 'VehicleUnAvailableError' }
    & ReservationErrors_VehicleUnAvailableError_Fragment
  ) | (
    { __typename?: 'ReservationDoesNotExistError' }
    & ReservationErrors_ReservationDoesNotExistError_Fragment
  ) | (
    { __typename?: 'InvalidActionError' }
    & ReservationErrors_InvalidActionError_Fragment
  ) | (
    { __typename?: 'ReservationExpiredError' }
    & ReservationErrors_ReservationExpiredError_Fragment
  ) | (
    { __typename?: 'InvalidReservationDatesError' }
    & ReservationErrors_InvalidReservationDatesError_Fragment
  ) | (
    { __typename?: 'VehicleIsBookedForThisPeriodError' }
    & ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment
  ) | (
    { __typename?: 'InvalidDurationCriteriaError' }
    & ReservationErrors_InvalidDurationCriteriaError_Fragment
  ) | (
    { __typename?: 'ReservationAlreadyProcessedError' }
    & ReservationErrors_ReservationAlreadyProcessedError_Fragment
  ) | (
    { __typename?: 'UnAuthorizedAccessError' }
    & ReservationErrors_UnAuthorizedAccessError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleMethodError' }
    & ReservationErrors_InvalidVehicleMethodError_Fragment
  ) | (
    { __typename?: 'PickUpAndDropOffRequiredError' }
    & ReservationErrors_PickUpAndDropOffRequiredError_Fragment
  ) | (
    { __typename?: 'BookingNotAllowedError' }
    & ReservationErrors_BookingNotAllowedError_Fragment
  ) | (
    { __typename?: 'CancelationTimeHasAlreadyExpiredError' }
    & ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment
  ) | (
    { __typename?: 'CouponCodeInvalidError' }
    & ReservationErrors_CouponCodeInvalidError_Fragment
  ) }
);

export type AcceptQuoteMutationVariables = Exact<{
  id: Scalars['String'];
  input: AcceptQuotationInput;
}>;


export type AcceptQuoteMutation = (
  { __typename?: 'Mutation' }
  & { acceptQuote: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'status'>
    & { pricePerDay: (
      { __typename?: 'Amount' }
      & AmountFieldFragment
    ), lineItems: Array<(
      { __typename?: 'ReservationLineItems' }
      & LineItemFieldsFragment
    )> }
    & ReservationErrors_Reservation_Fragment
  ) | (
    { __typename?: 'InvalidReservationError' }
    & ReservationErrors_InvalidReservationError_Fragment
  ) | (
    { __typename?: 'VehicleUnAvailableError' }
    & ReservationErrors_VehicleUnAvailableError_Fragment
  ) | (
    { __typename?: 'ReservationDoesNotExistError' }
    & ReservationErrors_ReservationDoesNotExistError_Fragment
  ) | (
    { __typename?: 'InvalidActionError' }
    & ReservationErrors_InvalidActionError_Fragment
  ) | (
    { __typename?: 'ReservationExpiredError' }
    & ReservationErrors_ReservationExpiredError_Fragment
  ) | (
    { __typename?: 'InvalidReservationDatesError' }
    & ReservationErrors_InvalidReservationDatesError_Fragment
  ) | (
    { __typename?: 'VehicleIsBookedForThisPeriodError' }
    & ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment
  ) | (
    { __typename?: 'InvalidDurationCriteriaError' }
    & ReservationErrors_InvalidDurationCriteriaError_Fragment
  ) | (
    { __typename?: 'ReservationAlreadyProcessedError' }
    & ReservationErrors_ReservationAlreadyProcessedError_Fragment
  ) | (
    { __typename?: 'UnAuthorizedAccessError' }
    & ReservationErrors_UnAuthorizedAccessError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleMethodError' }
    & ReservationErrors_InvalidVehicleMethodError_Fragment
  ) | (
    { __typename?: 'PickUpAndDropOffRequiredError' }
    & ReservationErrors_PickUpAndDropOffRequiredError_Fragment
  ) | (
    { __typename?: 'BookingNotAllowedError' }
    & ReservationErrors_BookingNotAllowedError_Fragment
  ) | (
    { __typename?: 'CancelationTimeHasAlreadyExpiredError' }
    & ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment
  ) | (
    { __typename?: 'CouponCodeInvalidError' }
    & ReservationErrors_CouponCodeInvalidError_Fragment
  ) }
);

export type CreatePaymentReservationMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<PaymentMethodInput>;
}>;


export type CreatePaymentReservationMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentReservation: (
    { __typename?: 'PaymentResponse' }
    & Pick<PaymentResponse, 'method' | 'metadata'>
  ) }
);

export type ApproveReservationMutationVariables = Exact<{
  input: ReservationApprovalInput;
}>;


export type ApproveReservationMutation = (
  { __typename?: 'Mutation' }
  & { approveReservation: (
    { __typename?: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type RejectReservationMutationVariables = Exact<{
  input: ReservationApprovalInput;
}>;


export type RejectReservationMutation = (
  { __typename?: 'Mutation' }
  & { rejectReservation: (
    { __typename?: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type SetReservationDeliveryMutationVariables = Exact<{
  id: Scalars['String'];
  input: SetAddressInput;
}>;


export type SetReservationDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { setIsDelivery: (
    { __typename?: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type SetReservationDeliveryAirportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SetReservationDeliveryAirportMutation = (
  { __typename?: 'Mutation' }
  & { setIsDeliveryAirport: (
    { __typename?: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type VehicleAvailabilityQueryVariables = Exact<{
  id: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type VehicleAvailabilityQuery = (
  { __typename?: 'Query' }
  & { vehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
    & { daily?: Maybe<Array<(
      { __typename?: 'DailyAttributes' }
      & Pick<DailyAttributes, 'vehicleId' | 'date' | 'isAvailable' | 'isBooked' | 'note'>
    )>> }
  ) }
);

export type DashboardDriverFieldsFragment = (
  { __typename?: 'Reservation' }
  & Pick<Reservation, 'id' | 'startDate' | 'endDate' | 'status'>
  & { dates: (
    { __typename?: 'ReservationDatesDto' }
    & Pick<ReservationDatesDto, 'namedMonthStartDate' | 'namedMonthEndDate' | 'isoEndDate' | 'isoStartDate' | 'startDate' | 'endDate' | 'startFullDate' | 'endFullDate' | 'formatEndDate' | 'formatStartDate'>
  ), getTotal: (
    { __typename?: 'Amount' }
    & Pick<Amount, 'formatted'>
  ), vehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'name'>
    & { user: (
      { __typename?: 'VehicleUser' }
      & VehicleUserFieldsFragment
    ), images: Array<(
      { __typename?: 'VehicleImage' }
      & Pick<VehicleImage, 'view'>
      & { file: (
        { __typename?: 'FileEntity' }
        & Pick<FileEntity, 'id' | 'userId' | 'url'>
      ) }
    )> }
  ), user: (
    { __typename?: 'ReservationUser' }
    & Pick<ReservationUser, 'id'>
  ), reviews?: Maybe<Array<(
    { __typename?: 'ReservationReview' }
    & ReservationReviewFieldsFragment
  )>> }
);

export type DashboardDriverConnectionFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & DashboardDriverFieldsFragment
    ) }
  )> }
);

export type DashboardDriverQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDriverQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { activeReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardDriverConnectionFragment
    )>, futureReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardDriverConnectionFragment
    )>, pastReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardDriverConnectionFragment
    )>, requests?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardDriverConnectionFragment
    )> }
  ) }
);

export type DashboardOwnerFieldsFragment = (
  { __typename?: 'Reservation' }
  & Pick<Reservation, 'id' | 'startDate' | 'endDate' | 'status'>
  & { dates: (
    { __typename?: 'ReservationDatesDto' }
    & Pick<ReservationDatesDto, 'namedMonthStartDate' | 'namedMonthEndDate' | 'isoEndDate' | 'isoStartDate' | 'startDate' | 'endDate' | 'startFullDate' | 'endFullDate' | 'formatEndDate' | 'formatStartDate'>
  ), payout?: Maybe<(
    { __typename?: 'Amount' }
    & Pick<Amount, 'formatted'>
  )>, vehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'name' | 'licence_no'>
    & { images: Array<(
      { __typename?: 'VehicleImage' }
      & Pick<VehicleImage, 'view'>
      & { file: (
        { __typename?: 'FileEntity' }
        & Pick<FileEntity, 'id' | 'userId' | 'url'>
      ) }
    )>, user: (
      { __typename?: 'VehicleUser' }
      & Pick<VehicleUser, 'id'>
    ) }
  ), user: (
    { __typename?: 'ReservationUser' }
    & ReservationUserFieldsFragment
  ) }
);

export type DashboardOwnerConnectionFragment = (
  { __typename?: 'ReservationConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationEdge' }
    & { node: (
      { __typename?: 'Reservation' }
      & DashboardOwnerFieldsFragment
    ) }
  )> }
);

export type DashboardOwnerQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardOwnerQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { activeReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardOwnerConnectionFragment
    )>, futureReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardOwnerConnectionFragment
    )>, pastReservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardOwnerConnectionFragment
    )>, requests?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & DashboardOwnerConnectionFragment
    )> }
  ) }
);

export type MyReservationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyReservationsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { reservations?: Maybe<(
      { __typename?: 'ReservationConnection' }
      & PagedReservationsWithVehicleFragment
    )> }
  ) }
);

export type CancelReservationMutationVariables = Exact<{
  id: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CancelReservationMutation = (
  { __typename?: 'Mutation' }
  & { cancelReservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'status' | 'scopes'>
    & ReservationErrors_Reservation_Fragment
  ) | (
    { __typename?: 'InvalidReservationError' }
    & ReservationErrors_InvalidReservationError_Fragment
  ) | (
    { __typename?: 'VehicleUnAvailableError' }
    & ReservationErrors_VehicleUnAvailableError_Fragment
  ) | (
    { __typename?: 'ReservationDoesNotExistError' }
    & ReservationErrors_ReservationDoesNotExistError_Fragment
  ) | (
    { __typename?: 'InvalidActionError' }
    & ReservationErrors_InvalidActionError_Fragment
  ) | (
    { __typename?: 'ReservationExpiredError' }
    & ReservationErrors_ReservationExpiredError_Fragment
  ) | (
    { __typename?: 'InvalidReservationDatesError' }
    & ReservationErrors_InvalidReservationDatesError_Fragment
  ) | (
    { __typename?: 'VehicleIsBookedForThisPeriodError' }
    & ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment
  ) | (
    { __typename?: 'InvalidDurationCriteriaError' }
    & ReservationErrors_InvalidDurationCriteriaError_Fragment
  ) | (
    { __typename?: 'ReservationAlreadyProcessedError' }
    & ReservationErrors_ReservationAlreadyProcessedError_Fragment
  ) | (
    { __typename?: 'UnAuthorizedAccessError' }
    & ReservationErrors_UnAuthorizedAccessError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleMethodError' }
    & ReservationErrors_InvalidVehicleMethodError_Fragment
  ) | (
    { __typename?: 'PickUpAndDropOffRequiredError' }
    & ReservationErrors_PickUpAndDropOffRequiredError_Fragment
  ) | (
    { __typename?: 'BookingNotAllowedError' }
    & ReservationErrors_BookingNotAllowedError_Fragment
  ) | (
    { __typename?: 'CancelationTimeHasAlreadyExpiredError' }
    & ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment
  ) | (
    { __typename?: 'CouponCodeInvalidError' }
    & ReservationErrors_CouponCodeInvalidError_Fragment
  ) }
);

export type CompleteReservationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompleteReservationMutation = (
  { __typename?: 'Mutation' }
  & { completeReservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'status' | 'scopes'>
    & ReservationErrors_Reservation_Fragment
  ) | (
    { __typename?: 'InvalidReservationError' }
    & ReservationErrors_InvalidReservationError_Fragment
  ) | (
    { __typename?: 'VehicleUnAvailableError' }
    & ReservationErrors_VehicleUnAvailableError_Fragment
  ) | (
    { __typename?: 'ReservationDoesNotExistError' }
    & ReservationErrors_ReservationDoesNotExistError_Fragment
  ) | (
    { __typename?: 'InvalidActionError' }
    & ReservationErrors_InvalidActionError_Fragment
  ) | (
    { __typename?: 'ReservationExpiredError' }
    & ReservationErrors_ReservationExpiredError_Fragment
  ) | (
    { __typename?: 'InvalidReservationDatesError' }
    & ReservationErrors_InvalidReservationDatesError_Fragment
  ) | (
    { __typename?: 'VehicleIsBookedForThisPeriodError' }
    & ReservationErrors_VehicleIsBookedForThisPeriodError_Fragment
  ) | (
    { __typename?: 'InvalidDurationCriteriaError' }
    & ReservationErrors_InvalidDurationCriteriaError_Fragment
  ) | (
    { __typename?: 'ReservationAlreadyProcessedError' }
    & ReservationErrors_ReservationAlreadyProcessedError_Fragment
  ) | (
    { __typename?: 'UnAuthorizedAccessError' }
    & ReservationErrors_UnAuthorizedAccessError_Fragment
  ) | (
    { __typename?: 'InvalidVehicleMethodError' }
    & ReservationErrors_InvalidVehicleMethodError_Fragment
  ) | (
    { __typename?: 'PickUpAndDropOffRequiredError' }
    & ReservationErrors_PickUpAndDropOffRequiredError_Fragment
  ) | (
    { __typename?: 'BookingNotAllowedError' }
    & ReservationErrors_BookingNotAllowedError_Fragment
  ) | (
    { __typename?: 'CancelationTimeHasAlreadyExpiredError' }
    & ReservationErrors_CancelationTimeHasAlreadyExpiredError_Fragment
  ) | (
    { __typename?: 'CouponCodeInvalidError' }
    & ReservationErrors_CouponCodeInvalidError_Fragment
  ) }
);

export type BankAccountFieldsFragment = (
  { __typename?: 'BankAccount' }
  & Pick<BankAccount, 'id' | 'bankName' | 'accountNumber' | 'accountType' | 'accountName' | 'branchCode'>
);

export type UpdateUserBankAccountMutationVariables = Exact<{
  id: Scalars['String'];
  input: BankAccountUpdate;
}>;


export type UpdateUserBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccount' }
      & BankAccountFieldsFragment
    )> }
  ) }
);

export type UpdateUserCommissionMutationVariables = Exact<{
  id: Scalars['String'];
  commission: Scalars['Float'];
}>;


export type UpdateUserCommissionMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCommission: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileFieldsFragment
    )> }
  ) }
);

export type GetCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCitiesQuery = (
  { __typename?: 'Query' }
  & { findPlaces: Array<(
    { __typename?: 'City' }
    & Pick<City, 'name'>
  )> }
);

type ProviderAuthResult_AuthTokens_Fragment = (
  { __typename?: 'AuthTokens' }
  & Pick<AuthTokens, 'accessToken'>
);

type ProviderAuthResult_CredentialsTakenError_Fragment = (
  { __typename?: 'CredentialsTakenError' }
  & Pick<CredentialsTakenError, 'message' | 'errorCode'>
);

type ProviderAuthResult_ProviderNotRegisteredError_Fragment = (
  { __typename?: 'ProviderNotRegisteredError' }
  & Pick<ProviderNotRegisteredError, 'message' | 'errorCode'>
);

type ProviderAuthResult_ProviderAlreadyAssignedError_Fragment = (
  { __typename?: 'ProviderAlreadyAssignedError' }
  & Pick<ProviderAlreadyAssignedError, 'message' | 'errorCode'>
);

type ProviderAuthResult_NoEmailFoundOnProfileError_Fragment = { __typename?: 'NoEmailFoundOnProfileError' };

export type ProviderAuthResultFragment = ProviderAuthResult_AuthTokens_Fragment | ProviderAuthResult_CredentialsTakenError_Fragment | ProviderAuthResult_ProviderNotRegisteredError_Fragment | ProviderAuthResult_ProviderAlreadyAssignedError_Fragment | ProviderAuthResult_NoEmailFoundOnProfileError_Fragment;

export type ProviderRegisterMutationVariables = Exact<{
  input: ProviderRegisterInput;
}>;


export type ProviderRegisterMutation = (
  { __typename?: 'Mutation' }
  & { providerRegister: (
    { __typename?: 'AuthTokens' }
    & ProviderAuthResult_AuthTokens_Fragment
  ) | (
    { __typename?: 'CredentialsTakenError' }
    & ProviderAuthResult_CredentialsTakenError_Fragment
  ) | (
    { __typename?: 'ProviderNotRegisteredError' }
    & ProviderAuthResult_ProviderNotRegisteredError_Fragment
  ) | (
    { __typename?: 'ProviderAlreadyAssignedError' }
    & ProviderAuthResult_ProviderAlreadyAssignedError_Fragment
  ) | (
    { __typename?: 'NoEmailFoundOnProfileError' }
    & ProviderAuthResult_NoEmailFoundOnProfileError_Fragment
  ) }
);

export type ProviderLoginMutationVariables = Exact<{
  input: ProviderLoginInput;
}>;


export type ProviderLoginMutation = (
  { __typename?: 'Mutation' }
  & { providerLogin: (
    { __typename?: 'AuthTokens' }
    & ProviderAuthResult_AuthTokens_Fragment
  ) | (
    { __typename?: 'CredentialsTakenError' }
    & ProviderAuthResult_CredentialsTakenError_Fragment
  ) | (
    { __typename?: 'ProviderNotRegisteredError' }
    & ProviderAuthResult_ProviderNotRegisteredError_Fragment
  ) | (
    { __typename?: 'ProviderAlreadyAssignedError' }
    & ProviderAuthResult_ProviderAlreadyAssignedError_Fragment
  ) | (
    { __typename?: 'NoEmailFoundOnProfileError' }
    & ProviderAuthResult_NoEmailFoundOnProfileError_Fragment
  ) }
);

export type ExportUsersMutationVariables = Exact<{
  input: ExportUsersInput;
}>;


export type ExportUsersMutation = (
  { __typename?: 'Mutation' }
  & { exportUsers?: Maybe<(
    { __typename?: 'ExportUsersResult' }
    & Pick<ExportUsersResult, 'message'>
  ) | (
    { __typename?: 'ForbidenError' }
    & Pick<ForbidenError, 'message'>
  )> }
);

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInput;
  reservationId: Scalars['String'];
}>;


export type CreateReviewMutation = (
  { __typename?: 'Mutation' }
  & { createReview: (
    { __typename: 'Review' }
    & Pick<Review, 'id'>
  ) | (
    { __typename: 'ReviewTimeExpiredError' }
    & Pick<ReviewTimeExpiredError, 'errorCode' | 'message'>
  ) | { __typename: 'ReservationIncompleteError' } | { __typename: 'UnAuthorizedActionError' } | { __typename: 'ReservationAlreadyReviewed' } | { __typename: 'ReviewNotFoundError' } }
);

export type UpdateReviewMutationVariables = Exact<{
  input: UpdateReviewInput;
  reviewId: Scalars['String'];
}>;


export type UpdateReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateReview: (
    { __typename?: 'Review' }
    & Pick<Review, 'id'>
  ) | { __typename?: 'ReviewTimeExpiredError' } | { __typename?: 'ReservationIncompleteError' } | { __typename?: 'UnAuthorizedActionError' } | { __typename?: 'ReservationAlreadyReviewed' } | { __typename?: 'ReviewNotFoundError' } }
);

export type GetCategoriesQueryVariables = Exact<{
  input: TypeInput;
}>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCategories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'type'>
  )> }
);

export type TransactionFieldsFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'createdAt' | 'method' | 'amount' | 'status' | 'type' | 'response'>
  & { amountFormatted?: Maybe<(
    { __typename?: 'Amount' }
    & Pick<Amount, 'formatted'>
  )> }
);

export type TransactionFieldsWithReservationFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'createdAt' | 'method' | 'amount' | 'status' | 'type' | 'response'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'email' | 'role'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'phoneNumber'>
    )> }
  )>, amountFormatted?: Maybe<(
    { __typename?: 'Amount' }
    & Pick<Amount, 'formatted'>
  )>, reservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'status' | 'startDate' | 'endDate'>
    & { vehicle: (
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'name'>
    ) }
  ) }
);

export type PagedTransactionsFragment = (
  { __typename?: 'TransactionConnection' }
  & { edges: Array<(
    { __typename?: 'TransactionEdge' }
    & { node: (
      { __typename?: 'Transaction' }
      & TransactionFieldsWithReservationFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateTransaction' }
    & Pick<AggregateTransaction, 'count'>
  ) }
);

export type GetTransactionsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByInput>;
}>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { getTransactions: (
    { __typename?: 'TransactionConnection' }
    & PagedTransactionsFragment
  ) }
);

export type GetTransactionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTransactionByIdQuery = (
  { __typename?: 'Query' }
  & { getTransactionById?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactionFieldsWithReservationFragment
  )> }
);

export type ReservationHandoverQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReservationHandoverQuery = (
  { __typename?: 'Query' }
  & { getReservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'scopes'>
    & { handover?: Maybe<(
      { __typename?: 'Handover' }
      & HandoverFieldsFragment
    )> }
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type GetHandoverByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetHandoverByIdQuery = (
  { __typename?: 'Query' }
  & { getHandoverById: (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'Handover' }
    & HandoverFieldsFragment
  ) }
);

export type CreateHandoverMutationVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type CreateHandoverMutation = (
  { __typename?: 'Mutation' }
  & { createHandover: (
    { __typename?: 'ReservationNotFoundError' }
    & Pick<ReservationNotFoundError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | { __typename?: 'HandoverAccessError' } | (
    { __typename?: 'Handover' }
    & HandoverFieldsFragment
  ) }
);

export type UpdateHandoverMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateHandoverInput;
}>;


export type UpdateHandoverMutation = (
  { __typename?: 'Mutation' }
  & { updateHandover: (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'Handover' }
    & HandoverFieldsFragment
  ) }
);

export type AddHandoverFileMutationVariables = Exact<{
  handoverId: Scalars['String'];
  input: AddHandoverFileInput;
}>;


export type AddHandoverFileMutation = (
  { __typename?: 'Mutation' }
  & { addHandoverFile: (
    { __typename?: 'HandoverFile' }
    & HandoverFileFieldsFragment
  ) }
);

export type DeleteHandoverFileMutationVariables = Exact<{
  handoverFileId: Scalars['String'];
  handoverId: Scalars['String'];
}>;


export type DeleteHandoverFileMutation = (
  { __typename?: 'Mutation' }
  & { deleteHandoverFile: (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'HandoverAccessError' }
    & Pick<HandoverAccessError, 'errorCode' | 'message'>
  ) | { __typename?: 'DeletedResult' } }
);

export type AddNoteToHandoverMutationVariables = Exact<{
  handoverId: Scalars['String'];
  input: AddNoteToHandoverInput;
}>;


export type AddNoteToHandoverMutation = (
  { __typename?: 'Mutation' }
  & { addNoteToHandover: (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'HandoverAccessError' }
    & Pick<HandoverAccessError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'HandoverNote' }
    & HandoverNoteFieldsFragment
  ) }
);

export type UpdateHandoverNoteMutationVariables = Exact<{
  handoverId: Scalars['String'];
  handoverNoteId: Scalars['String'];
  input: UpdateNoteInput;
}>;


export type UpdateHandoverNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateHandoverNote: (
    { __typename?: 'InvalidHandoverError' }
    & Pick<InvalidHandoverError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'HandoverAccessError' }
    & Pick<HandoverAccessError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'HandoverNote' }
    & HandoverNoteFieldsFragment
  ) }
);

export type GetVehicleNotesQueryVariables = Exact<{
  vehicleId: Scalars['String'];
}>;


export type GetVehicleNotesQuery = (
  { __typename?: 'Query' }
  & { getVehicleNotes: Array<(
    { __typename?: 'VehicleNote' }
    & VehicleNoteFieldsFragment
  )> }
);

export type DeleteVehicleNoteMutationVariables = Exact<{
  noteId: Scalars['String'];
}>;


export type DeleteVehicleNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicleNote: (
    { __typename?: 'VehicleNote' }
    & Pick<VehicleNote, 'id'>
  ) }
);

export type AddNoteMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  input: CreateVehicleNoteInput;
}>;


export type AddNoteMutation = (
  { __typename?: 'Mutation' }
  & { addVehicleNote: (
    { __typename?: 'VehicleNote' }
    & VehicleNoteFieldsFragment
  ) }
);

export type PagedReservationNotesFragment = (
  { __typename: 'ReservationNoteConnection' }
  & { edges: Array<(
    { __typename?: 'ReservationNoteEdge' }
    & { node: (
      { __typename?: 'ReservationNote' }
      & ReservationNoteFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateReservationNote' }
    & Pick<AggregateReservationNote, 'count'>
  ) }
);

export type PagedProfileNotesFragment = (
  { __typename: 'ProfileNoteConnection' }
  & { edges: Array<(
    { __typename?: 'ProfileNoteEdge' }
    & { node: (
      { __typename?: 'ProfileNote' }
      & ProfileNoteFieldsFragment
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & PageInfoFragment
  ), aggregate: (
    { __typename?: 'AggregateProfileNote' }
    & Pick<AggregateProfileNote, 'count'>
  ) }
);

export type GetReservationNotesQueryVariables = Exact<{
  input: QueryReservationNotesInput;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReservationNoteOrderByInput>;
  where?: Maybe<ReservationNoteWhereInput>;
}>;


export type GetReservationNotesQuery = (
  { __typename?: 'Query' }
  & { getReservationNotes?: Maybe<(
    { __typename?: 'ReservationNoteConnection' }
    & PagedReservationNotesFragment
  )> }
);

export type DeleteReservationNoteMutationVariables = Exact<{
  id: Scalars['String'];
  reservationId: Scalars['String'];
}>;


export type DeleteReservationNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteReservationNote: (
    { __typename?: 'ReservationNote' }
    & Pick<ReservationNote, 'id'>
  ) }
);

export type AddReservationNoteMutationVariables = Exact<{
  input: AddReservationNotesInput;
}>;


export type AddReservationNoteMutation = (
  { __typename?: 'Mutation' }
  & { addReservationNote: (
    { __typename?: 'ReservationNote' }
    & ReservationNoteFieldsFragment
  ) }
);

export type GetProfileNotesQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProfileNoteOrderByInput>;
  where?: Maybe<ProfileNoteWhereInput>;
  input: ProfileNoteQueryInput;
}>;


export type GetProfileNotesQuery = (
  { __typename?: 'Query' }
  & { getProfileNotes?: Maybe<(
    { __typename?: 'ProfileNoteConnection' }
    & PagedProfileNotesFragment
  )> }
);

export type DeleteProfileNoteMutationVariables = Exact<{
  profileNoteId: Scalars['String'];
}>;


export type DeleteProfileNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfileNote: (
    { __typename?: 'ProfileNoteNotFoundError' }
    & Pick<ProfileNoteNotFoundError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'InvalidProfileNoteError' }
    & Pick<InvalidProfileNoteError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'ProfileNote' }
    & Pick<ProfileNote, 'id'>
  ) }
);

export type CreateProfileNoteMutationVariables = Exact<{
  input: ProfileNoteInput;
}>;


export type CreateProfileNoteMutation = (
  { __typename?: 'Mutation' }
  & { createProfileNote: (
    { __typename?: 'ProfileNote' }
    & ProfileNoteFieldsFragment
  ) | (
    { __typename?: 'ProfileNoteNotFoundError' }
    & Pick<ProfileNoteNotFoundError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'InvalidProfileNoteError' }
    & Pick<InvalidProfileNoteError, 'errorCode' | 'message'>
  ) | (
    { __typename?: 'UnAuthorizedNoteActionError' }
    & Pick<UnAuthorizedNoteActionError, 'errorCode' | 'message'>
  ) }
);

export type ChangeTripStatusMutationVariables = Exact<{
  handoverId: Scalars['String'];
  status: HandoverStatus;
}>;


export type ChangeTripStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeTripStatus: { __typename?: 'InvalidHandoverError' } | (
    { __typename?: 'Handover' }
    & HandoverFieldsFragment
  ) }
);

export type VehicleNoteFieldsFragment = (
  { __typename?: 'VehicleNote' }
  & Pick<VehicleNote, 'id' | 'createdAt' | 'updatedAt' | 'note' | 'isAdmin'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
  )>, files?: Maybe<Array<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url'>
    & { meta?: Maybe<(
      { __typename?: 'FileMetaType' }
      & Pick<FileMetaType, 'filename'>
    )> }
  )>> }
);

export type ReservationNoteFieldsFragment = (
  { __typename?: 'ReservationNote' }
  & Pick<ReservationNote, 'id' | 'createdAt' | 'updatedAt' | 'note' | 'isAdmin' | 'isDriver' | 'isOwner'>
  & { files?: Maybe<Array<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url'>
    & { meta?: Maybe<(
      { __typename?: 'FileMetaType' }
      & Pick<FileMetaType, 'filename'>
    )> }
  )>>, profile?: Maybe<(
    { __typename?: 'NoteProfile' }
    & Pick<NoteProfile, 'userId' | 'fullName' | 'avatar'>
  )> }
);

export type ProfileNoteFieldsFragment = (
  { __typename?: 'ProfileNote' }
  & Pick<ProfileNote, 'id' | 'createdAt' | 'updatedAt' | 'note' | 'isAdmin' | 'canReply'>
  & { files?: Maybe<Array<(
    { __typename?: 'FileEntity' }
    & Pick<FileEntity, 'id' | 'userId' | 'url'>
    & { meta?: Maybe<(
      { __typename?: 'FileMetaType' }
      & Pick<FileMetaType, 'filename'>
    )> }
  )>>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'fullName' | 'avatar'>
    )> }
  ), profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'fullName' | 'avatar'>
  ) }
);

export type GetHandoverImagesByTypeQueryVariables = Exact<{
  id: Scalars['String'];
  type: HandoverFilesEnum;
}>;


export type GetHandoverImagesByTypeQuery = (
  { __typename?: 'Query' }
  & { handoverImages: Array<(
    { __typename?: 'HandoverImage' }
    & Pick<HandoverImage, 'id' | 'src' | 'isOwner'>
  )> }
);

export type GetHandoverCommmentsQueryVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type GetHandoverCommmentsQuery = (
  { __typename?: 'Query' }
  & { getReservation: (
    { __typename?: 'Reservation' }
    & { handover?: Maybe<(
      { __typename?: 'Handover' }
      & Pick<Handover, 'id' | 'status'>
      & { notes?: Maybe<(
        { __typename?: 'HandoverNoteConnection' }
        & { edges: Array<(
          { __typename?: 'HandoverNoteEdge' }
          & { node: (
            { __typename?: 'HandoverNoteWithUser' }
            & Pick<HandoverNoteWithUser, 'id' | 'createdAt' | 'updatedAt' | 'comment' | 'url'>
            & { profile?: Maybe<(
              { __typename?: 'HandoverNotesUser' }
              & Pick<HandoverNotesUser, 'isSelf' | 'name' | 'avatar'>
            )> }
          ) }
        )> }
      )> }
    )> }
  ) | { __typename?: 'InvalidReservationError' } | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type UpdateReservationDatesMutationVariables = Exact<{
  reservationId: Scalars['String'];
  input: UpdateReservationDatesInput;
}>;


export type UpdateReservationDatesMutation = (
  { __typename?: 'Mutation' }
  & { updateReservationDates: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'startDate' | 'endDate'>
    & { dates: (
      { __typename?: 'ReservationDatesDto' }
      & Pick<ReservationDatesDto, 'namedMonthStartDate' | 'namedMonthEndDate' | 'isoEndDate' | 'isoStartDate' | 'startDate' | 'endDate' | 'startFullDate' | 'endFullDate' | 'formatEndDate' | 'formatStartDate'>
    ) }
  ) | (
    { __typename?: 'InvalidReservationError' }
    & Pick<InvalidReservationError, 'errorCode' | 'message'>
  ) | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type UpdatePaymentDateMutationVariables = Exact<{
  reservationId: Scalars['String'];
  input: UpdatePaymentDateInput;
}>;


export type UpdatePaymentDateMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentDate: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'paymentDate'>
  ) | (
    { __typename?: 'InvalidReservationError' }
    & Pick<InvalidReservationError, 'errorCode' | 'message'>
  ) | { __typename?: 'VehicleUnAvailableError' } | { __typename?: 'ReservationDoesNotExistError' } | { __typename?: 'InvalidActionError' } | { __typename?: 'ReservationExpiredError' } | { __typename?: 'InvalidReservationDatesError' } | { __typename?: 'VehicleIsBookedForThisPeriodError' } | { __typename?: 'InvalidDurationCriteriaError' } | { __typename?: 'ReservationAlreadyProcessedError' } | { __typename?: 'UnAuthorizedAccessError' } | { __typename?: 'InvalidVehicleMethodError' } | { __typename?: 'PickUpAndDropOffRequiredError' } | { __typename?: 'BookingNotAllowedError' } | { __typename?: 'CancelationTimeHasAlreadyExpiredError' } | { __typename?: 'CouponCodeInvalidError' } }
);

export type GetOwnersReviewsQueryVariables = Exact<{
  userId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetOwnersReviewsQuery = (
  { __typename?: 'Query' }
  & { getOwnersReviews: (
    { __typename?: 'ReviewConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), aggregate: (
      { __typename?: 'AggregateReview' }
      & Pick<AggregateReview, 'count'>
    ), edges: Array<(
      { __typename?: 'ReviewEdge' }
      & { node: (
        { __typename?: 'VehicleReviewWithUser' }
        & Pick<VehicleReviewWithUser, 'id' | 'reservationId' | 'createdAt' | 'updatedAt' | 'comment' | 'note' | 'rating'>
        & { profile?: Maybe<(
          { __typename?: 'ReviewUser' }
          & Pick<ReviewUser, 'name' | 'avatar'>
        )> }
      ) }
    )> }
  ) }
);

export type GetReviewsToUserQueryVariables = Exact<{
  userId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetReviewsToUserQuery = (
  { __typename?: 'Query' }
  & { getReviewsToUser: (
    { __typename?: 'ReviewConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), aggregate: (
      { __typename?: 'AggregateReview' }
      & Pick<AggregateReview, 'count'>
    ), edges: Array<(
      { __typename?: 'ReviewEdge' }
      & { node: (
        { __typename?: 'VehicleReviewWithUser' }
        & Pick<VehicleReviewWithUser, 'id' | 'reservationId' | 'createdAt' | 'updatedAt' | 'comment' | 'note' | 'rating'>
        & { profile?: Maybe<(
          { __typename?: 'ReviewUser' }
          & Pick<ReviewUser, 'name' | 'avatar'>
        )> }
      ) }
    )> }
  ) }
);

export type DeleteReviewMutationVariables = Exact<{
  id: Scalars['String'];
  reservationId: Scalars['String'];
}>;


export type DeleteReviewMutation = (
  { __typename?: 'Mutation' }
  & { deleteReview: (
    { __typename?: 'Review' }
    & Pick<Review, 'id'>
  ) }
);

export type CreateDisputeMutationVariables = Exact<{
  input: CreateDisputeInput;
  handoverId: Scalars['String'];
}>;


export type CreateDisputeMutation = (
  { __typename?: 'Mutation' }
  & { createDispute: (
    { __typename?: 'TicketResult' }
    & Pick<TicketResult, 'id' | 'url'>
  ) }
);

export type AccountingCodeFieldsFragment = (
  { __typename?: 'AccountingCode' }
  & Pick<AccountingCode, 'id' | 'code' | 'name' | 'description' | 'hasCommission' | 'isContra' | 'enabled' | 'isAdmin'>
);

export type GetAccountingCodesQueryVariables = Exact<{
  input?: Maybe<AccountingCodeFilterInput>;
}>;


export type GetAccountingCodesQuery = (
  { __typename?: 'Query' }
  & { getAccountingCodes?: Maybe<Array<(
    { __typename?: 'AccountingCode' }
    & AccountingCodeFieldsFragment
  )>> }
);

export type CreateAccountingCodeMutationVariables = Exact<{
  input: CreateAccountingCodeInput;
}>;


export type CreateAccountingCodeMutation = (
  { __typename?: 'Mutation' }
  & { createAccountingCode: (
    { __typename?: 'AccountingCode' }
    & AccountingCodeFieldsFragment
  ) }
);

export type DeleteAccountingCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAccountingCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccountingCode'>
);

export type UpdateAccountingCodeMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateAccountingCodeInput;
}>;


export type UpdateAccountingCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountingCode: (
    { __typename?: 'AccountingCode' }
    & AccountingCodeFieldsFragment
  ) }
);

export type OrderLineItemFieldsFragment = (
  { __typename?: 'OrderLineItem' }
  & Pick<OrderLineItem, 'id' | 'accountingCodeId' | 'isSystem' | 'price' | 'quantity' | 'description'>
  & { accountingCode: (
    { __typename?: 'AccountingCode' }
    & Pick<AccountingCode, 'id' | 'code' | 'name' | 'hasCommission'>
  ), reservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
  ) }
);

export type GetReservationOrderLineItemsQueryVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type GetReservationOrderLineItemsQuery = (
  { __typename?: 'Query' }
  & { getReservationLineItems: Array<(
    { __typename?: 'OrderLineItem' }
    & OrderLineItemFieldsFragment
  )> }
);

export type GetUserCardsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserCardsQuery = (
  { __typename?: 'Query' }
  & { getUserCards: Array<(
    { __typename?: 'CardToken' }
    & CardTokenFieldsFragment
  )> }
);

export type AddOrderLineItemMutationVariables = Exact<{
  reservationId: Scalars['String'];
  input: AddReservationLineItemInput;
}>;


export type AddOrderLineItemMutation = (
  { __typename?: 'Mutation' }
  & { addOrderLineItem: Array<(
    { __typename?: 'OrderLineItem' }
    & OrderLineItemFieldsFragment
  )> }
);

export type UpdateOrderLineItemMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateReservationLineItemInput;
}>;


export type UpdateOrderLineItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderLineItem: (
    { __typename?: 'OrderLineItem' }
    & OrderLineItemFieldsFragment
  ) }
);

export type DeleteOrderLineItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOrderLineItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrderLineItem'>
);

export type RefundByReservationIdMutationVariables = Exact<{
  id: Scalars['String'];
  transactionId: Scalars['String'];
  amount: Scalars['Float'];
}>;


export type RefundByReservationIdMutation = (
  { __typename?: 'Mutation' }
  & { refundByReservationId: (
    { __typename?: 'Transaction' }
    & TransactionFieldsFragment
  ) }
);

export type ChargeCardByReservationIdMutationVariables = Exact<{
  amount: Scalars['Float'];
  cardId: Scalars['String'];
  id: Scalars['String'];
}>;


export type ChargeCardByReservationIdMutation = (
  { __typename?: 'Mutation' }
  & { chargeCardByReservationId: (
    { __typename?: 'Transaction' }
    & TransactionFieldsFragment
  ) }
);

export type PlacesFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'name' | 'address' | 'type'>
);

export type FindPlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindPlacesQuery = (
  { __typename?: 'Query' }
  & { findPlaces: Array<(
    { __typename?: 'City' }
    & PlacesFragment
  )> }
);

export type FindPlaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindPlaceQuery = (
  { __typename?: 'Query' }
  & { findPlace: (
    { __typename?: 'City' }
    & PlacesFragment
  ) }
);

export type CreatePlaceMutationVariables = Exact<{
  input: CreatePlaceInput;
}>;


export type CreatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { createPlace: (
    { __typename?: 'City' }
    & PlacesFragment
  ) }
);

export type UpdatePlaceMutationVariables = Exact<{
  input: UpdatePlaceInput;
  id: Scalars['String'];
}>;


export type UpdatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlace: (
    { __typename?: 'City' }
    & PlacesFragment
  ) }
);

export type DeletePlaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlace'>
);

export type GetVehiclePlacesQueryVariables = Exact<{
  vehicleId: Scalars['String'];
}>;


export type GetVehiclePlacesQuery = (
  { __typename?: 'Query' }
  & { vehiclePlaces: Array<(
    { __typename?: 'VehiclePlace' }
    & VehiclePlaceFieldsFragment
  )> }
);

export type AddVehiclePlaceMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  input: CreateVehiclePlaceInput;
}>;


export type AddVehiclePlaceMutation = (
  { __typename?: 'Mutation' }
  & { createVehiclePlace: (
    { __typename?: 'VehiclePlace' }
    & VehiclePlaceFieldsFragment
  ) }
);

export type UpdateVehiclePlaceMutationVariables = Exact<{
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  input: UpdateVehiclePlaceInput;
}>;


export type UpdateVehiclePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updateVehiclePlace: (
    { __typename?: 'VehiclePlace' }
    & VehiclePlaceFieldsFragment
  ) }
);

export type RemoveVehiclePlaceMutationVariables = Exact<{
  id: Scalars['String'];
  vehicleId: Scalars['String'];
}>;


export type RemoveVehiclePlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeVehiclePlace'>
);

export type CreateVehiclePlaceByPlaceMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  placeId: Scalars['String'];
}>;


export type CreateVehiclePlaceByPlaceMutation = (
  { __typename?: 'Mutation' }
  & { createVehiclePlaceByPlaceId: (
    { __typename?: 'VehiclePlace' }
    & VehiclePlaceFieldsFragment
  ) }
);

export type SendPaymentLinkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendPaymentLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendPaymentLink: (
    { __typename?: 'StatusResponse' }
    & Pick<StatusResponse, 'success' | 'message'>
  ) }
);

export type AddReservationTransactionMutationVariables = Exact<{
  input: AddReservationTransactionInput;
  id: Scalars['String'];
}>;


export type AddReservationTransactionMutation = (
  { __typename?: 'Mutation' }
  & { addReservationTransaction: (
    { __typename?: 'StatusResponse' }
    & Pick<StatusResponse, 'success' | 'message'>
  ) }
);

export type ReferFriendsMutationVariables = Exact<{
  input: ReferFriendsInput;
}>;


export type ReferFriendsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'referFriends'>
);

export type CouponFieldsFragment = (
  { __typename?: 'Coupon' }
  & Pick<Coupon, 'id' | 'name' | 'startsAt' | 'endsAt' | 'couponCode' | 'createdAt' | 'perCustomerUsageLimit' | 'enabled' | 'priorityScore'>
  & { actions?: Maybe<Array<(
    { __typename?: 'ConfigurableOperation' }
    & Pick<ConfigurableOperation, 'code'>
    & { args: Array<(
      { __typename?: 'ConfigArgs' }
      & Pick<ConfigArgs, 'name' | 'value'>
    )> }
  )>>, conditions?: Maybe<Array<(
    { __typename?: 'ConfigurableOperation' }
    & Pick<ConfigurableOperation, 'code'>
    & { args: Array<(
      { __typename?: 'ConfigArgs' }
      & Pick<ConfigArgs, 'name' | 'value'>
    )> }
  )>> }
);

export type GetCouponQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCouponQuery = (
  { __typename?: 'Query' }
  & { coupon: (
    { __typename?: 'Coupon' }
    & CouponFieldsFragment
  ) }
);

export type GetAllCouponQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCouponQuery = (
  { __typename?: 'Query' }
  & { coupons: Array<(
    { __typename?: 'Coupon' }
    & CouponFieldsFragment
  )> }
);

export type CouponConditionsAndActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponConditionsAndActionsQuery = (
  { __typename?: 'Query' }
  & { couponConditions: Array<(
    { __typename?: 'ConfigurableOperationDefinitionGQL' }
    & Pick<ConfigurableOperationDefinitionGql, 'code' | 'description'>
    & { args: Array<(
      { __typename?: 'ConfigArgDefinitionGQL' }
      & Pick<ConfigArgDefinitionGql, 'name' | 'type' | 'required' | 'description'>
    )> }
  )>, couponActions: Array<(
    { __typename?: 'ConfigurableOperationDefinitionGQL' }
    & Pick<ConfigurableOperationDefinitionGql, 'code' | 'description'>
    & { args: Array<(
      { __typename?: 'ConfigArgDefinitionGQL' }
      & Pick<ConfigArgDefinitionGql, 'name' | 'type' | 'required' | 'description'>
    )> }
  )> }
);

export type CreateCouponMutationVariables = Exact<{
  input: CreateCouponInput;
}>;


export type CreateCouponMutation = (
  { __typename?: 'Mutation' }
  & { createCoupon: (
    { __typename?: 'Coupon' }
    & CouponFieldsFragment
  ) }
);

export type UpdateCouponMutationVariables = Exact<{
  input: UpdateCouponInput;
  id: Scalars['String'];
}>;


export type UpdateCouponMutation = (
  { __typename?: 'Mutation' }
  & { updateCoupon: (
    { __typename?: 'Coupon' }
    & CouponFieldsFragment
  ) }
);

export type RemoveCouponMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveCouponMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCoupon'>
);

export type ApplyCouponCodeMutationVariables = Exact<{
  id: Scalars['String'];
  couponCode: Scalars['String'];
}>;


export type ApplyCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { applyCouponCode: (
    { __typename: 'Reservation' }
    & Pick<Reservation, 'id'>
  ) | { __typename: 'InvalidReservationError' } | { __typename: 'VehicleUnAvailableError' } | { __typename: 'ReservationDoesNotExistError' } | { __typename: 'InvalidActionError' } | { __typename: 'ReservationExpiredError' } | { __typename: 'InvalidReservationDatesError' } | { __typename: 'VehicleIsBookedForThisPeriodError' } | { __typename: 'InvalidDurationCriteriaError' } | { __typename: 'ReservationAlreadyProcessedError' } | { __typename: 'UnAuthorizedAccessError' } | { __typename: 'InvalidVehicleMethodError' } | { __typename: 'PickUpAndDropOffRequiredError' } | { __typename: 'BookingNotAllowedError' } | { __typename: 'CancelationTimeHasAlreadyExpiredError' } | (
    { __typename: 'CouponCodeInvalidError' }
    & Pick<CouponCodeInvalidError, 'errorCode' | 'message'>
  ) }
);

export type RemoveCouponCodeMutationVariables = Exact<{
  id: Scalars['String'];
  couponCode: Scalars['String'];
}>;


export type RemoveCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { removeCouponCode: (
    { __typename: 'Reservation' }
    & Pick<Reservation, 'id'>
  ) | { __typename: 'InvalidReservationError' } | { __typename: 'VehicleUnAvailableError' } | { __typename: 'ReservationDoesNotExistError' } | { __typename: 'InvalidActionError' } | { __typename: 'ReservationExpiredError' } | { __typename: 'InvalidReservationDatesError' } | { __typename: 'VehicleIsBookedForThisPeriodError' } | { __typename: 'InvalidDurationCriteriaError' } | { __typename: 'ReservationAlreadyProcessedError' } | { __typename: 'UnAuthorizedAccessError' } | { __typename: 'InvalidVehicleMethodError' } | { __typename: 'PickUpAndDropOffRequiredError' } | { __typename: 'BookingNotAllowedError' } | { __typename: 'CancelationTimeHasAlreadyExpiredError' } | { __typename: 'CouponCodeInvalidError' } }
);

export type GetPaymentMethodsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { getPaymentMethods: Array<(
    { __typename?: 'PaymentMethodOutput' }
    & Pick<PaymentMethodOutput, 'name' | 'description' | 'code' | 'channels'>
    & { amount: (
      { __typename?: 'Amount' }
      & Pick<Amount, 'formatted' | 'amountFloat'>
    ) }
  )> }
);

export type UpdateReservationStatusMutationVariables = Exact<{
  id: Scalars['String'];
  input: ReservationStatusUpdateInput;
}>;


export type UpdateReservationStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateReservationStatus: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'status'>
  ) }
);

export type ChangeReservationVehicleMutationVariables = Exact<{
  id: Scalars['String'];
  input: ReservationVehicleChangeInput;
}>;


export type ChangeReservationVehicleMutation = (
  { __typename?: 'Mutation' }
  & { changeReservationVehicle: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
    & { vehicle: (
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id'>
    ) }
  ) }
);

export type SetReservationMethodMutationVariables = Exact<{
  id: Scalars['String'];
  code: Scalars['String'];
}>;


export type SetReservationMethodMutation = (
  { __typename?: 'Mutation' }
  & { setPaymentMethod: (
    { __typename: 'Reservation' }
    & ReservationFieldsFragment
  ) | { __typename: 'InvalidReservationError' } | { __typename: 'VehicleUnAvailableError' } | { __typename: 'ReservationDoesNotExistError' } | { __typename: 'InvalidActionError' } | { __typename: 'ReservationExpiredError' } | { __typename: 'InvalidReservationDatesError' } | { __typename: 'VehicleIsBookedForThisPeriodError' } | { __typename: 'InvalidDurationCriteriaError' } | { __typename: 'ReservationAlreadyProcessedError' } | { __typename: 'UnAuthorizedAccessError' } | { __typename: 'InvalidVehicleMethodError' } | { __typename: 'PickUpAndDropOffRequiredError' } | { __typename: 'BookingNotAllowedError' } | { __typename: 'CancelationTimeHasAlreadyExpiredError' } | { __typename: 'CouponCodeInvalidError' } }
);

export type ExportVehiclesMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportVehiclesMutation = (
  { __typename?: 'Mutation' }
  & { exportVehicles?: Maybe<(
    { __typename?: 'ExportVehicleResult' }
    & Pick<ExportVehicleResult, 'message'>
  )> }
);

export type ResubmitReviewMutationVariables = Exact<{ [key: string]: never; }>;


export type ResubmitReviewMutation = (
  { __typename?: 'Mutation' }
  & { resubmitReview: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type CreditCheckMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CreditCheckMutation = (
  { __typename?: 'Mutation' }
  & { creditCheck: (
    { __typename?: 'UserResult' }
    & Pick<UserResult, 'error'>
  ) }
);

export type VerifyFacialIdentityMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type VerifyFacialIdentityMutation = (
  { __typename?: 'Mutation' }
  & { verifyFacialIdentity: (
    { __typename?: 'UserResult' }
    & Pick<UserResult, 'error'>
  ) }
);

export const CardTokenFieldsFragmentDoc = gql`
    fragment CardTokenFields on CardToken {
  id
  createdAt
  updatedAt
  gateway
  cardType
  lastFourDigits
  expiryMonth
  expiryYear
  expiryDate
  default
}
    `;
export const ProfileFieldsFragmentDoc = gql`
    fragment ProfileFields on Profile {
  id
  createdAt
  firstName
  lastName
  fullName
  idnumber
  phoneNumber
  countryCode
  country
  isSAcitizen
  consentAt
  whatsapp
  whatsAppOtp
  altPhoneNumber
  altCountryCode
  gender
  phoneVerifiedAt
  idnumberVerifiedAt
  idnumber
  passportNumber
  isApprovedOwner
  isApprovedDriver
  avatar
  commission
  dateOfBirth
  joined
  joinDate
  driversLicenseFirstIssued
  commission
  verifications {
    country
    facialMatch
    idVerified
    creditScore
    creditRisk
    emailVerified
    cellphoneVerified
    idPhoto
    idName
    idSurname
    martialStatus
    deceasedStatus
    facialMatchScore
    passport
    drivers
    emailVerified
    cellphoneVerified
  }
  additionalInfo
  addresses {
    id
    addressLine1
    addressLine2
    suburb
    city
    latitude
    longitude
    postalCode
    province
    country
    addressType
  }
  bio
  referralCode
}
    `;
export const UserDocumentFieldsFragmentDoc = gql`
    fragment UserDocumentFields on UserDocument {
  id
  userId
  fileId
  type
  verified
  status
  file {
    id
    userId
    url
    preview: url
    key
    meta {
      ext
    }
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  createdAt
  updatedAt
  cards {
    ...CardTokenFields
  }
  profile {
    ...ProfileFields
  }
  documents {
    ...UserDocumentFields
  }
  email
  emailVerifiedAt
  termsAcceptedAt
  role
  status
  fullName
  isVerified
  isBlocked
  blockReason
}
    ${CardTokenFieldsFragmentDoc}
${ProfileFieldsFragmentDoc}
${UserDocumentFieldsFragmentDoc}`;
export const HandoverNoteFieldsFragmentDoc = gql`
    fragment HandoverNoteFields on HandoverNote {
  id
  createdAt
  type
  comment
  user {
    ...UserFields
  }
  isUser
}
    ${UserFieldsFragmentDoc}`;
export const VehicleErrorFragmentDoc = gql`
    fragment VehicleError on VehicleResultUnion {
  ... on VehicleNotFoundError {
    errorCode
    message
  }
  ... on InvalidVehicleCategoryError {
    errorCode
    message
  }
  ... on InvalidVehicleSelectedError {
    errorCode
    message
  }
  ... on VehicleIsNotApprovedError {
    errorCode
    message
  }
  ... on VehicleNotRejectedError {
    errorCode
    message
  }
  ... on VehicleUnAuthorizedError {
    errorCode
    message
  }
}
    `;
export const VehicleImageFieldsFragmentDoc = gql`
    fragment VehicleImageFields on VehicleImage {
  id
  fileId
  view
  file {
    id
    userId
    key
    url
  }
  position
  approved
}
    `;
export const AmountFieldFragmentDoc = gql`
    fragment AmountField on Amount {
  formatted
  formattedNoDecimals
  amount
  currencyCode
  amountFloat
}
    `;
export const SearchVehicleFieldsFragmentDoc = gql`
    fragment SearchVehicleFields on Vehicle {
  id
  defaultName
  name
  images {
    ...VehicleImageFields
  }
  pricePerDay {
    ...AmountField
  }
  stats {
    ratings: rating
    reviews
    trips
  }
  addresses {
    city
    state
  }
  max_kilometers_per_day
  is_deliver_airport
  is_deliver_renter
  lat: mapLat
  lng: mapLng
}
    ${VehicleImageFieldsFragmentDoc}
${AmountFieldFragmentDoc}`;
export const VehicleTableFieldsFragmentDoc = gql`
    fragment VehicleTableFields on Vehicle {
  id
  nodeId
  createdAt
  updatedAt
  licence_no
  name
  enabled
  listingStatus
  approvalStatus
  images {
    ...VehicleImageFields
  }
  user {
    ... on VehicleUser {
      fullName
    }
  }
}
    ${VehicleImageFieldsFragmentDoc}`;
export const VehiclePlaceFieldsFragmentDoc = gql`
    fragment VehiclePlaceFields on VehiclePlace {
  id
  createdAt
  updatedAt
  streetNumber
  addressLine1
  addressLine2
  googlePlaceId
  city
  suburb
  state
  country
  postalCode
  deliveryFee
  lat
  lng
  name
  enabled
  type
  deliveryFeeAmount {
    ...AmountField
  }
}
    ${AmountFieldFragmentDoc}`;
export const AdminUserDetailsFragmentDoc = gql`
    fragment AdminUserDetails on VehicleUser {
  createdAt
  updatedAt
  email
  idnumber
  passportNumber
  phoneNumber
  altPhoneNumber
  country
  isSAcitizen
  role
  isApprovedOwner
  isApprovedDriver
  status
}
    `;
export const VehicleUserFieldsFragmentDoc = gql`
    fragment VehicleUserFields on VehicleUser {
  id
  isBlocked
  firstName
  lastName
  fullName
  joinDate
  avatar
  rating
  ...AdminUserDetails
}
    ${AdminUserDetailsFragmentDoc}`;
export const AllVehicleFieldsFragmentDoc = gql`
    fragment AllVehicleFields on Vehicle {
  ...VehicleTableFields
  description
  vin
  odometer
  fueltype
  tracker
  transmission
  color
  stats {
    reviews
    trips
    rating
  }
  details {
    id
    year
    make
    model
    variant
  }
  assets {
    id
  }
  recommendedPricePerDay {
    price
    min
    max
    price_label
  }
  recommendedPrice {
    price {
      ...AmountField
    }
    min {
      ...AmountField
    }
    max {
      ...AmountField
    }
  }
  addresses {
    ...VehiclePlaceFields
  }
  lat: mapLng
  lng: mapLat
  user {
    ...VehicleUserFields
  }
  details {
    id
    year
    make
    model
    variant
  }
  features {
    id
    name
    icon
  }
  category {
    id
    name
  }
  rejectionReason
  rejectedAt
  price_per_day
  pricePerDay {
    ...AmountField
  }
  is_weekends_only
  is_weekdays_only
  price_in_cents_retail
  retailPrice {
    ...AmountField
  }
  is_deliver_airport
  is_deliver_renter
  delivery_fee_airport
  deliveryFeeAirport {
    ...AmountField
  }
  delivery_fee_renter
  deliveryFeeRenter {
    ...AmountField
  }
  delivery_radius
  daily_kilometers_included
  max_kilometers_per_day
  minimum_days
  maximum_days
  is_drive_on_unpaved_roads
  is_allow_pets
  is_collect
  is_delivery
  is_show_location
  t_c_accepted_at
  documents {
    id
    fileId
    type
    file {
      id
      userId
      key
      url
    }
  }
  detailsList {
    icon
    label
    description
  }
}
    ${VehicleTableFieldsFragmentDoc}
${AmountFieldFragmentDoc}
${VehiclePlaceFieldsFragmentDoc}
${VehicleUserFieldsFragmentDoc}`;
export const UserStatusUpdateResFragmentDoc = gql`
    fragment UserStatusUpdateRes on User {
  id
  status
  profile {
    isApprovedOwner
    isApprovedDriver
  }
  isBlocked
  blockReason
}
    `;
export const DailyAttributesFieldsFragmentDoc = gql`
    fragment DailyAttributesFields on VehicleDailyAttribute {
  id
  date
  status
  privateNote
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment fileFields on FileEntity {
  __typename
  id
  userId
  url
  key
  meta {
    filename
  }
}
    `;
export const LineItemFieldsFragmentDoc = gql`
    fragment LineItemFields on ReservationLineItems {
  title
  description
  icon
  price
  priceEyebrow: eyebrow
  main
}
    `;
export const ReservationUserFieldsFragmentDoc = gql`
    fragment ReservationUserFields on ReservationUser {
  id
  firstName
  lastName
  fullName
  rating
  age
  dob
  avatar
  email
  cellPhone
  joinDate
}
    `;
export const ReservationReviewFieldsFragmentDoc = gql`
    fragment ReservationReviewFields on ReservationReview {
  id
  type
  publicComment
  privateComment
  categories {
    id
    createdAt
    updatedAt
    name
    rating
    comments
  }
  user {
    fullName
    avatar
  }
  overall
  reviewed
}
    `;
export const HandoverFileFieldsFragmentDoc = gql`
    fragment HandoverFileFields on HandoverFile {
  id
  type
  url
  file {
    id
    userId
    url
  }
}
    `;
export const HandoverFieldsFragmentDoc = gql`
    fragment HandoverFields on Handover {
  id
  status
  files {
    ...HandoverFileFields
  }
  checklist {
    name
    required
    label
    value
    responsible
    stage
  }
  status
  startOdometer
  endOdometer
  startFuel
  endFuel
  terms
  acceptIdentity
  startRequirements
  completeRequirements
}
    ${HandoverFileFieldsFragmentDoc}`;
export const ReservationFieldsFragmentDoc = gql`
    fragment ReservationFields on Reservation {
  id
  isOwner
  rejectionNote
  rejectionNotePrivate
  driverNote
  address
  locationId
  verifications {
    label
    type
    value
  }
  pricePerDay {
    ...AmountField
  }
  lineItems {
    ...LineItemFields
  }
  getTotal {
    ...AmountField
  }
  payout {
    ...AmountField
  }
  paymentDate
  scopes
  startDate
  dates {
    namedMonthStartDate
    namedMonthEndDate
    isoEndDate
    isoStartDate
    startDate
    endDate
    startFullDate
    endFullDate
    formatEndDate
    formatStartDate
  }
  endDate
  status
  duration
  isConfirmed
  isBooked
  insurancePerDay {
    ...AmountField
  }
  depositTotal {
    ...AmountField
  }
  deliveryFee
  isDelivery
  isDeliveryAirport
  dailykilometers
  createdAt
  updatedAt
  user {
    ...ReservationUserFields
  }
  vehicleUser {
    ...ReservationUserFields
  }
  vehicle {
    id
    name
    defaultName
    description
    color
    fueltype
    tracker
    transmission
    max_kilometers_per_day
    maximum_days
    minimum_days
    is_allow_pets
    is_collect
    is_deliver_renter
    is_deliver_airport
    addresses {
      ...VehiclePlaceFields
    }
    deliveryFeeRenter {
      ...AmountField
    }
    details {
      year
      make
      model
      variant
    }
    features {
      name
    }
    category {
      name
    }
    images {
      ...VehicleImageFields
    }
    pricePerDay {
      ...AmountField
    }
    user {
      ...VehicleUserFields
    }
    stats {
      ratings: rating
      trips
      reviews
    }
  }
  reviews {
    ...ReservationReviewFields
  }
  handover {
    ...HandoverFields
  }
  orderLineTotals {
    balance {
      ...AmountField
    }
    totalPaid {
      ...AmountField
    }
    depositBalance {
      ...AmountField
    }
    serviceTotal {
      ...AmountField
    }
    payoutTotal {
      ...AmountField
    }
    lineItemTotal {
      ...AmountField
    }
    discountTotal {
      ...AmountField
    }
    pricePerDayTotal {
      ...AmountField
    }
    rentalTotal {
      ...AmountField
    }
    insuranceTotal {
      ...AmountField
    }
    deliveryTotal {
      ...AmountField
    }
    reservationTotal {
      ...AmountField
    }
  }
  payments {
    id
    type
    amount
    method
    createdAt
    transactionDate
    amountFormatted {
      formatted
    }
  }
  couponCodes
}
    ${AmountFieldFragmentDoc}
${LineItemFieldsFragmentDoc}
${ReservationUserFieldsFragmentDoc}
${VehiclePlaceFieldsFragmentDoc}
${VehicleImageFieldsFragmentDoc}
${VehicleUserFieldsFragmentDoc}
${ReservationReviewFieldsFragmentDoc}
${HandoverFieldsFragmentDoc}`;
export const ReservationErrorsFragmentDoc = gql`
    fragment ReservationErrors on ReservationResult {
  ... on InvalidReservationError {
    errorCode
    message
  }
  ... on VehicleUnAvailableError {
    errorCode
    message
  }
  ... on ReservationDoesNotExistError {
    errorCode
    message
  }
  ... on InvalidActionError {
    errorCode
    message
  }
  ... on ReservationExpiredError {
    errorCode
    message
  }
  ... on InvalidReservationDatesError {
    errorCode
    message
  }
  ... on VehicleIsBookedForThisPeriodError {
    errorCode
    message
  }
  ... on InvalidDurationCriteriaError {
    errorCode
    message
  }
  ... on ReservationAlreadyProcessedError {
    errorCode
    message
  }
  ... on UnAuthorizedAccessError {
    errorCode
    message
  }
  ... on InvalidVehicleMethodError {
    errorCode
    message
  }
}
    `;
export const PagedReservationFieldsFragmentDoc = gql`
    fragment PagedReservationFields on Reservation {
  id
  status
  createdAt
  startDate
  endDate
  dates {
    namedMonthStartDate
    namedMonthEndDate
    isoEndDate
    isoStartDate
    startDate
    endDate
    startFullDate
    endFullDate
    formatEndDate
    formatStartDate
  }
  getTotal {
    ...AmountField
  }
  payout {
    ...AmountField
  }
  duration
  user {
    id
    fullName
  }
  vehicle {
    user {
      id
    }
  }
  reviews {
    ...ReservationReviewFields
  }
}
    ${AmountFieldFragmentDoc}
${ReservationReviewFieldsFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const PagedReservationsFragmentDoc = gql`
    fragment PagedReservations on ReservationConnection {
  edges {
    node {
      ...PagedReservationFields
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${PagedReservationFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const PagedReservationsWithReviewsFragmentDoc = gql`
    fragment PagedReservationsWithReviews on ReservationConnection {
  edges {
    node {
      ...PagedReservationFields
      reviews {
        id
        publicComment
        overall
        type
      }
      vehicle {
        id
        name
      }
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${PagedReservationFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const PagedReservationsWithVehicleFragmentDoc = gql`
    fragment PagedReservationsWithVehicle on ReservationConnection {
  edges {
    node {
      ...PagedReservationFields
      vehicle {
        id
        name
        images {
          file {
            url
          }
        }
        user {
          ... on VehicleUser {
            id
          }
        }
      }
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${PagedReservationFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const PagedReservationsWithVehicleNameFragmentDoc = gql`
    fragment PagedReservationsWithVehicleName on ReservationConnection {
  edges {
    node {
      ...PagedReservationFields
      vehicle {
        id
        name
      }
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${PagedReservationFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const DashboardDriverFieldsFragmentDoc = gql`
    fragment DashboardDriverFields on Reservation {
  id
  startDate
  endDate
  dates {
    namedMonthStartDate
    namedMonthEndDate
    isoEndDate
    isoStartDate
    startDate
    endDate
    startFullDate
    endFullDate
    formatEndDate
    formatStartDate
  }
  status
  getTotal {
    formatted
  }
  vehicle {
    id
    name
    user {
      ...VehicleUserFields
    }
    images {
      file {
        id
        userId
        url
      }
      view
    }
  }
  user {
    ... on ReservationUser {
      id
    }
  }
  reviews {
    ...ReservationReviewFields
  }
}
    ${VehicleUserFieldsFragmentDoc}
${ReservationReviewFieldsFragmentDoc}`;
export const DashboardDriverConnectionFragmentDoc = gql`
    fragment DashboardDriverConnection on ReservationConnection {
  edges {
    node {
      ...DashboardDriverFields
    }
  }
}
    ${DashboardDriverFieldsFragmentDoc}`;
export const DashboardOwnerFieldsFragmentDoc = gql`
    fragment DashboardOwnerFields on Reservation {
  id
  startDate
  endDate
  dates {
    namedMonthStartDate
    namedMonthEndDate
    isoEndDate
    isoStartDate
    startDate
    endDate
    startFullDate
    endFullDate
    formatEndDate
    formatStartDate
  }
  status
  payout {
    formatted
  }
  vehicle {
    id
    name
    licence_no
    images {
      file {
        id
        userId
        url
      }
      view
    }
    user {
      ... on VehicleUser {
        id
      }
    }
  }
  user {
    ...ReservationUserFields
  }
}
    ${ReservationUserFieldsFragmentDoc}`;
export const DashboardOwnerConnectionFragmentDoc = gql`
    fragment DashboardOwnerConnection on ReservationConnection {
  edges {
    node {
      ...DashboardOwnerFields
    }
  }
}
    ${DashboardOwnerFieldsFragmentDoc}`;
export const BankAccountFieldsFragmentDoc = gql`
    fragment BankAccountFields on BankAccount {
  id
  bankName
  accountNumber
  accountType
  accountName
  branchCode
}
    `;
export const ProviderAuthResultFragmentDoc = gql`
    fragment ProviderAuthResult on ProviderAuthResultUnion {
  ... on AuthTokens {
    accessToken
  }
  ... on ProviderAlreadyAssignedError {
    message
    errorCode
  }
  ... on ProviderNotRegisteredError {
    message
    errorCode
  }
  ... on CredentialsTakenError {
    message
    errorCode
  }
}
    `;
export const TransactionFieldsFragmentDoc = gql`
    fragment TransactionFields on Transaction {
  id
  createdAt
  method
  amount
  status
  type
  response
  amountFormatted {
    formatted
  }
}
    `;
export const TransactionFieldsWithReservationFragmentDoc = gql`
    fragment TransactionFieldsWithReservation on Transaction {
  id
  createdAt
  method
  amount
  user {
    id
    fullName
    email
    role
    profile {
      phoneNumber
    }
  }
  status
  type
  response
  amountFormatted {
    formatted
  }
  reservation {
    id
    status
    vehicle {
      name
    }
    startDate
    endDate
  }
}
    `;
export const PagedTransactionsFragmentDoc = gql`
    fragment PagedTransactions on TransactionConnection {
  edges {
    node {
      ...TransactionFieldsWithReservation
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${TransactionFieldsWithReservationFragmentDoc}
${PageInfoFragmentDoc}`;
export const ReservationNoteFieldsFragmentDoc = gql`
    fragment ReservationNoteFields on ReservationNote {
  id
  createdAt
  updatedAt
  note
  isAdmin
  isDriver
  isOwner
  files {
    id
    userId
    url
    meta {
      filename
    }
  }
  profile {
    userId
    fullName
    avatar
  }
}
    `;
export const PagedReservationNotesFragmentDoc = gql`
    fragment PagedReservationNotes on ReservationNoteConnection {
  __typename
  edges {
    node {
      ...ReservationNoteFields
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${ReservationNoteFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const ProfileNoteFieldsFragmentDoc = gql`
    fragment ProfileNoteFields on ProfileNote {
  id
  createdAt
  updatedAt
  note
  isAdmin
  canReply
  files {
    id
    userId
    url
    meta {
      filename
    }
  }
  user {
    id
    profile {
      fullName
      avatar
    }
  }
  profile {
    fullName
    avatar
  }
}
    `;
export const PagedProfileNotesFragmentDoc = gql`
    fragment PagedProfileNotes on ProfileNoteConnection {
  __typename
  edges {
    node {
      ...ProfileNoteFields
    }
  }
  pageInfo {
    ...PageInfo
  }
  aggregate {
    count
  }
}
    ${ProfileNoteFieldsFragmentDoc}
${PageInfoFragmentDoc}`;
export const VehicleNoteFieldsFragmentDoc = gql`
    fragment VehicleNoteFields on VehicleNote {
  id
  createdAt
  updatedAt
  note
  isAdmin
  user {
    id
    fullName
  }
  files {
    id
    userId
    url
    meta {
      filename
    }
  }
}
    `;
export const AccountingCodeFieldsFragmentDoc = gql`
    fragment AccountingCodeFields on AccountingCode {
  id
  code
  name
  description
  hasCommission
  isContra
  enabled
  isAdmin
}
    `;
export const OrderLineItemFieldsFragmentDoc = gql`
    fragment OrderLineItemFields on OrderLineItem {
  id
  accountingCodeId
  accountingCode {
    id
    code
    name
    hasCommission
  }
  isSystem
  price
  quantity
  description
  reservation {
    id
  }
}
    `;
export const PlacesFragmentDoc = gql`
    fragment Places on City {
  id
  name
  address
  type
}
    `;
export const CouponFieldsFragmentDoc = gql`
    fragment CouponFields on Coupon {
  id
  name
  startsAt
  endsAt
  couponCode
  createdAt
  perCustomerUsageLimit
  actions {
    code
    args {
      name
      value
    }
  }
  conditions {
    code
    args {
      name
      value
    }
  }
  enabled
  priorityScore
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    __typename
    ... on AuthTokens {
      token: accessToken
      expiry: accessTokenExpiry
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on AccountBlockedError {
      errorCode
      message
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = gql`
    query RefreshToken {
  refreshToken {
    ... on AuthTokens {
      token: accessToken
      expiry: accessTokenExpiry
    }
  }
}
    `;

/**
 * __useRefreshTokenQuery__
 *
 * To run a query within a React component, call `useRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenQuery(baseOptions?: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    __typename
    ... on UserRegisterSuccess {
      user {
        ...UserFields
      }
      token: accessToken
      expiry: accessTokenExpiry
    }
    ... on UserRegisterInvalidInputError {
      message
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    __typename
    ... on UserUpdateSuccess {
      user {
        ...UserFields
      }
    }
    ... on UserRegisterInvalidInputError {
      message
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    ... on ForgotPasswordSuccess {
      success
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const SendOtpDocument = gql`
    mutation SendOtp {
  sendOtp {
    __typename
    ... on BaseOutput {
      success
    }
    ... on OtpSuccessOutput {
      fingerPrint
    }
    ... on OtpFailureOutput {
      error
    }
    ... on OtpNotFoundError {
      message
      errorCode
    }
    ... on TooManyOtpAttemptsError {
      message
      errorCode
    }
    ... on AccountBlockedError {
      message
      errorCode
    }
  }
}
    `;
export type SendOtpMutationFn = Apollo.MutationFunction<SendOtpMutation, SendOtpMutationVariables>;

/**
 * __useSendOtpMutation__
 *
 * To run a mutation, you first call `useSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpMutation, { data, loading, error }] = useSendOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendOtpMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpMutation, SendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpMutation, SendOtpMutationVariables>(SendOtpDocument, options);
      }
export type SendOtpMutationHookResult = ReturnType<typeof useSendOtpMutation>;
export type SendOtpMutationResult = Apollo.MutationResult<SendOtpMutation>;
export type SendOtpMutationOptions = Apollo.BaseMutationOptions<SendOtpMutation, SendOtpMutationVariables>;
export const ResendOtpDocument = gql`
    mutation ResendOtp {
  resendOtp {
    __typename
    ... on BaseOutput {
      success
    }
    ... on OtpSuccessOutput {
      fingerPrint
    }
    ... on OtpFailureOutput {
      error
    }
    ... on OtpNotFoundError {
      message
      errorCode
    }
    ... on TooManyOtpAttemptsError {
      message
      errorCode
    }
    ... on AccountBlockedError {
      message
      errorCode
    }
  }
}
    `;
export type ResendOtpMutationFn = Apollo.MutationFunction<ResendOtpMutation, ResendOtpMutationVariables>;

/**
 * __useResendOtpMutation__
 *
 * To run a mutation, you first call `useResendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOtpMutation, { data, loading, error }] = useResendOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendOtpMutation(baseOptions?: Apollo.MutationHookOptions<ResendOtpMutation, ResendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendOtpMutation, ResendOtpMutationVariables>(ResendOtpDocument, options);
      }
export type ResendOtpMutationHookResult = ReturnType<typeof useResendOtpMutation>;
export type ResendOtpMutationResult = Apollo.MutationResult<ResendOtpMutation>;
export type ResendOtpMutationOptions = Apollo.BaseMutationOptions<ResendOtpMutation, ResendOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($input: VerifyOtpInput!) {
  verifyOtp(input: $input) {
    __typename
    ... on BaseOutput {
      success
    }
    ... on OtpSuccessOutput {
      fingerPrint
    }
    ... on OtpFailureOutput {
      error
    }
    ... on OtpNotFoundError {
      message
      errorCode
    }
    ... on TooManyOtpAttemptsError {
      message
      errorCode
    }
    ... on AccountBlockedError {
      message
      errorCode
    }
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($verify: String!) {
  verifyEmail(verify: $verify) {
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on AccountBlockedError {
      errorCode
      message
    }
    ... on AuthTokens {
      refreshToken
      accessToken
      accessTokenExpiry
      refreshTokenExpiry
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      verify: // value for 'verify'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation ResendEmailVerification($input: EmailVerificationInput!) {
  resendEmailVerification(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, options);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = Apollo.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: ProfileUpdateInput!) {
  updateProfile(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ApproveListingDocument = gql`
    mutation ApproveListing($id: String!) {
  approveListing(id: $id) {
    ... on Vehicle {
      id
      approvalStatus
      listingStatus
    }
    ...VehicleError
  }
}
    ${VehicleErrorFragmentDoc}`;
export type ApproveListingMutationFn = Apollo.MutationFunction<ApproveListingMutation, ApproveListingMutationVariables>;

/**
 * __useApproveListingMutation__
 *
 * To run a mutation, you first call `useApproveListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveListingMutation, { data, loading, error }] = useApproveListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveListingMutation(baseOptions?: Apollo.MutationHookOptions<ApproveListingMutation, ApproveListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveListingMutation, ApproveListingMutationVariables>(ApproveListingDocument, options);
      }
export type ApproveListingMutationHookResult = ReturnType<typeof useApproveListingMutation>;
export type ApproveListingMutationResult = Apollo.MutationResult<ApproveListingMutation>;
export type ApproveListingMutationOptions = Apollo.BaseMutationOptions<ApproveListingMutation, ApproveListingMutationVariables>;
export const RejectListingDocument = gql`
    mutation RejectListing($id: String!, $reason: String) {
  rejectListing(id: $id, reason: $reason) {
    ... on Vehicle {
      id
      approvalStatus
      rejectionReason
      rejectedAt
    }
  }
}
    `;
export type RejectListingMutationFn = Apollo.MutationFunction<RejectListingMutation, RejectListingMutationVariables>;

/**
 * __useRejectListingMutation__
 *
 * To run a mutation, you first call `useRejectListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectListingMutation, { data, loading, error }] = useRejectListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectListingMutation(baseOptions?: Apollo.MutationHookOptions<RejectListingMutation, RejectListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectListingMutation, RejectListingMutationVariables>(RejectListingDocument, options);
      }
export type RejectListingMutationHookResult = ReturnType<typeof useRejectListingMutation>;
export type RejectListingMutationResult = Apollo.MutationResult<RejectListingMutation>;
export type RejectListingMutationOptions = Apollo.BaseMutationOptions<RejectListingMutation, RejectListingMutationVariables>;
export const UpdateVehicleListingStatusDocument = gql`
    mutation UpdateVehicleListingStatus($id: String!, $status: VehicleStatus!) {
  updateVehicleListingStatus(id: $id, status: $status) {
    ... on Vehicle {
      id
      listingStatus
    }
    ...VehicleError
  }
}
    ${VehicleErrorFragmentDoc}`;
export type UpdateVehicleListingStatusMutationFn = Apollo.MutationFunction<UpdateVehicleListingStatusMutation, UpdateVehicleListingStatusMutationVariables>;

/**
 * __useUpdateVehicleListingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleListingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleListingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleListingStatusMutation, { data, loading, error }] = useUpdateVehicleListingStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateVehicleListingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleListingStatusMutation, UpdateVehicleListingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleListingStatusMutation, UpdateVehicleListingStatusMutationVariables>(UpdateVehicleListingStatusDocument, options);
      }
export type UpdateVehicleListingStatusMutationHookResult = ReturnType<typeof useUpdateVehicleListingStatusMutation>;
export type UpdateVehicleListingStatusMutationResult = Apollo.MutationResult<UpdateVehicleListingStatusMutation>;
export type UpdateVehicleListingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleListingStatusMutation, UpdateVehicleListingStatusMutationVariables>;
export const ResubmitListingDocument = gql`
    mutation ResubmitListing($id: String!) {
  resubmitListing(id: $id) {
    id
    approvalStatus
  }
}
    `;
export type ResubmitListingMutationFn = Apollo.MutationFunction<ResubmitListingMutation, ResubmitListingMutationVariables>;

/**
 * __useResubmitListingMutation__
 *
 * To run a mutation, you first call `useResubmitListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitListingMutation, { data, loading, error }] = useResubmitListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResubmitListingMutation(baseOptions?: Apollo.MutationHookOptions<ResubmitListingMutation, ResubmitListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubmitListingMutation, ResubmitListingMutationVariables>(ResubmitListingDocument, options);
      }
export type ResubmitListingMutationHookResult = ReturnType<typeof useResubmitListingMutation>;
export type ResubmitListingMutationResult = Apollo.MutationResult<ResubmitListingMutation>;
export type ResubmitListingMutationOptions = Apollo.BaseMutationOptions<ResubmitListingMutation, ResubmitListingMutationVariables>;
export const EnableVehicleDocument = gql`
    mutation EnableVehicle($id: String!, $enabled: Boolean!) {
  enableVehicle(id: $id, enabled: $enabled) {
    id
    enabled
  }
}
    `;
export type EnableVehicleMutationFn = Apollo.MutationFunction<EnableVehicleMutation, EnableVehicleMutationVariables>;

/**
 * __useEnableVehicleMutation__
 *
 * To run a mutation, you first call `useEnableVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableVehicleMutation, { data, loading, error }] = useEnableVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useEnableVehicleMutation(baseOptions?: Apollo.MutationHookOptions<EnableVehicleMutation, EnableVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableVehicleMutation, EnableVehicleMutationVariables>(EnableVehicleDocument, options);
      }
export type EnableVehicleMutationHookResult = ReturnType<typeof useEnableVehicleMutation>;
export type EnableVehicleMutationResult = Apollo.MutationResult<EnableVehicleMutation>;
export type EnableVehicleMutationOptions = Apollo.BaseMutationOptions<EnableVehicleMutation, EnableVehicleMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($input: UpdateVehicleInput!) {
  updateVehicle(input: $input) {
    ...AllVehicleFields
    ...VehicleError
  }
}
    ${AllVehicleFieldsFragmentDoc}
${VehicleErrorFragmentDoc}`;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    __typename
    ...UserFields
    bankAccount {
      ...BankAccountFields
    }
    vehicles {
      edges {
        node {
          ...VehicleTableFields
        }
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}
${VehicleTableFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeCheckinDocument = gql`
    query MeCheckin {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeCheckinQuery__
 *
 * To run a query within a React component, call `useMeCheckinQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeCheckinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeCheckinQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeCheckinQuery(baseOptions?: Apollo.QueryHookOptions<MeCheckinQuery, MeCheckinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeCheckinQuery, MeCheckinQueryVariables>(MeCheckinDocument, options);
      }
export function useMeCheckinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeCheckinQuery, MeCheckinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeCheckinQuery, MeCheckinQueryVariables>(MeCheckinDocument, options);
        }
export type MeCheckinQueryHookResult = ReturnType<typeof useMeCheckinQuery>;
export type MeCheckinLazyQueryHookResult = ReturnType<typeof useMeCheckinLazyQuery>;
export type MeCheckinQueryResult = Apollo.QueryResult<MeCheckinQuery, MeCheckinQueryVariables>;
export const MyBookingsDocument = gql`
    query MyBookings($before: String, $after: String, $first: Int, $last: Int, $where: ReservationWhereInput, $orderBy: ReservationOrderByInput) {
  me {
    ... on User {
      reservations(
        before: $before
        after: $after
        first: $first
        last: $last
        where: $where
        orderBy: $orderBy
      ) {
        ...PagedReservationsWithVehicle
      }
    }
  }
}
    ${PagedReservationsWithVehicleFragmentDoc}`;

/**
 * __useMyBookingsQuery__
 *
 * To run a query within a React component, call `useMyBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBookingsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMyBookingsQuery(baseOptions?: Apollo.QueryHookOptions<MyBookingsQuery, MyBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBookingsQuery, MyBookingsQueryVariables>(MyBookingsDocument, options);
      }
export function useMyBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBookingsQuery, MyBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBookingsQuery, MyBookingsQueryVariables>(MyBookingsDocument, options);
        }
export type MyBookingsQueryHookResult = ReturnType<typeof useMyBookingsQuery>;
export type MyBookingsLazyQueryHookResult = ReturnType<typeof useMyBookingsLazyQuery>;
export type MyBookingsQueryResult = Apollo.QueryResult<MyBookingsQuery, MyBookingsQueryVariables>;
export const MyVehicleReservationsDocument = gql`
    query MyVehicleReservations($before: String, $after: String, $first: Int, $last: Int, $where: ReservationWhereInput, $orderBy: ReservationOrderByInput, $input: ReservationsQueryInput) {
  me {
    ... on User {
      vehicleReservations(
        before: $before
        after: $after
        first: $first
        last: $last
        where: $where
        orderBy: $orderBy
        input: $input
      ) {
        ...PagedReservationsWithVehicle
      }
    }
  }
}
    ${PagedReservationsWithVehicleFragmentDoc}`;

/**
 * __useMyVehicleReservationsQuery__
 *
 * To run a query within a React component, call `useMyVehicleReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyVehicleReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyVehicleReservationsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyVehicleReservationsQuery(baseOptions?: Apollo.QueryHookOptions<MyVehicleReservationsQuery, MyVehicleReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyVehicleReservationsQuery, MyVehicleReservationsQueryVariables>(MyVehicleReservationsDocument, options);
      }
export function useMyVehicleReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyVehicleReservationsQuery, MyVehicleReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyVehicleReservationsQuery, MyVehicleReservationsQueryVariables>(MyVehicleReservationsDocument, options);
        }
export type MyVehicleReservationsQueryHookResult = ReturnType<typeof useMyVehicleReservationsQuery>;
export type MyVehicleReservationsLazyQueryHookResult = ReturnType<typeof useMyVehicleReservationsLazyQuery>;
export type MyVehicleReservationsQueryResult = Apollo.QueryResult<MyVehicleReservationsQuery, MyVehicleReservationsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($skip: Int, $take: Int, $order: Order, $sortBy: String, $where: UserWhereInput) {
  getUsers(
    skip: $skip
    take: $take
    order: $order
    sortBy: $sortBy
    where: $where
  ) {
    users {
      ...UserFields
      bankAccount {
        ...BankAccountFields
      }
    }
    totalCount
  }
}
    ${UserFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      sortBy: // value for 'sortBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: String!) {
  getUser(id: $id) {
    ...UserFields
    bankAccount {
      ...BankAccountFields
    }
    profile {
      ...ProfileFields
    }
    vehicles {
      edges {
        node {
          ...VehicleTableFields
        }
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}
${ProfileFieldsFragmentDoc}
${VehicleTableFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserReservationsDocument = gql`
    query GetUserReservations($userId: String!, $before: String, $after: String, $first: Int, $last: Int, $where: ReservationWhereInput, $orderBy: ReservationOrderByInput) {
  getUser(id: $userId) {
    reservations(
      before: $before
      after: $after
      first: $first
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      ...PagedReservationsWithVehicle
    }
  }
}
    ${PagedReservationsWithVehicleFragmentDoc}`;

/**
 * __useGetUserReservationsQuery__
 *
 * To run a query within a React component, call `useGetUserReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReservationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserReservationsQuery, GetUserReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserReservationsQuery, GetUserReservationsQueryVariables>(GetUserReservationsDocument, options);
      }
export function useGetUserReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserReservationsQuery, GetUserReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserReservationsQuery, GetUserReservationsQueryVariables>(GetUserReservationsDocument, options);
        }
export type GetUserReservationsQueryHookResult = ReturnType<typeof useGetUserReservationsQuery>;
export type GetUserReservationsLazyQueryHookResult = ReturnType<typeof useGetUserReservationsLazyQuery>;
export type GetUserReservationsQueryResult = Apollo.QueryResult<GetUserReservationsQuery, GetUserReservationsQueryVariables>;
export const GetAllVehiclesDocument = gql`
    query GetAllVehicles($before: String, $after: String, $first: Int, $last: Int, $where: VehicleWhereInput, $orderBy: VehicleOrderByInput) {
  allVehicles(
    before: $before
    after: $after
    first: $first
    last: $last
    where: $where
    orderBy: $orderBy
  ) {
    pageInfo {
      ...PageInfo
    }
    aggregate {
      count
      totalPages
      currentPage
    }
    edges {
      node {
        ...AllVehicleFields
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AllVehicleFieldsFragmentDoc}`;

/**
 * __useGetAllVehiclesQuery__
 *
 * To run a query within a React component, call `useGetAllVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVehiclesQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>(GetAllVehiclesDocument, options);
      }
export function useGetAllVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>(GetAllVehiclesDocument, options);
        }
export type GetAllVehiclesQueryHookResult = ReturnType<typeof useGetAllVehiclesQuery>;
export type GetAllVehiclesLazyQueryHookResult = ReturnType<typeof useGetAllVehiclesLazyQuery>;
export type GetAllVehiclesQueryResult = Apollo.QueryResult<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>;
export const GetVehiclesDocument = gql`
    query GetVehicles($input: SearchVehicleInput!, $first: Int, $after: String, $last: Int, $before: String) {
  searchVehicles(
    input: $input
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    aggregate {
      count
      totalPages
      currentPage
    }
    pageInfo {
      ...PageInfo
    }
    aggregate {
      count
      totalPages
      currentPage
    }
    edges {
      node {
        ...SearchVehicleFields
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${SearchVehicleFieldsFragmentDoc}`;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
      }
export function useGetVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
        }
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<typeof useGetVehiclesLazyQuery>;
export type GetVehiclesQueryResult = Apollo.QueryResult<GetVehiclesQuery, GetVehiclesQueryVariables>;
export const GetVehicleReviewsDocument = gql`
    query GetVehicleReviews($vehicleId: String!, $first: Int, $after: String, $last: Int, $before: String) {
  getVehicleReviews(
    vehicleId: $vehicleId
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    pageInfo {
      ...PageInfo
    }
    aggregate {
      count
    }
    edges {
      node {
        id
        createdAt
        updatedAt
        comment
        note
        rating
        profile {
          name
          avatar
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useGetVehicleReviewsQuery__
 *
 * To run a query within a React component, call `useGetVehicleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleReviewsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetVehicleReviewsQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleReviewsQuery, GetVehicleReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleReviewsQuery, GetVehicleReviewsQueryVariables>(GetVehicleReviewsDocument, options);
      }
export function useGetVehicleReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleReviewsQuery, GetVehicleReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleReviewsQuery, GetVehicleReviewsQueryVariables>(GetVehicleReviewsDocument, options);
        }
export type GetVehicleReviewsQueryHookResult = ReturnType<typeof useGetVehicleReviewsQuery>;
export type GetVehicleReviewsLazyQueryHookResult = ReturnType<typeof useGetVehicleReviewsLazyQuery>;
export type GetVehicleReviewsQueryResult = Apollo.QueryResult<GetVehicleReviewsQuery, GetVehicleReviewsQueryVariables>;
export const GetVehicleDocument = gql`
    query GetVehicle($id: String!) {
  vehicle(id: $id) {
    ...AllVehicleFields
  }
}
    ${AllVehicleFieldsFragmentDoc}`;

/**
 * __useGetVehicleQuery__
 *
 * To run a query within a React component, call `useGetVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
      }
export function useGetVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
        }
export type GetVehicleQueryHookResult = ReturnType<typeof useGetVehicleQuery>;
export type GetVehicleLazyQueryHookResult = ReturnType<typeof useGetVehicleLazyQuery>;
export type GetVehicleQueryResult = Apollo.QueryResult<GetVehicleQuery, GetVehicleQueryVariables>;
export const GetUserVehicleDocument = gql`
    query GetUserVehicle($id: String!) {
  getUserVehicle(id: $id) {
    ...AllVehicleFields
  }
}
    ${AllVehicleFieldsFragmentDoc}`;

/**
 * __useGetUserVehicleQuery__
 *
 * To run a query within a React component, call `useGetUserVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetUserVehicleQuery, GetUserVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserVehicleQuery, GetUserVehicleQueryVariables>(GetUserVehicleDocument, options);
      }
export function useGetUserVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserVehicleQuery, GetUserVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserVehicleQuery, GetUserVehicleQueryVariables>(GetUserVehicleDocument, options);
        }
export type GetUserVehicleQueryHookResult = ReturnType<typeof useGetUserVehicleQuery>;
export type GetUserVehicleLazyQueryHookResult = ReturnType<typeof useGetUserVehicleLazyQuery>;
export type GetUserVehicleQueryResult = Apollo.QueryResult<GetUserVehicleQuery, GetUserVehicleQueryVariables>;
export const GetViewerVehiclesDocument = gql`
    query GetViewerVehicles {
  myVehicles {
    ...VehicleTableFields
  }
}
    ${VehicleTableFieldsFragmentDoc}`;

/**
 * __useGetViewerVehiclesQuery__
 *
 * To run a query within a React component, call `useGetViewerVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GetViewerVehiclesQuery, GetViewerVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerVehiclesQuery, GetViewerVehiclesQueryVariables>(GetViewerVehiclesDocument, options);
      }
export function useGetViewerVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerVehiclesQuery, GetViewerVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerVehiclesQuery, GetViewerVehiclesQueryVariables>(GetViewerVehiclesDocument, options);
        }
export type GetViewerVehiclesQueryHookResult = ReturnType<typeof useGetViewerVehiclesQuery>;
export type GetViewerVehiclesLazyQueryHookResult = ReturnType<typeof useGetViewerVehiclesLazyQuery>;
export type GetViewerVehiclesQueryResult = Apollo.QueryResult<GetViewerVehiclesQuery, GetViewerVehiclesQueryVariables>;
export const GetVehicleYearsDocument = gql`
    query GetVehicleYears {
  vehicleYears {
    year
  }
}
    `;

/**
 * __useGetVehicleYearsQuery__
 *
 * To run a query within a React component, call `useGetVehicleYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleYearsQuery(baseOptions?: Apollo.QueryHookOptions<GetVehicleYearsQuery, GetVehicleYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleYearsQuery, GetVehicleYearsQueryVariables>(GetVehicleYearsDocument, options);
      }
export function useGetVehicleYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleYearsQuery, GetVehicleYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleYearsQuery, GetVehicleYearsQueryVariables>(GetVehicleYearsDocument, options);
        }
export type GetVehicleYearsQueryHookResult = ReturnType<typeof useGetVehicleYearsQuery>;
export type GetVehicleYearsLazyQueryHookResult = ReturnType<typeof useGetVehicleYearsLazyQuery>;
export type GetVehicleYearsQueryResult = Apollo.QueryResult<GetVehicleYearsQuery, GetVehicleYearsQueryVariables>;
export const GetVehicleMakesDocument = gql`
    query GetVehicleMakes($year: String!) {
  vehicleMakes(year: $year) {
    make
  }
}
    `;

/**
 * __useGetVehicleMakesQuery__
 *
 * To run a query within a React component, call `useGetVehicleMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleMakesQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetVehicleMakesQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleMakesQuery, GetVehicleMakesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleMakesQuery, GetVehicleMakesQueryVariables>(GetVehicleMakesDocument, options);
      }
export function useGetVehicleMakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleMakesQuery, GetVehicleMakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleMakesQuery, GetVehicleMakesQueryVariables>(GetVehicleMakesDocument, options);
        }
export type GetVehicleMakesQueryHookResult = ReturnType<typeof useGetVehicleMakesQuery>;
export type GetVehicleMakesLazyQueryHookResult = ReturnType<typeof useGetVehicleMakesLazyQuery>;
export type GetVehicleMakesQueryResult = Apollo.QueryResult<GetVehicleMakesQuery, GetVehicleMakesQueryVariables>;
export const GetVehicleModelsDocument = gql`
    query GetVehicleModels($year: String!, $make: String!) {
  vehicleModels(year: $year, make: $make) {
    model
  }
}
    `;

/**
 * __useGetVehicleModelsQuery__
 *
 * To run a query within a React component, call `useGetVehicleModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleModelsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      make: // value for 'make'
 *   },
 * });
 */
export function useGetVehicleModelsQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleModelsQuery, GetVehicleModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleModelsQuery, GetVehicleModelsQueryVariables>(GetVehicleModelsDocument, options);
      }
export function useGetVehicleModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleModelsQuery, GetVehicleModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleModelsQuery, GetVehicleModelsQueryVariables>(GetVehicleModelsDocument, options);
        }
export type GetVehicleModelsQueryHookResult = ReturnType<typeof useGetVehicleModelsQuery>;
export type GetVehicleModelsLazyQueryHookResult = ReturnType<typeof useGetVehicleModelsLazyQuery>;
export type GetVehicleModelsQueryResult = Apollo.QueryResult<GetVehicleModelsQuery, GetVehicleModelsQueryVariables>;
export const GetVehicleVariantsDocument = gql`
    query GetVehicleVariants($year: String!, $make: String!, $model: String!) {
  vehicleVariants(year: $year, make: $make, model: $model) {
    id
    variant
  }
}
    `;

/**
 * __useGetVehicleVariantsQuery__
 *
 * To run a query within a React component, call `useGetVehicleVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleVariantsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetVehicleVariantsQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleVariantsQuery, GetVehicleVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleVariantsQuery, GetVehicleVariantsQueryVariables>(GetVehicleVariantsDocument, options);
      }
export function useGetVehicleVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleVariantsQuery, GetVehicleVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleVariantsQuery, GetVehicleVariantsQueryVariables>(GetVehicleVariantsDocument, options);
        }
export type GetVehicleVariantsQueryHookResult = ReturnType<typeof useGetVehicleVariantsQuery>;
export type GetVehicleVariantsLazyQueryHookResult = ReturnType<typeof useGetVehicleVariantsLazyQuery>;
export type GetVehicleVariantsQueryResult = Apollo.QueryResult<GetVehicleVariantsQuery, GetVehicleVariantsQueryVariables>;
export const GetVehicleCategoriesDocument = gql`
    query GetVehicleCategories {
  vehicleCategories {
    id
    name
    description
  }
}
    `;

/**
 * __useGetVehicleCategoriesQuery__
 *
 * To run a query within a React component, call `useGetVehicleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetVehicleCategoriesQuery, GetVehicleCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleCategoriesQuery, GetVehicleCategoriesQueryVariables>(GetVehicleCategoriesDocument, options);
      }
export function useGetVehicleCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleCategoriesQuery, GetVehicleCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleCategoriesQuery, GetVehicleCategoriesQueryVariables>(GetVehicleCategoriesDocument, options);
        }
export type GetVehicleCategoriesQueryHookResult = ReturnType<typeof useGetVehicleCategoriesQuery>;
export type GetVehicleCategoriesLazyQueryHookResult = ReturnType<typeof useGetVehicleCategoriesLazyQuery>;
export type GetVehicleCategoriesQueryResult = Apollo.QueryResult<GetVehicleCategoriesQuery, GetVehicleCategoriesQueryVariables>;
export const GetVehicleFeaturesDocument = gql`
    query GetVehicleFeatures {
  vehicleFeatures {
    id
    name
    icon
  }
}
    `;

/**
 * __useGetVehicleFeaturesQuery__
 *
 * To run a query within a React component, call `useGetVehicleFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<GetVehicleFeaturesQuery, GetVehicleFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleFeaturesQuery, GetVehicleFeaturesQueryVariables>(GetVehicleFeaturesDocument, options);
      }
export function useGetVehicleFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleFeaturesQuery, GetVehicleFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleFeaturesQuery, GetVehicleFeaturesQueryVariables>(GetVehicleFeaturesDocument, options);
        }
export type GetVehicleFeaturesQueryHookResult = ReturnType<typeof useGetVehicleFeaturesQuery>;
export type GetVehicleFeaturesLazyQueryHookResult = ReturnType<typeof useGetVehicleFeaturesLazyQuery>;
export type GetVehicleFeaturesQueryResult = Apollo.QueryResult<GetVehicleFeaturesQuery, GetVehicleFeaturesQueryVariables>;
export const CreateVehicleDocument = gql`
    mutation CreateVehicle($input: CreateVehicleInput!) {
  createVehicle(input: $input) {
    ...AllVehicleFields
    ...VehicleError
  }
}
    ${AllVehicleFieldsFragmentDoc}
${VehicleErrorFragmentDoc}`;
export type CreateVehicleMutationFn = Apollo.MutationFunction<CreateVehicleMutation, CreateVehicleMutationVariables>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleMutation, CreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleMutation, CreateVehicleMutationVariables>(CreateVehicleDocument, options);
      }
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const GetVehicleByIdDocument = gql`
    query GetVehicleById($id: String!) {
  vehicle: getUserVehicle(id: $id) {
    ...AllVehicleFields
  }
}
    ${AllVehicleFieldsFragmentDoc}`;

/**
 * __useGetVehicleByIdQuery__
 *
 * To run a query within a React component, call `useGetVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>(GetVehicleByIdDocument, options);
      }
export function useGetVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>(GetVehicleByIdDocument, options);
        }
export type GetVehicleByIdQueryHookResult = ReturnType<typeof useGetVehicleByIdQuery>;
export type GetVehicleByIdLazyQueryHookResult = ReturnType<typeof useGetVehicleByIdLazyQuery>;
export type GetVehicleByIdQueryResult = Apollo.QueryResult<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  uploadImage(file: $file) {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadUserImageDocument = gql`
    mutation UploadUserImage($file: Upload!) {
  uploadUserImage(file: $file) {
    __typename
    id
    userId
    url
  }
}
    `;
export type UploadUserImageMutationFn = Apollo.MutationFunction<UploadUserImageMutation, UploadUserImageMutationVariables>;

/**
 * __useUploadUserImageMutation__
 *
 * To run a mutation, you first call `useUploadUserImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserImageMutation, { data, loading, error }] = useUploadUserImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadUserImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserImageMutation, UploadUserImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserImageMutation, UploadUserImageMutationVariables>(UploadUserImageDocument, options);
      }
export type UploadUserImageMutationHookResult = ReturnType<typeof useUploadUserImageMutation>;
export type UploadUserImageMutationResult = Apollo.MutationResult<UploadUserImageMutation>;
export type UploadUserImageMutationOptions = Apollo.BaseMutationOptions<UploadUserImageMutation, UploadUserImageMutationVariables>;
export const ApproveUserDocument = gql`
    mutation ApproveUser($id: String!, $input: ApproveUserInput!) {
  approveUser(id: $id, input: $input) {
    ...UserStatusUpdateRes
  }
}
    ${UserStatusUpdateResFragmentDoc}`;
export type ApproveUserMutationFn = Apollo.MutationFunction<ApproveUserMutation, ApproveUserMutationVariables>;

/**
 * __useApproveUserMutation__
 *
 * To run a mutation, you first call `useApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserMutation, { data, loading, error }] = useApproveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveUserMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUserMutation, ApproveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, options);
      }
export type ApproveUserMutationHookResult = ReturnType<typeof useApproveUserMutation>;
export type ApproveUserMutationResult = Apollo.MutationResult<ApproveUserMutation>;
export type ApproveUserMutationOptions = Apollo.BaseMutationOptions<ApproveUserMutation, ApproveUserMutationVariables>;
export const RejectUserDocument = gql`
    mutation RejectUser($id: String!, $input: ApproveUserInput!) {
  rejectUser(id: $id, input: $input) {
    ...UserStatusUpdateRes
  }
}
    ${UserStatusUpdateResFragmentDoc}`;
export type RejectUserMutationFn = Apollo.MutationFunction<RejectUserMutation, RejectUserMutationVariables>;

/**
 * __useRejectUserMutation__
 *
 * To run a mutation, you first call `useRejectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectUserMutation, { data, loading, error }] = useRejectUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectUserMutation(baseOptions?: Apollo.MutationHookOptions<RejectUserMutation, RejectUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectUserMutation, RejectUserMutationVariables>(RejectUserDocument, options);
      }
export type RejectUserMutationHookResult = ReturnType<typeof useRejectUserMutation>;
export type RejectUserMutationResult = Apollo.MutationResult<RejectUserMutation>;
export type RejectUserMutationOptions = Apollo.BaseMutationOptions<RejectUserMutation, RejectUserMutationVariables>;
export const BlockUserDocument = gql`
    mutation BlockUser($id: String!, $input: BlockUserInput!) {
  blockUser(id: $id, input: $input) {
    ...UserStatusUpdateRes
  }
}
    ${UserStatusUpdateResFragmentDoc}`;
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, options);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const UnblockUserDocument = gql`
    mutation UnblockUser($id: String!) {
  unblockUser(id: $id) {
    ...UserStatusUpdateRes
  }
}
    ${UserStatusUpdateResFragmentDoc}`;
export type UnblockUserMutationFn = Apollo.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: Apollo.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, options);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const ApproveVehicleImagesDocument = gql`
    mutation ApproveVehicleImages($input: ApproveVehicleImageInput!) {
  approveVehicleImages(input: $input) {
    ...VehicleImageFields
  }
}
    ${VehicleImageFieldsFragmentDoc}`;
export type ApproveVehicleImagesMutationFn = Apollo.MutationFunction<ApproveVehicleImagesMutation, ApproveVehicleImagesMutationVariables>;

/**
 * __useApproveVehicleImagesMutation__
 *
 * To run a mutation, you first call `useApproveVehicleImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveVehicleImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveVehicleImagesMutation, { data, loading, error }] = useApproveVehicleImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveVehicleImagesMutation(baseOptions?: Apollo.MutationHookOptions<ApproveVehicleImagesMutation, ApproveVehicleImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveVehicleImagesMutation, ApproveVehicleImagesMutationVariables>(ApproveVehicleImagesDocument, options);
      }
export type ApproveVehicleImagesMutationHookResult = ReturnType<typeof useApproveVehicleImagesMutation>;
export type ApproveVehicleImagesMutationResult = Apollo.MutationResult<ApproveVehicleImagesMutation>;
export type ApproveVehicleImagesMutationOptions = Apollo.BaseMutationOptions<ApproveVehicleImagesMutation, ApproveVehicleImagesMutationVariables>;
export const RejectVehicleImagesDocument = gql`
    mutation RejectVehicleImages($input: ApproveVehicleImageInput!) {
  rejectVehicleImages(input: $input) {
    ...VehicleImageFields
  }
}
    ${VehicleImageFieldsFragmentDoc}`;
export type RejectVehicleImagesMutationFn = Apollo.MutationFunction<RejectVehicleImagesMutation, RejectVehicleImagesMutationVariables>;

/**
 * __useRejectVehicleImagesMutation__
 *
 * To run a mutation, you first call `useRejectVehicleImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectVehicleImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectVehicleImagesMutation, { data, loading, error }] = useRejectVehicleImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectVehicleImagesMutation(baseOptions?: Apollo.MutationHookOptions<RejectVehicleImagesMutation, RejectVehicleImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectVehicleImagesMutation, RejectVehicleImagesMutationVariables>(RejectVehicleImagesDocument, options);
      }
export type RejectVehicleImagesMutationHookResult = ReturnType<typeof useRejectVehicleImagesMutation>;
export type RejectVehicleImagesMutationResult = Apollo.MutationResult<RejectVehicleImagesMutation>;
export type RejectVehicleImagesMutationOptions = Apollo.BaseMutationOptions<RejectVehicleImagesMutation, RejectVehicleImagesMutationVariables>;
export const DeleteVehicleImageDocument = gql`
    mutation DeleteVehicleImage($input: ApproveVehicleImageInput!) {
  deleteVehicleImages(input: $input)
}
    `;
export type DeleteVehicleImageMutationFn = Apollo.MutationFunction<DeleteVehicleImageMutation, DeleteVehicleImageMutationVariables>;

/**
 * __useDeleteVehicleImageMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleImageMutation, { data, loading, error }] = useDeleteVehicleImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVehicleImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleImageMutation, DeleteVehicleImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleImageMutation, DeleteVehicleImageMutationVariables>(DeleteVehicleImageDocument, options);
      }
export type DeleteVehicleImageMutationHookResult = ReturnType<typeof useDeleteVehicleImageMutation>;
export type DeleteVehicleImageMutationResult = Apollo.MutationResult<DeleteVehicleImageMutation>;
export type DeleteVehicleImageMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleImageMutation, DeleteVehicleImageMutationVariables>;
export const UpdateVehicleDailyAttibuteDocument = gql`
    mutation UpdateVehicleDailyAttibute($id: String!, $input: UpdateVehicleDailyAttributes!, $startDate: DateTime!, $endDate: DateTime!) {
  updateVehicleDailyAttribute(id: $id, input: $input) {
    id
    daily(input: {startDate: $startDate, endDate: $endDate}) {
      vehicleId
      date
      isAvailable
      isBooked
      note
    }
  }
}
    `;
export type UpdateVehicleDailyAttibuteMutationFn = Apollo.MutationFunction<UpdateVehicleDailyAttibuteMutation, UpdateVehicleDailyAttibuteMutationVariables>;

/**
 * __useUpdateVehicleDailyAttibuteMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleDailyAttibuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleDailyAttibuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleDailyAttibuteMutation, { data, loading, error }] = useUpdateVehicleDailyAttibuteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateVehicleDailyAttibuteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleDailyAttibuteMutation, UpdateVehicleDailyAttibuteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleDailyAttibuteMutation, UpdateVehicleDailyAttibuteMutationVariables>(UpdateVehicleDailyAttibuteDocument, options);
      }
export type UpdateVehicleDailyAttibuteMutationHookResult = ReturnType<typeof useUpdateVehicleDailyAttibuteMutation>;
export type UpdateVehicleDailyAttibuteMutationResult = Apollo.MutationResult<UpdateVehicleDailyAttibuteMutation>;
export type UpdateVehicleDailyAttibuteMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleDailyAttibuteMutation, UpdateVehicleDailyAttibuteMutationVariables>;
export const GetReservationDocument = gql`
    query getReservation($id: String!) {
  getReservation(id: $id) {
    ... on Reservation {
      ...ReservationFields
    }
  }
}
    ${ReservationFieldsFragmentDoc}`;

/**
 * __useGetReservationQuery__
 *
 * To run a query within a React component, call `useGetReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReservationQuery(baseOptions: Apollo.QueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
      }
export function useGetReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
        }
export type GetReservationQueryHookResult = ReturnType<typeof useGetReservationQuery>;
export type GetReservationLazyQueryHookResult = ReturnType<typeof useGetReservationLazyQuery>;
export type GetReservationQueryResult = Apollo.QueryResult<GetReservationQuery, GetReservationQueryVariables>;
export const ReservationsDocument = gql`
    query Reservations($before: String, $after: String, $first: Int, $last: Int, $where: ReservationWhereInput, $orderBy: ReservationOrderByInput, $input: ReservationsQueryInput) {
  reservations(
    before: $before
    after: $after
    first: $first
    last: $last
    where: $where
    orderBy: $orderBy
    input: $input
  ) {
    ...PagedReservationsWithVehicleName
  }
}
    ${PagedReservationsWithVehicleNameFragmentDoc}`;

/**
 * __useReservationsQuery__
 *
 * To run a query within a React component, call `useReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReservationsQuery(baseOptions?: Apollo.QueryHookOptions<ReservationsQuery, ReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsQuery, ReservationsQueryVariables>(ReservationsDocument, options);
      }
export function useReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsQuery, ReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsQuery, ReservationsQueryVariables>(ReservationsDocument, options);
        }
export type ReservationsQueryHookResult = ReturnType<typeof useReservationsQuery>;
export type ReservationsLazyQueryHookResult = ReturnType<typeof useReservationsLazyQuery>;
export type ReservationsQueryResult = Apollo.QueryResult<ReservationsQuery, ReservationsQueryVariables>;
export const ReservationsWithReviewsDocument = gql`
    query ReservationsWithReviews($before: String, $after: String, $first: Int, $last: Int, $where: ReservationWhereInput, $orderBy: ReservationOrderByInput, $input: ReservationsQueryInput) {
  reservationsWithReviews(
    before: $before
    after: $after
    first: $first
    last: $last
    where: $where
    orderBy: $orderBy
    input: $input
  ) {
    ...PagedReservationsWithReviews
  }
}
    ${PagedReservationsWithReviewsFragmentDoc}`;

/**
 * __useReservationsWithReviewsQuery__
 *
 * To run a query within a React component, call `useReservationsWithReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsWithReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsWithReviewsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReservationsWithReviewsQuery(baseOptions?: Apollo.QueryHookOptions<ReservationsWithReviewsQuery, ReservationsWithReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsWithReviewsQuery, ReservationsWithReviewsQueryVariables>(ReservationsWithReviewsDocument, options);
      }
export function useReservationsWithReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsWithReviewsQuery, ReservationsWithReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsWithReviewsQuery, ReservationsWithReviewsQueryVariables>(ReservationsWithReviewsDocument, options);
        }
export type ReservationsWithReviewsQueryHookResult = ReturnType<typeof useReservationsWithReviewsQuery>;
export type ReservationsWithReviewsLazyQueryHookResult = ReturnType<typeof useReservationsWithReviewsLazyQuery>;
export type ReservationsWithReviewsQueryResult = Apollo.QueryResult<ReservationsWithReviewsQuery, ReservationsWithReviewsQueryVariables>;
export const RequestReservationDocument = gql`
    mutation requestReservation($input: RequestReservationInput!) {
  requestReservation(input: $input) {
    ... on Reservation {
      id
      pricePerDay {
        ...AmountField
      }
      lineItems {
        ...LineItemFields
      }
    }
    ...ReservationErrors
  }
}
    ${AmountFieldFragmentDoc}
${LineItemFieldsFragmentDoc}
${ReservationErrorsFragmentDoc}`;
export type RequestReservationMutationFn = Apollo.MutationFunction<RequestReservationMutation, RequestReservationMutationVariables>;

/**
 * __useRequestReservationMutation__
 *
 * To run a mutation, you first call `useRequestReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReservationMutation, { data, loading, error }] = useRequestReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestReservationMutation(baseOptions?: Apollo.MutationHookOptions<RequestReservationMutation, RequestReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestReservationMutation, RequestReservationMutationVariables>(RequestReservationDocument, options);
      }
export type RequestReservationMutationHookResult = ReturnType<typeof useRequestReservationMutation>;
export type RequestReservationMutationResult = Apollo.MutationResult<RequestReservationMutation>;
export type RequestReservationMutationOptions = Apollo.BaseMutationOptions<RequestReservationMutation, RequestReservationMutationVariables>;
export const AcceptQuoteDocument = gql`
    mutation acceptQuote($id: String!, $input: AcceptQuotationInput!) {
  acceptQuote(id: $id, input: $input) {
    ... on Reservation {
      id
      status
      pricePerDay {
        ...AmountField
      }
      lineItems {
        ...LineItemFields
      }
    }
    ...ReservationErrors
  }
}
    ${AmountFieldFragmentDoc}
${LineItemFieldsFragmentDoc}
${ReservationErrorsFragmentDoc}`;
export type AcceptQuoteMutationFn = Apollo.MutationFunction<AcceptQuoteMutation, AcceptQuoteMutationVariables>;

/**
 * __useAcceptQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteMutation, { data, loading, error }] = useAcceptQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptQuoteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptQuoteMutation, AcceptQuoteMutationVariables>(AcceptQuoteDocument, options);
      }
export type AcceptQuoteMutationHookResult = ReturnType<typeof useAcceptQuoteMutation>;
export type AcceptQuoteMutationResult = Apollo.MutationResult<AcceptQuoteMutation>;
export type AcceptQuoteMutationOptions = Apollo.BaseMutationOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>;
export const CreatePaymentReservationDocument = gql`
    mutation createPaymentReservation($id: String!, $input: PaymentMethodInput) {
  createPaymentReservation(id: $id, input: $input) {
    method
    metadata
  }
}
    `;
export type CreatePaymentReservationMutationFn = Apollo.MutationFunction<CreatePaymentReservationMutation, CreatePaymentReservationMutationVariables>;

/**
 * __useCreatePaymentReservationMutation__
 *
 * To run a mutation, you first call `useCreatePaymentReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentReservationMutation, { data, loading, error }] = useCreatePaymentReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentReservationMutation, CreatePaymentReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentReservationMutation, CreatePaymentReservationMutationVariables>(CreatePaymentReservationDocument, options);
      }
export type CreatePaymentReservationMutationHookResult = ReturnType<typeof useCreatePaymentReservationMutation>;
export type CreatePaymentReservationMutationResult = Apollo.MutationResult<CreatePaymentReservationMutation>;
export type CreatePaymentReservationMutationOptions = Apollo.BaseMutationOptions<CreatePaymentReservationMutation, CreatePaymentReservationMutationVariables>;
export const ApproveReservationDocument = gql`
    mutation ApproveReservation($input: ReservationApprovalInput!) {
  approveReservation(input: $input) {
    ...ReservationFields
  }
}
    ${ReservationFieldsFragmentDoc}`;
export type ApproveReservationMutationFn = Apollo.MutationFunction<ApproveReservationMutation, ApproveReservationMutationVariables>;

/**
 * __useApproveReservationMutation__
 *
 * To run a mutation, you first call `useApproveReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveReservationMutation, { data, loading, error }] = useApproveReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveReservationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveReservationMutation, ApproveReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveReservationMutation, ApproveReservationMutationVariables>(ApproveReservationDocument, options);
      }
export type ApproveReservationMutationHookResult = ReturnType<typeof useApproveReservationMutation>;
export type ApproveReservationMutationResult = Apollo.MutationResult<ApproveReservationMutation>;
export type ApproveReservationMutationOptions = Apollo.BaseMutationOptions<ApproveReservationMutation, ApproveReservationMutationVariables>;
export const RejectReservationDocument = gql`
    mutation RejectReservation($input: ReservationApprovalInput!) {
  rejectReservation(input: $input) {
    ...ReservationFields
  }
}
    ${ReservationFieldsFragmentDoc}`;
export type RejectReservationMutationFn = Apollo.MutationFunction<RejectReservationMutation, RejectReservationMutationVariables>;

/**
 * __useRejectReservationMutation__
 *
 * To run a mutation, you first call `useRejectReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectReservationMutation, { data, loading, error }] = useRejectReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectReservationMutation(baseOptions?: Apollo.MutationHookOptions<RejectReservationMutation, RejectReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectReservationMutation, RejectReservationMutationVariables>(RejectReservationDocument, options);
      }
export type RejectReservationMutationHookResult = ReturnType<typeof useRejectReservationMutation>;
export type RejectReservationMutationResult = Apollo.MutationResult<RejectReservationMutation>;
export type RejectReservationMutationOptions = Apollo.BaseMutationOptions<RejectReservationMutation, RejectReservationMutationVariables>;
export const SetReservationDeliveryDocument = gql`
    mutation SetReservationDelivery($id: String!, $input: SetAddressInput!) {
  setIsDelivery(id: $id, input: $input) {
    ...ReservationFields
  }
}
    ${ReservationFieldsFragmentDoc}`;
export type SetReservationDeliveryMutationFn = Apollo.MutationFunction<SetReservationDeliveryMutation, SetReservationDeliveryMutationVariables>;

/**
 * __useSetReservationDeliveryMutation__
 *
 * To run a mutation, you first call `useSetReservationDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReservationDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReservationDeliveryMutation, { data, loading, error }] = useSetReservationDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReservationDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<SetReservationDeliveryMutation, SetReservationDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReservationDeliveryMutation, SetReservationDeliveryMutationVariables>(SetReservationDeliveryDocument, options);
      }
export type SetReservationDeliveryMutationHookResult = ReturnType<typeof useSetReservationDeliveryMutation>;
export type SetReservationDeliveryMutationResult = Apollo.MutationResult<SetReservationDeliveryMutation>;
export type SetReservationDeliveryMutationOptions = Apollo.BaseMutationOptions<SetReservationDeliveryMutation, SetReservationDeliveryMutationVariables>;
export const SetReservationDeliveryAirportDocument = gql`
    mutation SetReservationDeliveryAirport($id: String!) {
  setIsDeliveryAirport(id: $id) {
    ...ReservationFields
  }
}
    ${ReservationFieldsFragmentDoc}`;
export type SetReservationDeliveryAirportMutationFn = Apollo.MutationFunction<SetReservationDeliveryAirportMutation, SetReservationDeliveryAirportMutationVariables>;

/**
 * __useSetReservationDeliveryAirportMutation__
 *
 * To run a mutation, you first call `useSetReservationDeliveryAirportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReservationDeliveryAirportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReservationDeliveryAirportMutation, { data, loading, error }] = useSetReservationDeliveryAirportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetReservationDeliveryAirportMutation(baseOptions?: Apollo.MutationHookOptions<SetReservationDeliveryAirportMutation, SetReservationDeliveryAirportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReservationDeliveryAirportMutation, SetReservationDeliveryAirportMutationVariables>(SetReservationDeliveryAirportDocument, options);
      }
export type SetReservationDeliveryAirportMutationHookResult = ReturnType<typeof useSetReservationDeliveryAirportMutation>;
export type SetReservationDeliveryAirportMutationResult = Apollo.MutationResult<SetReservationDeliveryAirportMutation>;
export type SetReservationDeliveryAirportMutationOptions = Apollo.BaseMutationOptions<SetReservationDeliveryAirportMutation, SetReservationDeliveryAirportMutationVariables>;
export const VehicleAvailabilityDocument = gql`
    query VehicleAvailability($id: String!, $startDate: DateTime!, $endDate: DateTime!) {
  vehicle(id: $id) {
    id
    daily(input: {startDate: $startDate, endDate: $endDate}) {
      vehicleId
      date
      isAvailable
      isBooked
      note
    }
  }
}
    `;

/**
 * __useVehicleAvailabilityQuery__
 *
 * To run a query within a React component, call `useVehicleAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleAvailabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useVehicleAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<VehicleAvailabilityQuery, VehicleAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleAvailabilityQuery, VehicleAvailabilityQueryVariables>(VehicleAvailabilityDocument, options);
      }
export function useVehicleAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleAvailabilityQuery, VehicleAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleAvailabilityQuery, VehicleAvailabilityQueryVariables>(VehicleAvailabilityDocument, options);
        }
export type VehicleAvailabilityQueryHookResult = ReturnType<typeof useVehicleAvailabilityQuery>;
export type VehicleAvailabilityLazyQueryHookResult = ReturnType<typeof useVehicleAvailabilityLazyQuery>;
export type VehicleAvailabilityQueryResult = Apollo.QueryResult<VehicleAvailabilityQuery, VehicleAvailabilityQueryVariables>;
export const DashboardDriverDocument = gql`
    query DashboardDriver {
  me {
    ... on User {
      id
      activeReservations: reservations(
        first: 5
        where: {timePeriod: ACTIVE, status: {in: ["CONFIRMED", "RESERVED"]}}
        orderBy: {startDate: ASC}
      ) {
        ...DashboardDriverConnection
      }
      futureReservations: reservations(
        first: 5
        where: {timePeriod: FUTURE, status: {in: ["CONFIRMED", "RESERVED"]}}
        orderBy: {startDate: ASC}
      ) {
        ...DashboardDriverConnection
      }
      pastReservations: reservations(
        first: 5
        where: {timePeriod: PAST, status: {in: ["COMPLETED", "UNDER_ASSESSMENT"]}}
        orderBy: {endDate: DESC}
      ) {
        ...DashboardDriverConnection
      }
      requests: reservations(
        first: 5
        where: {status: {in: ["QUOTED", "REQUESTED", "ACCEPTED"]}}
        orderBy: {createdAt: DESC}
      ) {
        ...DashboardDriverConnection
      }
    }
  }
}
    ${DashboardDriverConnectionFragmentDoc}`;

/**
 * __useDashboardDriverQuery__
 *
 * To run a query within a React component, call `useDashboardDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDriverQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDriverQuery(baseOptions?: Apollo.QueryHookOptions<DashboardDriverQuery, DashboardDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardDriverQuery, DashboardDriverQueryVariables>(DashboardDriverDocument, options);
      }
export function useDashboardDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardDriverQuery, DashboardDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardDriverQuery, DashboardDriverQueryVariables>(DashboardDriverDocument, options);
        }
export type DashboardDriverQueryHookResult = ReturnType<typeof useDashboardDriverQuery>;
export type DashboardDriverLazyQueryHookResult = ReturnType<typeof useDashboardDriverLazyQuery>;
export type DashboardDriverQueryResult = Apollo.QueryResult<DashboardDriverQuery, DashboardDriverQueryVariables>;
export const DashboardOwnerDocument = gql`
    query DashboardOwner {
  me {
    ... on User {
      id
      activeReservations: vehicleReservations(
        first: 5
        where: {timePeriod: ACTIVE, status: {in: ["CONFIRMED", "RESERVED"]}}
        orderBy: {startDate: ASC}
      ) {
        ...DashboardOwnerConnection
      }
      futureReservations: vehicleReservations(
        first: 5
        where: {timePeriod: FUTURE, status: {in: ["CONFIRMED", "RESERVED"]}}
        orderBy: {startDate: ASC}
      ) {
        ...DashboardOwnerConnection
      }
      pastReservations: vehicleReservations(
        first: 5
        where: {timePeriod: PAST, status: {in: ["COMPLETED", "UNDER_ASSESSMENT"]}}
        orderBy: {endDate: DESC}
      ) {
        ...DashboardOwnerConnection
      }
      requests: vehicleReservations(
        first: 5
        where: {status: {in: ["REQUESTED", "ACCEPTED"]}}
        orderBy: {createdAt: DESC}
      ) {
        ...DashboardOwnerConnection
      }
    }
  }
}
    ${DashboardOwnerConnectionFragmentDoc}`;

/**
 * __useDashboardOwnerQuery__
 *
 * To run a query within a React component, call `useDashboardOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOwnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardOwnerQuery(baseOptions?: Apollo.QueryHookOptions<DashboardOwnerQuery, DashboardOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardOwnerQuery, DashboardOwnerQueryVariables>(DashboardOwnerDocument, options);
      }
export function useDashboardOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardOwnerQuery, DashboardOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardOwnerQuery, DashboardOwnerQueryVariables>(DashboardOwnerDocument, options);
        }
export type DashboardOwnerQueryHookResult = ReturnType<typeof useDashboardOwnerQuery>;
export type DashboardOwnerLazyQueryHookResult = ReturnType<typeof useDashboardOwnerLazyQuery>;
export type DashboardOwnerQueryResult = Apollo.QueryResult<DashboardOwnerQuery, DashboardOwnerQueryVariables>;
export const MyReservationsDocument = gql`
    query MyReservations {
  me {
    ... on User {
      id
      reservations: vehicleReservations(
        first: 30
        where: {status: {eq: "CONFIRMED"}}
        orderBy: {startDate: ASC}
      ) {
        ...PagedReservationsWithVehicle
      }
    }
  }
}
    ${PagedReservationsWithVehicleFragmentDoc}`;

/**
 * __useMyReservationsQuery__
 *
 * To run a query within a React component, call `useMyReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReservationsQuery(baseOptions?: Apollo.QueryHookOptions<MyReservationsQuery, MyReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyReservationsQuery, MyReservationsQueryVariables>(MyReservationsDocument, options);
      }
export function useMyReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyReservationsQuery, MyReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyReservationsQuery, MyReservationsQueryVariables>(MyReservationsDocument, options);
        }
export type MyReservationsQueryHookResult = ReturnType<typeof useMyReservationsQuery>;
export type MyReservationsLazyQueryHookResult = ReturnType<typeof useMyReservationsLazyQuery>;
export type MyReservationsQueryResult = Apollo.QueryResult<MyReservationsQuery, MyReservationsQueryVariables>;
export const CancelReservationDocument = gql`
    mutation CancelReservation($id: String!, $reason: String!) {
  cancelReservation(id: $id, reason: $reason) {
    ... on Reservation {
      id
      status
      scopes
    }
    ...ReservationErrors
  }
}
    ${ReservationErrorsFragmentDoc}`;
export type CancelReservationMutationFn = Apollo.MutationFunction<CancelReservationMutation, CancelReservationMutationVariables>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelReservationMutation(baseOptions?: Apollo.MutationHookOptions<CancelReservationMutation, CancelReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelReservationMutation, CancelReservationMutationVariables>(CancelReservationDocument, options);
      }
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>;
export type CancelReservationMutationResult = Apollo.MutationResult<CancelReservationMutation>;
export type CancelReservationMutationOptions = Apollo.BaseMutationOptions<CancelReservationMutation, CancelReservationMutationVariables>;
export const CompleteReservationDocument = gql`
    mutation CompleteReservation($id: String!) {
  completeReservation(id: $id) {
    ... on Reservation {
      id
      status
      scopes
    }
    ...ReservationErrors
  }
}
    ${ReservationErrorsFragmentDoc}`;
export type CompleteReservationMutationFn = Apollo.MutationFunction<CompleteReservationMutation, CompleteReservationMutationVariables>;

/**
 * __useCompleteReservationMutation__
 *
 * To run a mutation, you first call `useCompleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeReservationMutation, { data, loading, error }] = useCompleteReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteReservationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteReservationMutation, CompleteReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteReservationMutation, CompleteReservationMutationVariables>(CompleteReservationDocument, options);
      }
export type CompleteReservationMutationHookResult = ReturnType<typeof useCompleteReservationMutation>;
export type CompleteReservationMutationResult = Apollo.MutationResult<CompleteReservationMutation>;
export type CompleteReservationMutationOptions = Apollo.BaseMutationOptions<CompleteReservationMutation, CompleteReservationMutationVariables>;
export const UpdateUserBankAccountDocument = gql`
    mutation UpdateUserBankAccount($id: String!, $input: BankAccountUpdate!) {
  updateBankAccount(id: $id, input: $input) {
    id
    bankAccount {
      ...BankAccountFields
    }
  }
}
    ${BankAccountFieldsFragmentDoc}`;
export type UpdateUserBankAccountMutationFn = Apollo.MutationFunction<UpdateUserBankAccountMutation, UpdateUserBankAccountMutationVariables>;

/**
 * __useUpdateUserBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBankAccountMutation, { data, loading, error }] = useUpdateUserBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBankAccountMutation, UpdateUserBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBankAccountMutation, UpdateUserBankAccountMutationVariables>(UpdateUserBankAccountDocument, options);
      }
export type UpdateUserBankAccountMutationHookResult = ReturnType<typeof useUpdateUserBankAccountMutation>;
export type UpdateUserBankAccountMutationResult = Apollo.MutationResult<UpdateUserBankAccountMutation>;
export type UpdateUserBankAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserBankAccountMutation, UpdateUserBankAccountMutationVariables>;
export const UpdateUserCommissionDocument = gql`
    mutation UpdateUserCommission($id: String!, $commission: Float!) {
  updateUserCommission(id: $id, commission: $commission) {
    id
    profile {
      ...ProfileFields
    }
  }
}
    ${ProfileFieldsFragmentDoc}`;
export type UpdateUserCommissionMutationFn = Apollo.MutationFunction<UpdateUserCommissionMutation, UpdateUserCommissionMutationVariables>;

/**
 * __useUpdateUserCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateUserCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCommissionMutation, { data, loading, error }] = useUpdateUserCommissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      commission: // value for 'commission'
 *   },
 * });
 */
export function useUpdateUserCommissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCommissionMutation, UpdateUserCommissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCommissionMutation, UpdateUserCommissionMutationVariables>(UpdateUserCommissionDocument, options);
      }
export type UpdateUserCommissionMutationHookResult = ReturnType<typeof useUpdateUserCommissionMutation>;
export type UpdateUserCommissionMutationResult = Apollo.MutationResult<UpdateUserCommissionMutation>;
export type UpdateUserCommissionMutationOptions = Apollo.BaseMutationOptions<UpdateUserCommissionMutation, UpdateUserCommissionMutationVariables>;
export const GetCitiesDocument = gql`
    query GetCities {
  findPlaces {
    name
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const ProviderRegisterDocument = gql`
    mutation ProviderRegister($input: ProviderRegisterInput!) {
  providerRegister(input: $input) {
    ...ProviderAuthResult
  }
}
    ${ProviderAuthResultFragmentDoc}`;
export type ProviderRegisterMutationFn = Apollo.MutationFunction<ProviderRegisterMutation, ProviderRegisterMutationVariables>;

/**
 * __useProviderRegisterMutation__
 *
 * To run a mutation, you first call `useProviderRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerRegisterMutation, { data, loading, error }] = useProviderRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderRegisterMutation(baseOptions?: Apollo.MutationHookOptions<ProviderRegisterMutation, ProviderRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderRegisterMutation, ProviderRegisterMutationVariables>(ProviderRegisterDocument, options);
      }
export type ProviderRegisterMutationHookResult = ReturnType<typeof useProviderRegisterMutation>;
export type ProviderRegisterMutationResult = Apollo.MutationResult<ProviderRegisterMutation>;
export type ProviderRegisterMutationOptions = Apollo.BaseMutationOptions<ProviderRegisterMutation, ProviderRegisterMutationVariables>;
export const ProviderLoginDocument = gql`
    mutation ProviderLogin($input: ProviderLoginInput!) {
  providerLogin(input: $input) {
    ...ProviderAuthResult
  }
}
    ${ProviderAuthResultFragmentDoc}`;
export type ProviderLoginMutationFn = Apollo.MutationFunction<ProviderLoginMutation, ProviderLoginMutationVariables>;

/**
 * __useProviderLoginMutation__
 *
 * To run a mutation, you first call `useProviderLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerLoginMutation, { data, loading, error }] = useProviderLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderLoginMutation(baseOptions?: Apollo.MutationHookOptions<ProviderLoginMutation, ProviderLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderLoginMutation, ProviderLoginMutationVariables>(ProviderLoginDocument, options);
      }
export type ProviderLoginMutationHookResult = ReturnType<typeof useProviderLoginMutation>;
export type ProviderLoginMutationResult = Apollo.MutationResult<ProviderLoginMutation>;
export type ProviderLoginMutationOptions = Apollo.BaseMutationOptions<ProviderLoginMutation, ProviderLoginMutationVariables>;
export const ExportUsersDocument = gql`
    mutation ExportUsers($input: ExportUsersInput!) {
  exportUsers(input: $input) {
    ... on ExportUsersResult {
      message
    }
    ... on ForbidenError {
      message
    }
  }
}
    `;
export type ExportUsersMutationFn = Apollo.MutationFunction<ExportUsersMutation, ExportUsersMutationVariables>;

/**
 * __useExportUsersMutation__
 *
 * To run a mutation, you first call `useExportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUsersMutation, { data, loading, error }] = useExportUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportUsersMutation(baseOptions?: Apollo.MutationHookOptions<ExportUsersMutation, ExportUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUsersMutation, ExportUsersMutationVariables>(ExportUsersDocument, options);
      }
export type ExportUsersMutationHookResult = ReturnType<typeof useExportUsersMutation>;
export type ExportUsersMutationResult = Apollo.MutationResult<ExportUsersMutation>;
export type ExportUsersMutationOptions = Apollo.BaseMutationOptions<ExportUsersMutation, ExportUsersMutationVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($input: CreateReviewInput!, $reservationId: String!) {
  createReview(input: $input, reservationId: $reservationId) {
    __typename
    ... on Review {
      id
    }
    ... on ReviewTimeExpiredError {
      errorCode
      message
    }
  }
}
    `;
export type CreateReviewMutationFn = Apollo.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const UpdateReviewDocument = gql`
    mutation UpdateReview($input: UpdateReviewInput!, $reviewId: String!) {
  updateReview(input: $input, reviewId: $reviewId) {
    ... on Review {
      id
    }
  }
}
    `;
export type UpdateReviewMutationFn = Apollo.MutationFunction<UpdateReviewMutation, UpdateReviewMutationVariables>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useUpdateReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReviewMutation, UpdateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(UpdateReviewDocument, options);
      }
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<UpdateReviewMutation, UpdateReviewMutationVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($input: TypeInput!) {
  getCategories(input: $input) {
    id
    createdAt
    updatedAt
    name
    type
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetTransactionsDocument = gql`
    query GetTransactions($before: String, $after: String, $first: Int, $last: Int, $where: TransactionWhereInput, $orderBy: TransactionOrderByInput) {
  getTransactions(
    before: $before
    after: $after
    first: $first
    last: $last
    where: $where
    orderBy: $orderBy
  ) {
    ...PagedTransactions
  }
}
    ${PagedTransactionsFragmentDoc}`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetTransactionByIdDocument = gql`
    query GetTransactionById($id: String!) {
  getTransactionById(id: $id) {
    ...TransactionFieldsWithReservation
  }
}
    ${TransactionFieldsWithReservationFragmentDoc}`;

/**
 * __useGetTransactionByIdQuery__
 *
 * To run a query within a React component, call `useGetTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>(GetTransactionByIdDocument, options);
      }
export function useGetTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>(GetTransactionByIdDocument, options);
        }
export type GetTransactionByIdQueryHookResult = ReturnType<typeof useGetTransactionByIdQuery>;
export type GetTransactionByIdLazyQueryHookResult = ReturnType<typeof useGetTransactionByIdLazyQuery>;
export type GetTransactionByIdQueryResult = Apollo.QueryResult<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>;
export const ReservationHandoverDocument = gql`
    query ReservationHandover($id: String!) {
  getReservation(id: $id) {
    ... on Reservation {
      id
      scopes
      handover {
        ...HandoverFields
      }
    }
  }
}
    ${HandoverFieldsFragmentDoc}`;

/**
 * __useReservationHandoverQuery__
 *
 * To run a query within a React component, call `useReservationHandoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationHandoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationHandoverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationHandoverQuery(baseOptions: Apollo.QueryHookOptions<ReservationHandoverQuery, ReservationHandoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationHandoverQuery, ReservationHandoverQueryVariables>(ReservationHandoverDocument, options);
      }
export function useReservationHandoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationHandoverQuery, ReservationHandoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationHandoverQuery, ReservationHandoverQueryVariables>(ReservationHandoverDocument, options);
        }
export type ReservationHandoverQueryHookResult = ReturnType<typeof useReservationHandoverQuery>;
export type ReservationHandoverLazyQueryHookResult = ReturnType<typeof useReservationHandoverLazyQuery>;
export type ReservationHandoverQueryResult = Apollo.QueryResult<ReservationHandoverQuery, ReservationHandoverQueryVariables>;
export const GetHandoverByIdDocument = gql`
    query GetHandoverById($id: String!) {
  getHandoverById(id: $id) {
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on Handover {
      ...HandoverFields
    }
  }
}
    ${HandoverFieldsFragmentDoc}`;

/**
 * __useGetHandoverByIdQuery__
 *
 * To run a query within a React component, call `useGetHandoverByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandoverByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandoverByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHandoverByIdQuery(baseOptions: Apollo.QueryHookOptions<GetHandoverByIdQuery, GetHandoverByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHandoverByIdQuery, GetHandoverByIdQueryVariables>(GetHandoverByIdDocument, options);
      }
export function useGetHandoverByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHandoverByIdQuery, GetHandoverByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHandoverByIdQuery, GetHandoverByIdQueryVariables>(GetHandoverByIdDocument, options);
        }
export type GetHandoverByIdQueryHookResult = ReturnType<typeof useGetHandoverByIdQuery>;
export type GetHandoverByIdLazyQueryHookResult = ReturnType<typeof useGetHandoverByIdLazyQuery>;
export type GetHandoverByIdQueryResult = Apollo.QueryResult<GetHandoverByIdQuery, GetHandoverByIdQueryVariables>;
export const CreateHandoverDocument = gql`
    mutation CreateHandover($reservationId: String!) {
  createHandover(reservationId: $reservationId) {
    ... on ReservationNotFoundError {
      errorCode
      message
    }
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on Handover {
      ...HandoverFields
    }
  }
}
    ${HandoverFieldsFragmentDoc}`;
export type CreateHandoverMutationFn = Apollo.MutationFunction<CreateHandoverMutation, CreateHandoverMutationVariables>;

/**
 * __useCreateHandoverMutation__
 *
 * To run a mutation, you first call `useCreateHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHandoverMutation, { data, loading, error }] = useCreateHandoverMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useCreateHandoverMutation(baseOptions?: Apollo.MutationHookOptions<CreateHandoverMutation, CreateHandoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHandoverMutation, CreateHandoverMutationVariables>(CreateHandoverDocument, options);
      }
export type CreateHandoverMutationHookResult = ReturnType<typeof useCreateHandoverMutation>;
export type CreateHandoverMutationResult = Apollo.MutationResult<CreateHandoverMutation>;
export type CreateHandoverMutationOptions = Apollo.BaseMutationOptions<CreateHandoverMutation, CreateHandoverMutationVariables>;
export const UpdateHandoverDocument = gql`
    mutation UpdateHandover($id: String!, $input: UpdateHandoverInput!) {
  updateHandover(id: $id, input: $input) {
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on Handover {
      ...HandoverFields
    }
  }
}
    ${HandoverFieldsFragmentDoc}`;
export type UpdateHandoverMutationFn = Apollo.MutationFunction<UpdateHandoverMutation, UpdateHandoverMutationVariables>;

/**
 * __useUpdateHandoverMutation__
 *
 * To run a mutation, you first call `useUpdateHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandoverMutation, { data, loading, error }] = useUpdateHandoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHandoverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHandoverMutation, UpdateHandoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHandoverMutation, UpdateHandoverMutationVariables>(UpdateHandoverDocument, options);
      }
export type UpdateHandoverMutationHookResult = ReturnType<typeof useUpdateHandoverMutation>;
export type UpdateHandoverMutationResult = Apollo.MutationResult<UpdateHandoverMutation>;
export type UpdateHandoverMutationOptions = Apollo.BaseMutationOptions<UpdateHandoverMutation, UpdateHandoverMutationVariables>;
export const AddHandoverFileDocument = gql`
    mutation AddHandoverFile($handoverId: String!, $input: AddHandoverFileInput!) {
  addHandoverFile(handoverId: $handoverId, input: $input) {
    ...HandoverFileFields
  }
}
    ${HandoverFileFieldsFragmentDoc}`;
export type AddHandoverFileMutationFn = Apollo.MutationFunction<AddHandoverFileMutation, AddHandoverFileMutationVariables>;

/**
 * __useAddHandoverFileMutation__
 *
 * To run a mutation, you first call `useAddHandoverFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHandoverFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHandoverFileMutation, { data, loading, error }] = useAddHandoverFileMutation({
 *   variables: {
 *      handoverId: // value for 'handoverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHandoverFileMutation(baseOptions?: Apollo.MutationHookOptions<AddHandoverFileMutation, AddHandoverFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHandoverFileMutation, AddHandoverFileMutationVariables>(AddHandoverFileDocument, options);
      }
export type AddHandoverFileMutationHookResult = ReturnType<typeof useAddHandoverFileMutation>;
export type AddHandoverFileMutationResult = Apollo.MutationResult<AddHandoverFileMutation>;
export type AddHandoverFileMutationOptions = Apollo.BaseMutationOptions<AddHandoverFileMutation, AddHandoverFileMutationVariables>;
export const DeleteHandoverFileDocument = gql`
    mutation DeleteHandoverFile($handoverFileId: String!, $handoverId: String!) {
  deleteHandoverFile(handoverFileId: $handoverFileId, handoverId: $handoverId) {
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on HandoverAccessError {
      errorCode
      message
    }
  }
}
    `;
export type DeleteHandoverFileMutationFn = Apollo.MutationFunction<DeleteHandoverFileMutation, DeleteHandoverFileMutationVariables>;

/**
 * __useDeleteHandoverFileMutation__
 *
 * To run a mutation, you first call `useDeleteHandoverFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHandoverFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHandoverFileMutation, { data, loading, error }] = useDeleteHandoverFileMutation({
 *   variables: {
 *      handoverFileId: // value for 'handoverFileId'
 *      handoverId: // value for 'handoverId'
 *   },
 * });
 */
export function useDeleteHandoverFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHandoverFileMutation, DeleteHandoverFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHandoverFileMutation, DeleteHandoverFileMutationVariables>(DeleteHandoverFileDocument, options);
      }
export type DeleteHandoverFileMutationHookResult = ReturnType<typeof useDeleteHandoverFileMutation>;
export type DeleteHandoverFileMutationResult = Apollo.MutationResult<DeleteHandoverFileMutation>;
export type DeleteHandoverFileMutationOptions = Apollo.BaseMutationOptions<DeleteHandoverFileMutation, DeleteHandoverFileMutationVariables>;
export const AddNoteToHandoverDocument = gql`
    mutation AddNoteToHandover($handoverId: String!, $input: AddNoteToHandoverInput!) {
  addNoteToHandover(handoverId: $handoverId, input: $input) {
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on HandoverAccessError {
      errorCode
      message
    }
    ... on HandoverNote {
      ...HandoverNoteFields
    }
  }
}
    ${HandoverNoteFieldsFragmentDoc}`;
export type AddNoteToHandoverMutationFn = Apollo.MutationFunction<AddNoteToHandoverMutation, AddNoteToHandoverMutationVariables>;

/**
 * __useAddNoteToHandoverMutation__
 *
 * To run a mutation, you first call `useAddNoteToHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteToHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteToHandoverMutation, { data, loading, error }] = useAddNoteToHandoverMutation({
 *   variables: {
 *      handoverId: // value for 'handoverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteToHandoverMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteToHandoverMutation, AddNoteToHandoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNoteToHandoverMutation, AddNoteToHandoverMutationVariables>(AddNoteToHandoverDocument, options);
      }
export type AddNoteToHandoverMutationHookResult = ReturnType<typeof useAddNoteToHandoverMutation>;
export type AddNoteToHandoverMutationResult = Apollo.MutationResult<AddNoteToHandoverMutation>;
export type AddNoteToHandoverMutationOptions = Apollo.BaseMutationOptions<AddNoteToHandoverMutation, AddNoteToHandoverMutationVariables>;
export const UpdateHandoverNoteDocument = gql`
    mutation UpdateHandoverNote($handoverId: String!, $handoverNoteId: String!, $input: UpdateNoteInput!) {
  updateHandoverNote(
    handoverId: $handoverId
    handoverNoteId: $handoverNoteId
    input: $input
  ) {
    ... on InvalidHandoverError {
      errorCode
      message
    }
    ... on HandoverAccessError {
      errorCode
      message
    }
    ... on HandoverNote {
      ...HandoverNoteFields
    }
  }
}
    ${HandoverNoteFieldsFragmentDoc}`;
export type UpdateHandoverNoteMutationFn = Apollo.MutationFunction<UpdateHandoverNoteMutation, UpdateHandoverNoteMutationVariables>;

/**
 * __useUpdateHandoverNoteMutation__
 *
 * To run a mutation, you first call `useUpdateHandoverNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandoverNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandoverNoteMutation, { data, loading, error }] = useUpdateHandoverNoteMutation({
 *   variables: {
 *      handoverId: // value for 'handoverId'
 *      handoverNoteId: // value for 'handoverNoteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHandoverNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHandoverNoteMutation, UpdateHandoverNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHandoverNoteMutation, UpdateHandoverNoteMutationVariables>(UpdateHandoverNoteDocument, options);
      }
export type UpdateHandoverNoteMutationHookResult = ReturnType<typeof useUpdateHandoverNoteMutation>;
export type UpdateHandoverNoteMutationResult = Apollo.MutationResult<UpdateHandoverNoteMutation>;
export type UpdateHandoverNoteMutationOptions = Apollo.BaseMutationOptions<UpdateHandoverNoteMutation, UpdateHandoverNoteMutationVariables>;
export const GetVehicleNotesDocument = gql`
    query getVehicleNotes($vehicleId: String!) {
  getVehicleNotes(vehicleId: $vehicleId) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;

/**
 * __useGetVehicleNotesQuery__
 *
 * To run a query within a React component, call `useGetVehicleNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleNotesQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetVehicleNotesQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleNotesQuery, GetVehicleNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleNotesQuery, GetVehicleNotesQueryVariables>(GetVehicleNotesDocument, options);
      }
export function useGetVehicleNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleNotesQuery, GetVehicleNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleNotesQuery, GetVehicleNotesQueryVariables>(GetVehicleNotesDocument, options);
        }
export type GetVehicleNotesQueryHookResult = ReturnType<typeof useGetVehicleNotesQuery>;
export type GetVehicleNotesLazyQueryHookResult = ReturnType<typeof useGetVehicleNotesLazyQuery>;
export type GetVehicleNotesQueryResult = Apollo.QueryResult<GetVehicleNotesQuery, GetVehicleNotesQueryVariables>;
export const DeleteVehicleNoteDocument = gql`
    mutation DeleteVehicleNote($noteId: String!) {
  deleteVehicleNote(noteId: $noteId) {
    ... on VehicleNote {
      id
    }
  }
}
    `;
export type DeleteVehicleNoteMutationFn = Apollo.MutationFunction<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>;

/**
 * __useDeleteVehicleNoteMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleNoteMutation, { data, loading, error }] = useDeleteVehicleNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteVehicleNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>(DeleteVehicleNoteDocument, options);
      }
export type DeleteVehicleNoteMutationHookResult = ReturnType<typeof useDeleteVehicleNoteMutation>;
export type DeleteVehicleNoteMutationResult = Apollo.MutationResult<DeleteVehicleNoteMutation>;
export type DeleteVehicleNoteMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>;
export const AddNoteDocument = gql`
    mutation addNote($vehicleId: String!, $input: CreateVehicleNoteInput!) {
  addVehicleNote(vehicleId: $vehicleId, input: $input) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;
export type AddNoteMutationFn = Apollo.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, options);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;
export const GetReservationNotesDocument = gql`
    query getReservationNotes($input: QueryReservationNotesInput!, $before: String, $after: String, $first: Int, $last: Int, $orderBy: ReservationNoteOrderByInput, $where: ReservationNoteWhereInput) {
  getReservationNotes(
    input: $input
    before: $before
    after: $after
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
  ) {
    ...PagedReservationNotes
  }
}
    ${PagedReservationNotesFragmentDoc}`;

/**
 * __useGetReservationNotesQuery__
 *
 * To run a query within a React component, call `useGetReservationNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReservationNotesQuery(baseOptions: Apollo.QueryHookOptions<GetReservationNotesQuery, GetReservationNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationNotesQuery, GetReservationNotesQueryVariables>(GetReservationNotesDocument, options);
      }
export function useGetReservationNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationNotesQuery, GetReservationNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationNotesQuery, GetReservationNotesQueryVariables>(GetReservationNotesDocument, options);
        }
export type GetReservationNotesQueryHookResult = ReturnType<typeof useGetReservationNotesQuery>;
export type GetReservationNotesLazyQueryHookResult = ReturnType<typeof useGetReservationNotesLazyQuery>;
export type GetReservationNotesQueryResult = Apollo.QueryResult<GetReservationNotesQuery, GetReservationNotesQueryVariables>;
export const DeleteReservationNoteDocument = gql`
    mutation DeleteReservationNote($id: String!, $reservationId: String!) {
  deleteReservationNote(id: $id, reservationId: $reservationId) {
    ... on ReservationNote {
      id
    }
  }
}
    `;
export type DeleteReservationNoteMutationFn = Apollo.MutationFunction<DeleteReservationNoteMutation, DeleteReservationNoteMutationVariables>;

/**
 * __useDeleteReservationNoteMutation__
 *
 * To run a mutation, you first call `useDeleteReservationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationNoteMutation, { data, loading, error }] = useDeleteReservationNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useDeleteReservationNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReservationNoteMutation, DeleteReservationNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReservationNoteMutation, DeleteReservationNoteMutationVariables>(DeleteReservationNoteDocument, options);
      }
export type DeleteReservationNoteMutationHookResult = ReturnType<typeof useDeleteReservationNoteMutation>;
export type DeleteReservationNoteMutationResult = Apollo.MutationResult<DeleteReservationNoteMutation>;
export type DeleteReservationNoteMutationOptions = Apollo.BaseMutationOptions<DeleteReservationNoteMutation, DeleteReservationNoteMutationVariables>;
export const AddReservationNoteDocument = gql`
    mutation addReservationNote($input: AddReservationNotesInput!) {
  addReservationNote(input: $input) {
    ...ReservationNoteFields
  }
}
    ${ReservationNoteFieldsFragmentDoc}`;
export type AddReservationNoteMutationFn = Apollo.MutationFunction<AddReservationNoteMutation, AddReservationNoteMutationVariables>;

/**
 * __useAddReservationNoteMutation__
 *
 * To run a mutation, you first call `useAddReservationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReservationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReservationNoteMutation, { data, loading, error }] = useAddReservationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReservationNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddReservationNoteMutation, AddReservationNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReservationNoteMutation, AddReservationNoteMutationVariables>(AddReservationNoteDocument, options);
      }
export type AddReservationNoteMutationHookResult = ReturnType<typeof useAddReservationNoteMutation>;
export type AddReservationNoteMutationResult = Apollo.MutationResult<AddReservationNoteMutation>;
export type AddReservationNoteMutationOptions = Apollo.BaseMutationOptions<AddReservationNoteMutation, AddReservationNoteMutationVariables>;
export const GetProfileNotesDocument = gql`
    query getProfileNotes($before: String, $after: String, $first: Int, $last: Int, $orderBy: ProfileNoteOrderByInput, $where: ProfileNoteWhereInput, $input: ProfileNoteQueryInput!) {
  getProfileNotes(
    before: $before
    after: $after
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
    input: $input
  ) {
    ...PagedProfileNotes
  }
}
    ${PagedProfileNotesFragmentDoc}`;

/**
 * __useGetProfileNotesQuery__
 *
 * To run a query within a React component, call `useGetProfileNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileNotesQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileNotesQuery(baseOptions: Apollo.QueryHookOptions<GetProfileNotesQuery, GetProfileNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileNotesQuery, GetProfileNotesQueryVariables>(GetProfileNotesDocument, options);
      }
export function useGetProfileNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileNotesQuery, GetProfileNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileNotesQuery, GetProfileNotesQueryVariables>(GetProfileNotesDocument, options);
        }
export type GetProfileNotesQueryHookResult = ReturnType<typeof useGetProfileNotesQuery>;
export type GetProfileNotesLazyQueryHookResult = ReturnType<typeof useGetProfileNotesLazyQuery>;
export type GetProfileNotesQueryResult = Apollo.QueryResult<GetProfileNotesQuery, GetProfileNotesQueryVariables>;
export const DeleteProfileNoteDocument = gql`
    mutation DeleteProfileNote($profileNoteId: String!) {
  deleteProfileNote(profileNoteId: $profileNoteId) {
    ... on ProfileNoteNotFoundError {
      errorCode
      message
    }
    ... on InvalidProfileNoteError {
      errorCode
      message
    }
    ... on ProfileNote {
      id
    }
  }
}
    `;
export type DeleteProfileNoteMutationFn = Apollo.MutationFunction<DeleteProfileNoteMutation, DeleteProfileNoteMutationVariables>;

/**
 * __useDeleteProfileNoteMutation__
 *
 * To run a mutation, you first call `useDeleteProfileNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileNoteMutation, { data, loading, error }] = useDeleteProfileNoteMutation({
 *   variables: {
 *      profileNoteId: // value for 'profileNoteId'
 *   },
 * });
 */
export function useDeleteProfileNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileNoteMutation, DeleteProfileNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileNoteMutation, DeleteProfileNoteMutationVariables>(DeleteProfileNoteDocument, options);
      }
export type DeleteProfileNoteMutationHookResult = ReturnType<typeof useDeleteProfileNoteMutation>;
export type DeleteProfileNoteMutationResult = Apollo.MutationResult<DeleteProfileNoteMutation>;
export type DeleteProfileNoteMutationOptions = Apollo.BaseMutationOptions<DeleteProfileNoteMutation, DeleteProfileNoteMutationVariables>;
export const CreateProfileNoteDocument = gql`
    mutation CreateProfileNote($input: ProfileNoteInput!) {
  createProfileNote(input: $input) {
    ... on ProfileNote {
      ...ProfileNoteFields
    }
    ... on ProfileNoteNotFoundError {
      errorCode
      message
    }
    ... on InvalidProfileNoteError {
      errorCode
      message
    }
    ... on UnAuthorizedNoteActionError {
      errorCode
      message
    }
  }
}
    ${ProfileNoteFieldsFragmentDoc}`;
export type CreateProfileNoteMutationFn = Apollo.MutationFunction<CreateProfileNoteMutation, CreateProfileNoteMutationVariables>;

/**
 * __useCreateProfileNoteMutation__
 *
 * To run a mutation, you first call `useCreateProfileNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileNoteMutation, { data, loading, error }] = useCreateProfileNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileNoteMutation, CreateProfileNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileNoteMutation, CreateProfileNoteMutationVariables>(CreateProfileNoteDocument, options);
      }
export type CreateProfileNoteMutationHookResult = ReturnType<typeof useCreateProfileNoteMutation>;
export type CreateProfileNoteMutationResult = Apollo.MutationResult<CreateProfileNoteMutation>;
export type CreateProfileNoteMutationOptions = Apollo.BaseMutationOptions<CreateProfileNoteMutation, CreateProfileNoteMutationVariables>;
export const ChangeTripStatusDocument = gql`
    mutation changeTripStatus($handoverId: String!, $status: HandoverStatus!) {
  changeTripStatus(handoverId: $handoverId, status: $status) {
    ... on Handover {
      ...HandoverFields
    }
  }
}
    ${HandoverFieldsFragmentDoc}`;
export type ChangeTripStatusMutationFn = Apollo.MutationFunction<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>;

/**
 * __useChangeTripStatusMutation__
 *
 * To run a mutation, you first call `useChangeTripStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTripStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTripStatusMutation, { data, loading, error }] = useChangeTripStatusMutation({
 *   variables: {
 *      handoverId: // value for 'handoverId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeTripStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>(ChangeTripStatusDocument, options);
      }
export type ChangeTripStatusMutationHookResult = ReturnType<typeof useChangeTripStatusMutation>;
export type ChangeTripStatusMutationResult = Apollo.MutationResult<ChangeTripStatusMutation>;
export type ChangeTripStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>;
export const GetHandoverImagesByTypeDocument = gql`
    query getHandoverImagesByType($id: String!, $type: HandoverFilesEnum!) {
  handoverImages(handoverId: $id, type: $type) {
    id
    src
    isOwner
  }
}
    `;

/**
 * __useGetHandoverImagesByTypeQuery__
 *
 * To run a query within a React component, call `useGetHandoverImagesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandoverImagesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandoverImagesByTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetHandoverImagesByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetHandoverImagesByTypeQuery, GetHandoverImagesByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHandoverImagesByTypeQuery, GetHandoverImagesByTypeQueryVariables>(GetHandoverImagesByTypeDocument, options);
      }
export function useGetHandoverImagesByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHandoverImagesByTypeQuery, GetHandoverImagesByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHandoverImagesByTypeQuery, GetHandoverImagesByTypeQueryVariables>(GetHandoverImagesByTypeDocument, options);
        }
export type GetHandoverImagesByTypeQueryHookResult = ReturnType<typeof useGetHandoverImagesByTypeQuery>;
export type GetHandoverImagesByTypeLazyQueryHookResult = ReturnType<typeof useGetHandoverImagesByTypeLazyQuery>;
export type GetHandoverImagesByTypeQueryResult = Apollo.QueryResult<GetHandoverImagesByTypeQuery, GetHandoverImagesByTypeQueryVariables>;
export const GetHandoverCommmentsDocument = gql`
    query getHandoverCommments($reservationId: String!) {
  getReservation(id: $reservationId) {
    ... on Reservation {
      handover {
        id
        status
        notes {
          edges {
            node {
              id
              createdAt
              updatedAt
              comment
              url
              profile {
                isSelf
                name
                avatar
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHandoverCommmentsQuery__
 *
 * To run a query within a React component, call `useGetHandoverCommmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandoverCommmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandoverCommmentsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetHandoverCommmentsQuery(baseOptions: Apollo.QueryHookOptions<GetHandoverCommmentsQuery, GetHandoverCommmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHandoverCommmentsQuery, GetHandoverCommmentsQueryVariables>(GetHandoverCommmentsDocument, options);
      }
export function useGetHandoverCommmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHandoverCommmentsQuery, GetHandoverCommmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHandoverCommmentsQuery, GetHandoverCommmentsQueryVariables>(GetHandoverCommmentsDocument, options);
        }
export type GetHandoverCommmentsQueryHookResult = ReturnType<typeof useGetHandoverCommmentsQuery>;
export type GetHandoverCommmentsLazyQueryHookResult = ReturnType<typeof useGetHandoverCommmentsLazyQuery>;
export type GetHandoverCommmentsQueryResult = Apollo.QueryResult<GetHandoverCommmentsQuery, GetHandoverCommmentsQueryVariables>;
export const UpdateReservationDatesDocument = gql`
    mutation UpdateReservationDates($reservationId: String!, $input: UpdateReservationDatesInput!) {
  updateReservationDates(reservationId: $reservationId, input: $input) {
    ... on InvalidReservationError {
      errorCode
      message
    }
    ... on Reservation {
      id
      startDate
      endDate
      dates {
        namedMonthStartDate
        namedMonthEndDate
        isoEndDate
        isoStartDate
        startDate
        endDate
        startFullDate
        endFullDate
        formatEndDate
        formatStartDate
      }
    }
  }
}
    `;
export type UpdateReservationDatesMutationFn = Apollo.MutationFunction<UpdateReservationDatesMutation, UpdateReservationDatesMutationVariables>;

/**
 * __useUpdateReservationDatesMutation__
 *
 * To run a mutation, you first call `useUpdateReservationDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationDatesMutation, { data, loading, error }] = useUpdateReservationDatesMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReservationDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationDatesMutation, UpdateReservationDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationDatesMutation, UpdateReservationDatesMutationVariables>(UpdateReservationDatesDocument, options);
      }
export type UpdateReservationDatesMutationHookResult = ReturnType<typeof useUpdateReservationDatesMutation>;
export type UpdateReservationDatesMutationResult = Apollo.MutationResult<UpdateReservationDatesMutation>;
export type UpdateReservationDatesMutationOptions = Apollo.BaseMutationOptions<UpdateReservationDatesMutation, UpdateReservationDatesMutationVariables>;
export const UpdatePaymentDateDocument = gql`
    mutation UpdatePaymentDate($reservationId: String!, $input: UpdatePaymentDateInput!) {
  updatePaymentDate(reservationId: $reservationId, input: $input) {
    ... on InvalidReservationError {
      errorCode
      message
    }
    ... on Reservation {
      id
      paymentDate
    }
  }
}
    `;
export type UpdatePaymentDateMutationFn = Apollo.MutationFunction<UpdatePaymentDateMutation, UpdatePaymentDateMutationVariables>;

/**
 * __useUpdatePaymentDateMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDateMutation, { data, loading, error }] = useUpdatePaymentDateMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDateMutation, UpdatePaymentDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDateMutation, UpdatePaymentDateMutationVariables>(UpdatePaymentDateDocument, options);
      }
export type UpdatePaymentDateMutationHookResult = ReturnType<typeof useUpdatePaymentDateMutation>;
export type UpdatePaymentDateMutationResult = Apollo.MutationResult<UpdatePaymentDateMutation>;
export type UpdatePaymentDateMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDateMutation, UpdatePaymentDateMutationVariables>;
export const GetOwnersReviewsDocument = gql`
    query GetOwnersReviews($userId: String!, $first: Int, $after: String, $last: Int, $before: String) {
  getOwnersReviews(
    userId: $userId
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    pageInfo {
      ...PageInfo
    }
    aggregate {
      count
    }
    edges {
      node {
        id
        reservationId
        createdAt
        updatedAt
        comment
        note
        rating
        profile {
          name
          avatar
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useGetOwnersReviewsQuery__
 *
 * To run a query within a React component, call `useGetOwnersReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersReviewsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetOwnersReviewsQuery(baseOptions: Apollo.QueryHookOptions<GetOwnersReviewsQuery, GetOwnersReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnersReviewsQuery, GetOwnersReviewsQueryVariables>(GetOwnersReviewsDocument, options);
      }
export function useGetOwnersReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnersReviewsQuery, GetOwnersReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnersReviewsQuery, GetOwnersReviewsQueryVariables>(GetOwnersReviewsDocument, options);
        }
export type GetOwnersReviewsQueryHookResult = ReturnType<typeof useGetOwnersReviewsQuery>;
export type GetOwnersReviewsLazyQueryHookResult = ReturnType<typeof useGetOwnersReviewsLazyQuery>;
export type GetOwnersReviewsQueryResult = Apollo.QueryResult<GetOwnersReviewsQuery, GetOwnersReviewsQueryVariables>;
export const GetReviewsToUserDocument = gql`
    query GetReviewsToUser($userId: String!, $first: Int, $after: String, $last: Int, $before: String) {
  getReviewsToUser(
    userId: $userId
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    pageInfo {
      ...PageInfo
    }
    aggregate {
      count
    }
    edges {
      node {
        id
        reservationId
        createdAt
        updatedAt
        comment
        note
        rating
        profile {
          name
          avatar
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useGetReviewsToUserQuery__
 *
 * To run a query within a React component, call `useGetReviewsToUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewsToUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewsToUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetReviewsToUserQuery(baseOptions: Apollo.QueryHookOptions<GetReviewsToUserQuery, GetReviewsToUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewsToUserQuery, GetReviewsToUserQueryVariables>(GetReviewsToUserDocument, options);
      }
export function useGetReviewsToUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewsToUserQuery, GetReviewsToUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewsToUserQuery, GetReviewsToUserQueryVariables>(GetReviewsToUserDocument, options);
        }
export type GetReviewsToUserQueryHookResult = ReturnType<typeof useGetReviewsToUserQuery>;
export type GetReviewsToUserLazyQueryHookResult = ReturnType<typeof useGetReviewsToUserLazyQuery>;
export type GetReviewsToUserQueryResult = Apollo.QueryResult<GetReviewsToUserQuery, GetReviewsToUserQueryVariables>;
export const DeleteReviewDocument = gql`
    mutation DeleteReview($id: String!, $reservationId: String!) {
  deleteReview(id: $id, reservationId: $reservationId) {
    id
  }
}
    `;
export type DeleteReviewMutationFn = Apollo.MutationFunction<DeleteReviewMutation, DeleteReviewMutationVariables>;

/**
 * __useDeleteReviewMutation__
 *
 * To run a mutation, you first call `useDeleteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewMutation, { data, loading, error }] = useDeleteReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useDeleteReviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReviewMutation, DeleteReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReviewMutation, DeleteReviewMutationVariables>(DeleteReviewDocument, options);
      }
export type DeleteReviewMutationHookResult = ReturnType<typeof useDeleteReviewMutation>;
export type DeleteReviewMutationResult = Apollo.MutationResult<DeleteReviewMutation>;
export type DeleteReviewMutationOptions = Apollo.BaseMutationOptions<DeleteReviewMutation, DeleteReviewMutationVariables>;
export const CreateDisputeDocument = gql`
    mutation CreateDispute($input: CreateDisputeInput!, $handoverId: String!) {
  createDispute(input: $input, handoverId: $handoverId) {
    id
    url
  }
}
    `;
export type CreateDisputeMutationFn = Apollo.MutationFunction<CreateDisputeMutation, CreateDisputeMutationVariables>;

/**
 * __useCreateDisputeMutation__
 *
 * To run a mutation, you first call `useCreateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeMutation, { data, loading, error }] = useCreateDisputeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      handoverId: // value for 'handoverId'
 *   },
 * });
 */
export function useCreateDisputeMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisputeMutation, CreateDisputeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisputeMutation, CreateDisputeMutationVariables>(CreateDisputeDocument, options);
      }
export type CreateDisputeMutationHookResult = ReturnType<typeof useCreateDisputeMutation>;
export type CreateDisputeMutationResult = Apollo.MutationResult<CreateDisputeMutation>;
export type CreateDisputeMutationOptions = Apollo.BaseMutationOptions<CreateDisputeMutation, CreateDisputeMutationVariables>;
export const GetAccountingCodesDocument = gql`
    query GetAccountingCodes($input: AccountingCodeFilterInput) {
  getAccountingCodes(input: $input) {
    ...AccountingCodeFields
  }
}
    ${AccountingCodeFieldsFragmentDoc}`;

/**
 * __useGetAccountingCodesQuery__
 *
 * To run a query within a React component, call `useGetAccountingCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountingCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountingCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountingCodesQuery, GetAccountingCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountingCodesQuery, GetAccountingCodesQueryVariables>(GetAccountingCodesDocument, options);
      }
export function useGetAccountingCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountingCodesQuery, GetAccountingCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountingCodesQuery, GetAccountingCodesQueryVariables>(GetAccountingCodesDocument, options);
        }
export type GetAccountingCodesQueryHookResult = ReturnType<typeof useGetAccountingCodesQuery>;
export type GetAccountingCodesLazyQueryHookResult = ReturnType<typeof useGetAccountingCodesLazyQuery>;
export type GetAccountingCodesQueryResult = Apollo.QueryResult<GetAccountingCodesQuery, GetAccountingCodesQueryVariables>;
export const CreateAccountingCodeDocument = gql`
    mutation CreateAccountingCode($input: CreateAccountingCodeInput!) {
  createAccountingCode(input: $input) {
    ...AccountingCodeFields
  }
}
    ${AccountingCodeFieldsFragmentDoc}`;
export type CreateAccountingCodeMutationFn = Apollo.MutationFunction<CreateAccountingCodeMutation, CreateAccountingCodeMutationVariables>;

/**
 * __useCreateAccountingCodeMutation__
 *
 * To run a mutation, you first call `useCreateAccountingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountingCodeMutation, { data, loading, error }] = useCreateAccountingCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountingCodeMutation, CreateAccountingCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountingCodeMutation, CreateAccountingCodeMutationVariables>(CreateAccountingCodeDocument, options);
      }
export type CreateAccountingCodeMutationHookResult = ReturnType<typeof useCreateAccountingCodeMutation>;
export type CreateAccountingCodeMutationResult = Apollo.MutationResult<CreateAccountingCodeMutation>;
export type CreateAccountingCodeMutationOptions = Apollo.BaseMutationOptions<CreateAccountingCodeMutation, CreateAccountingCodeMutationVariables>;
export const DeleteAccountingCodeDocument = gql`
    mutation DeleteAccountingCode($id: String!) {
  deleteAccountingCode(id: $id)
}
    `;
export type DeleteAccountingCodeMutationFn = Apollo.MutationFunction<DeleteAccountingCodeMutation, DeleteAccountingCodeMutationVariables>;

/**
 * __useDeleteAccountingCodeMutation__
 *
 * To run a mutation, you first call `useDeleteAccountingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountingCodeMutation, { data, loading, error }] = useDeleteAccountingCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountingCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountingCodeMutation, DeleteAccountingCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountingCodeMutation, DeleteAccountingCodeMutationVariables>(DeleteAccountingCodeDocument, options);
      }
export type DeleteAccountingCodeMutationHookResult = ReturnType<typeof useDeleteAccountingCodeMutation>;
export type DeleteAccountingCodeMutationResult = Apollo.MutationResult<DeleteAccountingCodeMutation>;
export type DeleteAccountingCodeMutationOptions = Apollo.BaseMutationOptions<DeleteAccountingCodeMutation, DeleteAccountingCodeMutationVariables>;
export const UpdateAccountingCodeDocument = gql`
    mutation UpdateAccountingCode($id: String!, $input: UpdateAccountingCodeInput!) {
  updateAccountingCode(id: $id, input: $input) {
    ...AccountingCodeFields
  }
}
    ${AccountingCodeFieldsFragmentDoc}`;
export type UpdateAccountingCodeMutationFn = Apollo.MutationFunction<UpdateAccountingCodeMutation, UpdateAccountingCodeMutationVariables>;

/**
 * __useUpdateAccountingCodeMutation__
 *
 * To run a mutation, you first call `useUpdateAccountingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountingCodeMutation, { data, loading, error }] = useUpdateAccountingCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountingCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountingCodeMutation, UpdateAccountingCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountingCodeMutation, UpdateAccountingCodeMutationVariables>(UpdateAccountingCodeDocument, options);
      }
export type UpdateAccountingCodeMutationHookResult = ReturnType<typeof useUpdateAccountingCodeMutation>;
export type UpdateAccountingCodeMutationResult = Apollo.MutationResult<UpdateAccountingCodeMutation>;
export type UpdateAccountingCodeMutationOptions = Apollo.BaseMutationOptions<UpdateAccountingCodeMutation, UpdateAccountingCodeMutationVariables>;
export const GetReservationOrderLineItemsDocument = gql`
    query GetReservationOrderLineItems($reservationId: String!) {
  getReservationLineItems(reservationId: $reservationId) {
    ...OrderLineItemFields
  }
}
    ${OrderLineItemFieldsFragmentDoc}`;

/**
 * __useGetReservationOrderLineItemsQuery__
 *
 * To run a query within a React component, call `useGetReservationOrderLineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationOrderLineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationOrderLineItemsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationOrderLineItemsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationOrderLineItemsQuery, GetReservationOrderLineItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationOrderLineItemsQuery, GetReservationOrderLineItemsQueryVariables>(GetReservationOrderLineItemsDocument, options);
      }
export function useGetReservationOrderLineItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationOrderLineItemsQuery, GetReservationOrderLineItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationOrderLineItemsQuery, GetReservationOrderLineItemsQueryVariables>(GetReservationOrderLineItemsDocument, options);
        }
export type GetReservationOrderLineItemsQueryHookResult = ReturnType<typeof useGetReservationOrderLineItemsQuery>;
export type GetReservationOrderLineItemsLazyQueryHookResult = ReturnType<typeof useGetReservationOrderLineItemsLazyQuery>;
export type GetReservationOrderLineItemsQueryResult = Apollo.QueryResult<GetReservationOrderLineItemsQuery, GetReservationOrderLineItemsQueryVariables>;
export const GetUserCardsDocument = gql`
    query GetUserCards($userId: String!) {
  getUserCards(userId: $userId) {
    ...CardTokenFields
  }
}
    ${CardTokenFieldsFragmentDoc}`;

/**
 * __useGetUserCardsQuery__
 *
 * To run a query within a React component, call `useGetUserCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCardsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCardsQuery(baseOptions: Apollo.QueryHookOptions<GetUserCardsQuery, GetUserCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCardsQuery, GetUserCardsQueryVariables>(GetUserCardsDocument, options);
      }
export function useGetUserCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCardsQuery, GetUserCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCardsQuery, GetUserCardsQueryVariables>(GetUserCardsDocument, options);
        }
export type GetUserCardsQueryHookResult = ReturnType<typeof useGetUserCardsQuery>;
export type GetUserCardsLazyQueryHookResult = ReturnType<typeof useGetUserCardsLazyQuery>;
export type GetUserCardsQueryResult = Apollo.QueryResult<GetUserCardsQuery, GetUserCardsQueryVariables>;
export const AddOrderLineItemDocument = gql`
    mutation AddOrderLineItem($reservationId: String!, $input: AddReservationLineItemInput!) {
  addOrderLineItem(reservationId: $reservationId, input: $input) {
    ...OrderLineItemFields
  }
}
    ${OrderLineItemFieldsFragmentDoc}`;
export type AddOrderLineItemMutationFn = Apollo.MutationFunction<AddOrderLineItemMutation, AddOrderLineItemMutationVariables>;

/**
 * __useAddOrderLineItemMutation__
 *
 * To run a mutation, you first call `useAddOrderLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderLineItemMutation, { data, loading, error }] = useAddOrderLineItemMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderLineItemMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderLineItemMutation, AddOrderLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderLineItemMutation, AddOrderLineItemMutationVariables>(AddOrderLineItemDocument, options);
      }
export type AddOrderLineItemMutationHookResult = ReturnType<typeof useAddOrderLineItemMutation>;
export type AddOrderLineItemMutationResult = Apollo.MutationResult<AddOrderLineItemMutation>;
export type AddOrderLineItemMutationOptions = Apollo.BaseMutationOptions<AddOrderLineItemMutation, AddOrderLineItemMutationVariables>;
export const UpdateOrderLineItemDocument = gql`
    mutation UpdateOrderLineItem($id: String!, $input: UpdateReservationLineItemInput!) {
  updateOrderLineItem(id: $id, input: $input) {
    ...OrderLineItemFields
  }
}
    ${OrderLineItemFieldsFragmentDoc}`;
export type UpdateOrderLineItemMutationFn = Apollo.MutationFunction<UpdateOrderLineItemMutation, UpdateOrderLineItemMutationVariables>;

/**
 * __useUpdateOrderLineItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderLineItemMutation, { data, loading, error }] = useUpdateOrderLineItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderLineItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderLineItemMutation, UpdateOrderLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderLineItemMutation, UpdateOrderLineItemMutationVariables>(UpdateOrderLineItemDocument, options);
      }
export type UpdateOrderLineItemMutationHookResult = ReturnType<typeof useUpdateOrderLineItemMutation>;
export type UpdateOrderLineItemMutationResult = Apollo.MutationResult<UpdateOrderLineItemMutation>;
export type UpdateOrderLineItemMutationOptions = Apollo.BaseMutationOptions<UpdateOrderLineItemMutation, UpdateOrderLineItemMutationVariables>;
export const DeleteOrderLineItemDocument = gql`
    mutation DeleteOrderLineItem($id: String!) {
  deleteOrderLineItem(id: $id)
}
    `;
export type DeleteOrderLineItemMutationFn = Apollo.MutationFunction<DeleteOrderLineItemMutation, DeleteOrderLineItemMutationVariables>;

/**
 * __useDeleteOrderLineItemMutation__
 *
 * To run a mutation, you first call `useDeleteOrderLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderLineItemMutation, { data, loading, error }] = useDeleteOrderLineItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderLineItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderLineItemMutation, DeleteOrderLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderLineItemMutation, DeleteOrderLineItemMutationVariables>(DeleteOrderLineItemDocument, options);
      }
export type DeleteOrderLineItemMutationHookResult = ReturnType<typeof useDeleteOrderLineItemMutation>;
export type DeleteOrderLineItemMutationResult = Apollo.MutationResult<DeleteOrderLineItemMutation>;
export type DeleteOrderLineItemMutationOptions = Apollo.BaseMutationOptions<DeleteOrderLineItemMutation, DeleteOrderLineItemMutationVariables>;
export const RefundByReservationIdDocument = gql`
    mutation RefundByReservationId($id: String!, $transactionId: String!, $amount: Float!) {
  refundByReservationId(id: $id, transactionId: $transactionId, amount: $amount) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type RefundByReservationIdMutationFn = Apollo.MutationFunction<RefundByReservationIdMutation, RefundByReservationIdMutationVariables>;

/**
 * __useRefundByReservationIdMutation__
 *
 * To run a mutation, you first call `useRefundByReservationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundByReservationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundByReservationIdMutation, { data, loading, error }] = useRefundByReservationIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transactionId: // value for 'transactionId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRefundByReservationIdMutation(baseOptions?: Apollo.MutationHookOptions<RefundByReservationIdMutation, RefundByReservationIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundByReservationIdMutation, RefundByReservationIdMutationVariables>(RefundByReservationIdDocument, options);
      }
export type RefundByReservationIdMutationHookResult = ReturnType<typeof useRefundByReservationIdMutation>;
export type RefundByReservationIdMutationResult = Apollo.MutationResult<RefundByReservationIdMutation>;
export type RefundByReservationIdMutationOptions = Apollo.BaseMutationOptions<RefundByReservationIdMutation, RefundByReservationIdMutationVariables>;
export const ChargeCardByReservationIdDocument = gql`
    mutation ChargeCardByReservationId($amount: Float!, $cardId: String!, $id: String!) {
  chargeCardByReservationId(id: $id, cardId: $cardId, amount: $amount) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type ChargeCardByReservationIdMutationFn = Apollo.MutationFunction<ChargeCardByReservationIdMutation, ChargeCardByReservationIdMutationVariables>;

/**
 * __useChargeCardByReservationIdMutation__
 *
 * To run a mutation, you first call `useChargeCardByReservationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeCardByReservationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeCardByReservationIdMutation, { data, loading, error }] = useChargeCardByReservationIdMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      cardId: // value for 'cardId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChargeCardByReservationIdMutation(baseOptions?: Apollo.MutationHookOptions<ChargeCardByReservationIdMutation, ChargeCardByReservationIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChargeCardByReservationIdMutation, ChargeCardByReservationIdMutationVariables>(ChargeCardByReservationIdDocument, options);
      }
export type ChargeCardByReservationIdMutationHookResult = ReturnType<typeof useChargeCardByReservationIdMutation>;
export type ChargeCardByReservationIdMutationResult = Apollo.MutationResult<ChargeCardByReservationIdMutation>;
export type ChargeCardByReservationIdMutationOptions = Apollo.BaseMutationOptions<ChargeCardByReservationIdMutation, ChargeCardByReservationIdMutationVariables>;
export const FindPlacesDocument = gql`
    query FindPlaces {
  findPlaces {
    ...Places
  }
}
    ${PlacesFragmentDoc}`;

/**
 * __useFindPlacesQuery__
 *
 * To run a query within a React component, call `useFindPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPlacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindPlacesQuery(baseOptions?: Apollo.QueryHookOptions<FindPlacesQuery, FindPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPlacesQuery, FindPlacesQueryVariables>(FindPlacesDocument, options);
      }
export function useFindPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPlacesQuery, FindPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPlacesQuery, FindPlacesQueryVariables>(FindPlacesDocument, options);
        }
export type FindPlacesQueryHookResult = ReturnType<typeof useFindPlacesQuery>;
export type FindPlacesLazyQueryHookResult = ReturnType<typeof useFindPlacesLazyQuery>;
export type FindPlacesQueryResult = Apollo.QueryResult<FindPlacesQuery, FindPlacesQueryVariables>;
export const FindPlaceDocument = gql`
    query FindPlace($id: String!) {
  findPlace(id: $id) {
    ...Places
  }
}
    ${PlacesFragmentDoc}`;

/**
 * __useFindPlaceQuery__
 *
 * To run a query within a React component, call `useFindPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPlaceQuery(baseOptions: Apollo.QueryHookOptions<FindPlaceQuery, FindPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPlaceQuery, FindPlaceQueryVariables>(FindPlaceDocument, options);
      }
export function useFindPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPlaceQuery, FindPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPlaceQuery, FindPlaceQueryVariables>(FindPlaceDocument, options);
        }
export type FindPlaceQueryHookResult = ReturnType<typeof useFindPlaceQuery>;
export type FindPlaceLazyQueryHookResult = ReturnType<typeof useFindPlaceLazyQuery>;
export type FindPlaceQueryResult = Apollo.QueryResult<FindPlaceQuery, FindPlaceQueryVariables>;
export const CreatePlaceDocument = gql`
    mutation CreatePlace($input: CreatePlaceInput!) {
  createPlace(input: $input) {
    ...Places
  }
}
    ${PlacesFragmentDoc}`;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;
export const UpdatePlaceDocument = gql`
    mutation updatePlace($input: UpdatePlaceInput!, $id: String!) {
  updatePlace(id: $id, input: $input) {
    ...Places
  }
}
    ${PlacesFragmentDoc}`;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const DeletePlaceDocument = gql`
    mutation DeletePlace($id: String!) {
  deletePlace(id: $id)
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, options);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const GetVehiclePlacesDocument = gql`
    query GetVehiclePlaces($vehicleId: String!) {
  vehiclePlaces(vehicleId: $vehicleId) {
    ...VehiclePlaceFields
  }
}
    ${VehiclePlaceFieldsFragmentDoc}`;

/**
 * __useGetVehiclePlacesQuery__
 *
 * To run a query within a React component, call `useGetVehiclePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclePlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclePlacesQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetVehiclePlacesQuery(baseOptions: Apollo.QueryHookOptions<GetVehiclePlacesQuery, GetVehiclePlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclePlacesQuery, GetVehiclePlacesQueryVariables>(GetVehiclePlacesDocument, options);
      }
export function useGetVehiclePlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclePlacesQuery, GetVehiclePlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclePlacesQuery, GetVehiclePlacesQueryVariables>(GetVehiclePlacesDocument, options);
        }
export type GetVehiclePlacesQueryHookResult = ReturnType<typeof useGetVehiclePlacesQuery>;
export type GetVehiclePlacesLazyQueryHookResult = ReturnType<typeof useGetVehiclePlacesLazyQuery>;
export type GetVehiclePlacesQueryResult = Apollo.QueryResult<GetVehiclePlacesQuery, GetVehiclePlacesQueryVariables>;
export const AddVehiclePlaceDocument = gql`
    mutation AddVehiclePlace($vehicleId: String!, $input: CreateVehiclePlaceInput!) {
  createVehiclePlace(vehicleId: $vehicleId, input: $input) {
    ...VehiclePlaceFields
  }
}
    ${VehiclePlaceFieldsFragmentDoc}`;
export type AddVehiclePlaceMutationFn = Apollo.MutationFunction<AddVehiclePlaceMutation, AddVehiclePlaceMutationVariables>;

/**
 * __useAddVehiclePlaceMutation__
 *
 * To run a mutation, you first call `useAddVehiclePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehiclePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehiclePlaceMutation, { data, loading, error }] = useAddVehiclePlaceMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVehiclePlaceMutation(baseOptions?: Apollo.MutationHookOptions<AddVehiclePlaceMutation, AddVehiclePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVehiclePlaceMutation, AddVehiclePlaceMutationVariables>(AddVehiclePlaceDocument, options);
      }
export type AddVehiclePlaceMutationHookResult = ReturnType<typeof useAddVehiclePlaceMutation>;
export type AddVehiclePlaceMutationResult = Apollo.MutationResult<AddVehiclePlaceMutation>;
export type AddVehiclePlaceMutationOptions = Apollo.BaseMutationOptions<AddVehiclePlaceMutation, AddVehiclePlaceMutationVariables>;
export const UpdateVehiclePlaceDocument = gql`
    mutation UpdateVehiclePlace($id: String!, $vehicleId: String!, $input: UpdateVehiclePlaceInput!) {
  updateVehiclePlace(id: $id, vehicleId: $vehicleId, input: $input) {
    ...VehiclePlaceFields
  }
}
    ${VehiclePlaceFieldsFragmentDoc}`;
export type UpdateVehiclePlaceMutationFn = Apollo.MutationFunction<UpdateVehiclePlaceMutation, UpdateVehiclePlaceMutationVariables>;

/**
 * __useUpdateVehiclePlaceMutation__
 *
 * To run a mutation, you first call `useUpdateVehiclePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehiclePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehiclePlaceMutation, { data, loading, error }] = useUpdateVehiclePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vehicleId: // value for 'vehicleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehiclePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehiclePlaceMutation, UpdateVehiclePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehiclePlaceMutation, UpdateVehiclePlaceMutationVariables>(UpdateVehiclePlaceDocument, options);
      }
export type UpdateVehiclePlaceMutationHookResult = ReturnType<typeof useUpdateVehiclePlaceMutation>;
export type UpdateVehiclePlaceMutationResult = Apollo.MutationResult<UpdateVehiclePlaceMutation>;
export type UpdateVehiclePlaceMutationOptions = Apollo.BaseMutationOptions<UpdateVehiclePlaceMutation, UpdateVehiclePlaceMutationVariables>;
export const RemoveVehiclePlaceDocument = gql`
    mutation RemoveVehiclePlace($id: String!, $vehicleId: String!) {
  removeVehiclePlace(id: $id, vehicleId: $vehicleId)
}
    `;
export type RemoveVehiclePlaceMutationFn = Apollo.MutationFunction<RemoveVehiclePlaceMutation, RemoveVehiclePlaceMutationVariables>;

/**
 * __useRemoveVehiclePlaceMutation__
 *
 * To run a mutation, you first call `useRemoveVehiclePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVehiclePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVehiclePlaceMutation, { data, loading, error }] = useRemoveVehiclePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useRemoveVehiclePlaceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVehiclePlaceMutation, RemoveVehiclePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVehiclePlaceMutation, RemoveVehiclePlaceMutationVariables>(RemoveVehiclePlaceDocument, options);
      }
export type RemoveVehiclePlaceMutationHookResult = ReturnType<typeof useRemoveVehiclePlaceMutation>;
export type RemoveVehiclePlaceMutationResult = Apollo.MutationResult<RemoveVehiclePlaceMutation>;
export type RemoveVehiclePlaceMutationOptions = Apollo.BaseMutationOptions<RemoveVehiclePlaceMutation, RemoveVehiclePlaceMutationVariables>;
export const CreateVehiclePlaceByPlaceDocument = gql`
    mutation CreateVehiclePlaceByPlace($vehicleId: String!, $placeId: String!) {
  createVehiclePlaceByPlaceId(vehicleId: $vehicleId, placeId: $placeId) {
    ...VehiclePlaceFields
  }
}
    ${VehiclePlaceFieldsFragmentDoc}`;
export type CreateVehiclePlaceByPlaceMutationFn = Apollo.MutationFunction<CreateVehiclePlaceByPlaceMutation, CreateVehiclePlaceByPlaceMutationVariables>;

/**
 * __useCreateVehiclePlaceByPlaceMutation__
 *
 * To run a mutation, you first call `useCreateVehiclePlaceByPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehiclePlaceByPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehiclePlaceByPlaceMutation, { data, loading, error }] = useCreateVehiclePlaceByPlaceMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useCreateVehiclePlaceByPlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehiclePlaceByPlaceMutation, CreateVehiclePlaceByPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehiclePlaceByPlaceMutation, CreateVehiclePlaceByPlaceMutationVariables>(CreateVehiclePlaceByPlaceDocument, options);
      }
export type CreateVehiclePlaceByPlaceMutationHookResult = ReturnType<typeof useCreateVehiclePlaceByPlaceMutation>;
export type CreateVehiclePlaceByPlaceMutationResult = Apollo.MutationResult<CreateVehiclePlaceByPlaceMutation>;
export type CreateVehiclePlaceByPlaceMutationOptions = Apollo.BaseMutationOptions<CreateVehiclePlaceByPlaceMutation, CreateVehiclePlaceByPlaceMutationVariables>;
export const SendPaymentLinkDocument = gql`
    mutation SendPaymentLink($id: String!) {
  sendPaymentLink(id: $id) {
    success
    message
  }
}
    `;
export type SendPaymentLinkMutationFn = Apollo.MutationFunction<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>;

/**
 * __useSendPaymentLinkMutation__
 *
 * To run a mutation, you first call `useSendPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPaymentLinkMutation, { data, loading, error }] = useSendPaymentLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>(SendPaymentLinkDocument, options);
      }
export type SendPaymentLinkMutationHookResult = ReturnType<typeof useSendPaymentLinkMutation>;
export type SendPaymentLinkMutationResult = Apollo.MutationResult<SendPaymentLinkMutation>;
export type SendPaymentLinkMutationOptions = Apollo.BaseMutationOptions<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>;
export const AddReservationTransactionDocument = gql`
    mutation AddReservationTransaction($input: AddReservationTransactionInput!, $id: String!) {
  addReservationTransaction(input: $input, id: $id) {
    success
    message
  }
}
    `;
export type AddReservationTransactionMutationFn = Apollo.MutationFunction<AddReservationTransactionMutation, AddReservationTransactionMutationVariables>;

/**
 * __useAddReservationTransactionMutation__
 *
 * To run a mutation, you first call `useAddReservationTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReservationTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReservationTransactionMutation, { data, loading, error }] = useAddReservationTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddReservationTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AddReservationTransactionMutation, AddReservationTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReservationTransactionMutation, AddReservationTransactionMutationVariables>(AddReservationTransactionDocument, options);
      }
export type AddReservationTransactionMutationHookResult = ReturnType<typeof useAddReservationTransactionMutation>;
export type AddReservationTransactionMutationResult = Apollo.MutationResult<AddReservationTransactionMutation>;
export type AddReservationTransactionMutationOptions = Apollo.BaseMutationOptions<AddReservationTransactionMutation, AddReservationTransactionMutationVariables>;
export const ReferFriendsDocument = gql`
    mutation ReferFriends($input: ReferFriendsInput!) {
  referFriends(input: $input)
}
    `;
export type ReferFriendsMutationFn = Apollo.MutationFunction<ReferFriendsMutation, ReferFriendsMutationVariables>;

/**
 * __useReferFriendsMutation__
 *
 * To run a mutation, you first call `useReferFriendsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferFriendsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referFriendsMutation, { data, loading, error }] = useReferFriendsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferFriendsMutation(baseOptions?: Apollo.MutationHookOptions<ReferFriendsMutation, ReferFriendsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReferFriendsMutation, ReferFriendsMutationVariables>(ReferFriendsDocument, options);
      }
export type ReferFriendsMutationHookResult = ReturnType<typeof useReferFriendsMutation>;
export type ReferFriendsMutationResult = Apollo.MutationResult<ReferFriendsMutation>;
export type ReferFriendsMutationOptions = Apollo.BaseMutationOptions<ReferFriendsMutation, ReferFriendsMutationVariables>;
export const GetCouponDocument = gql`
    query GetCoupon($id: String!) {
  coupon(id: $id) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

/**
 * __useGetCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCouponQuery(baseOptions: Apollo.QueryHookOptions<GetCouponQuery, GetCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
      }
export function useGetCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponQuery, GetCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
        }
export type GetCouponQueryHookResult = ReturnType<typeof useGetCouponQuery>;
export type GetCouponLazyQueryHookResult = ReturnType<typeof useGetCouponLazyQuery>;
export type GetCouponQueryResult = Apollo.QueryResult<GetCouponQuery, GetCouponQueryVariables>;
export const GetAllCouponDocument = gql`
    query GetAllCoupon {
  coupons {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

/**
 * __useGetAllCouponQuery__
 *
 * To run a query within a React component, call `useGetAllCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCouponQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCouponQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCouponQuery, GetAllCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCouponQuery, GetAllCouponQueryVariables>(GetAllCouponDocument, options);
      }
export function useGetAllCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCouponQuery, GetAllCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCouponQuery, GetAllCouponQueryVariables>(GetAllCouponDocument, options);
        }
export type GetAllCouponQueryHookResult = ReturnType<typeof useGetAllCouponQuery>;
export type GetAllCouponLazyQueryHookResult = ReturnType<typeof useGetAllCouponLazyQuery>;
export type GetAllCouponQueryResult = Apollo.QueryResult<GetAllCouponQuery, GetAllCouponQueryVariables>;
export const CouponConditionsAndActionsDocument = gql`
    query CouponConditionsAndActions {
  couponConditions {
    code
    description
    args {
      name
      type
      required
      description
    }
  }
  couponActions {
    code
    description
    args {
      name
      type
      required
      description
    }
  }
}
    `;

/**
 * __useCouponConditionsAndActionsQuery__
 *
 * To run a query within a React component, call `useCouponConditionsAndActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponConditionsAndActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponConditionsAndActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCouponConditionsAndActionsQuery(baseOptions?: Apollo.QueryHookOptions<CouponConditionsAndActionsQuery, CouponConditionsAndActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponConditionsAndActionsQuery, CouponConditionsAndActionsQueryVariables>(CouponConditionsAndActionsDocument, options);
      }
export function useCouponConditionsAndActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponConditionsAndActionsQuery, CouponConditionsAndActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponConditionsAndActionsQuery, CouponConditionsAndActionsQueryVariables>(CouponConditionsAndActionsDocument, options);
        }
export type CouponConditionsAndActionsQueryHookResult = ReturnType<typeof useCouponConditionsAndActionsQuery>;
export type CouponConditionsAndActionsLazyQueryHookResult = ReturnType<typeof useCouponConditionsAndActionsLazyQuery>;
export type CouponConditionsAndActionsQueryResult = Apollo.QueryResult<CouponConditionsAndActionsQuery, CouponConditionsAndActionsQueryVariables>;
export const CreateCouponDocument = gql`
    mutation CreateCoupon($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation UpdateCoupon($input: UpdateCouponInput!, $id: String!) {
  updateCoupon(id: $id, input: $input) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const RemoveCouponDocument = gql`
    mutation RemoveCoupon($id: String!) {
  removeCoupon(id: $id)
}
    `;
export type RemoveCouponMutationFn = Apollo.MutationFunction<RemoveCouponMutation, RemoveCouponMutationVariables>;

/**
 * __useRemoveCouponMutation__
 *
 * To run a mutation, you first call `useRemoveCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponMutation, { data, loading, error }] = useRemoveCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCouponMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCouponMutation, RemoveCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCouponMutation, RemoveCouponMutationVariables>(RemoveCouponDocument, options);
      }
export type RemoveCouponMutationHookResult = ReturnType<typeof useRemoveCouponMutation>;
export type RemoveCouponMutationResult = Apollo.MutationResult<RemoveCouponMutation>;
export type RemoveCouponMutationOptions = Apollo.BaseMutationOptions<RemoveCouponMutation, RemoveCouponMutationVariables>;
export const ApplyCouponCodeDocument = gql`
    mutation ApplyCouponCode($id: String!, $couponCode: String!) {
  applyCouponCode(id: $id, couponCode: $couponCode) {
    __typename
    ... on Reservation {
      id
    }
    ... on CouponCodeInvalidError {
      errorCode
      message
    }
  }
}
    `;
export type ApplyCouponCodeMutationFn = Apollo.MutationFunction<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>;

/**
 * __useApplyCouponCodeMutation__
 *
 * To run a mutation, you first call `useApplyCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponCodeMutation, { data, loading, error }] = useApplyCouponCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useApplyCouponCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>(ApplyCouponCodeDocument, options);
      }
export type ApplyCouponCodeMutationHookResult = ReturnType<typeof useApplyCouponCodeMutation>;
export type ApplyCouponCodeMutationResult = Apollo.MutationResult<ApplyCouponCodeMutation>;
export type ApplyCouponCodeMutationOptions = Apollo.BaseMutationOptions<ApplyCouponCodeMutation, ApplyCouponCodeMutationVariables>;
export const RemoveCouponCodeDocument = gql`
    mutation RemoveCouponCode($id: String!, $couponCode: String!) {
  removeCouponCode(id: $id, couponCode: $couponCode) {
    __typename
    ... on Reservation {
      id
    }
  }
}
    `;
export type RemoveCouponCodeMutationFn = Apollo.MutationFunction<RemoveCouponCodeMutation, RemoveCouponCodeMutationVariables>;

/**
 * __useRemoveCouponCodeMutation__
 *
 * To run a mutation, you first call `useRemoveCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponCodeMutation, { data, loading, error }] = useRemoveCouponCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useRemoveCouponCodeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCouponCodeMutation, RemoveCouponCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCouponCodeMutation, RemoveCouponCodeMutationVariables>(RemoveCouponCodeDocument, options);
      }
export type RemoveCouponCodeMutationHookResult = ReturnType<typeof useRemoveCouponCodeMutation>;
export type RemoveCouponCodeMutationResult = Apollo.MutationResult<RemoveCouponCodeMutation>;
export type RemoveCouponCodeMutationOptions = Apollo.BaseMutationOptions<RemoveCouponCodeMutation, RemoveCouponCodeMutationVariables>;
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods($id: String!) {
  getPaymentMethods(id: $id) {
    name
    description
    code
    channels
    amount {
      formatted
      amountFloat
    }
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export const UpdateReservationStatusDocument = gql`
    mutation UpdateReservationStatus($id: String!, $input: ReservationStatusUpdateInput!) {
  updateReservationStatus(id: $id, input: $input) {
    id
    status
  }
}
    `;
export type UpdateReservationStatusMutationFn = Apollo.MutationFunction<UpdateReservationStatusMutation, UpdateReservationStatusMutationVariables>;

/**
 * __useUpdateReservationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateReservationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationStatusMutation, { data, loading, error }] = useUpdateReservationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReservationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationStatusMutation, UpdateReservationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationStatusMutation, UpdateReservationStatusMutationVariables>(UpdateReservationStatusDocument, options);
      }
export type UpdateReservationStatusMutationHookResult = ReturnType<typeof useUpdateReservationStatusMutation>;
export type UpdateReservationStatusMutationResult = Apollo.MutationResult<UpdateReservationStatusMutation>;
export type UpdateReservationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateReservationStatusMutation, UpdateReservationStatusMutationVariables>;
export const ChangeReservationVehicleDocument = gql`
    mutation ChangeReservationVehicle($id: String!, $input: ReservationVehicleChangeInput!) {
  changeReservationVehicle(id: $id, input: $input) {
    id
    vehicle {
      id
    }
  }
}
    `;
export type ChangeReservationVehicleMutationFn = Apollo.MutationFunction<ChangeReservationVehicleMutation, ChangeReservationVehicleMutationVariables>;

/**
 * __useChangeReservationVehicleMutation__
 *
 * To run a mutation, you first call `useChangeReservationVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReservationVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReservationVehicleMutation, { data, loading, error }] = useChangeReservationVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeReservationVehicleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeReservationVehicleMutation, ChangeReservationVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeReservationVehicleMutation, ChangeReservationVehicleMutationVariables>(ChangeReservationVehicleDocument, options);
      }
export type ChangeReservationVehicleMutationHookResult = ReturnType<typeof useChangeReservationVehicleMutation>;
export type ChangeReservationVehicleMutationResult = Apollo.MutationResult<ChangeReservationVehicleMutation>;
export type ChangeReservationVehicleMutationOptions = Apollo.BaseMutationOptions<ChangeReservationVehicleMutation, ChangeReservationVehicleMutationVariables>;
export const SetReservationMethodDocument = gql`
    mutation setReservationMethod($id: String!, $code: String!) {
  setPaymentMethod(id: $id, code: $code) {
    __typename
    ... on Reservation {
      ...ReservationFields
    }
  }
}
    ${ReservationFieldsFragmentDoc}`;
export type SetReservationMethodMutationFn = Apollo.MutationFunction<SetReservationMethodMutation, SetReservationMethodMutationVariables>;

/**
 * __useSetReservationMethodMutation__
 *
 * To run a mutation, you first call `useSetReservationMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReservationMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReservationMethodMutation, { data, loading, error }] = useSetReservationMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetReservationMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetReservationMethodMutation, SetReservationMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReservationMethodMutation, SetReservationMethodMutationVariables>(SetReservationMethodDocument, options);
      }
export type SetReservationMethodMutationHookResult = ReturnType<typeof useSetReservationMethodMutation>;
export type SetReservationMethodMutationResult = Apollo.MutationResult<SetReservationMethodMutation>;
export type SetReservationMethodMutationOptions = Apollo.BaseMutationOptions<SetReservationMethodMutation, SetReservationMethodMutationVariables>;
export const ExportVehiclesDocument = gql`
    mutation ExportVehicles {
  exportVehicles {
    message
  }
}
    `;
export type ExportVehiclesMutationFn = Apollo.MutationFunction<ExportVehiclesMutation, ExportVehiclesMutationVariables>;

/**
 * __useExportVehiclesMutation__
 *
 * To run a mutation, you first call `useExportVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportVehiclesMutation, { data, loading, error }] = useExportVehiclesMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportVehiclesMutation(baseOptions?: Apollo.MutationHookOptions<ExportVehiclesMutation, ExportVehiclesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportVehiclesMutation, ExportVehiclesMutationVariables>(ExportVehiclesDocument, options);
      }
export type ExportVehiclesMutationHookResult = ReturnType<typeof useExportVehiclesMutation>;
export type ExportVehiclesMutationResult = Apollo.MutationResult<ExportVehiclesMutation>;
export type ExportVehiclesMutationOptions = Apollo.BaseMutationOptions<ExportVehiclesMutation, ExportVehiclesMutationVariables>;
export const ResubmitReviewDocument = gql`
    mutation ResubmitReview {
  resubmitReview {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type ResubmitReviewMutationFn = Apollo.MutationFunction<ResubmitReviewMutation, ResubmitReviewMutationVariables>;

/**
 * __useResubmitReviewMutation__
 *
 * To run a mutation, you first call `useResubmitReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitReviewMutation, { data, loading, error }] = useResubmitReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useResubmitReviewMutation(baseOptions?: Apollo.MutationHookOptions<ResubmitReviewMutation, ResubmitReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubmitReviewMutation, ResubmitReviewMutationVariables>(ResubmitReviewDocument, options);
      }
export type ResubmitReviewMutationHookResult = ReturnType<typeof useResubmitReviewMutation>;
export type ResubmitReviewMutationResult = Apollo.MutationResult<ResubmitReviewMutation>;
export type ResubmitReviewMutationOptions = Apollo.BaseMutationOptions<ResubmitReviewMutation, ResubmitReviewMutationVariables>;
export const CreditCheckDocument = gql`
    mutation CreditCheck($userId: String!) {
  creditCheck(userId: $userId) {
    error
  }
}
    `;
export type CreditCheckMutationFn = Apollo.MutationFunction<CreditCheckMutation, CreditCheckMutationVariables>;

/**
 * __useCreditCheckMutation__
 *
 * To run a mutation, you first call `useCreditCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditCheckMutation, { data, loading, error }] = useCreditCheckMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreditCheckMutation(baseOptions?: Apollo.MutationHookOptions<CreditCheckMutation, CreditCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreditCheckMutation, CreditCheckMutationVariables>(CreditCheckDocument, options);
      }
export type CreditCheckMutationHookResult = ReturnType<typeof useCreditCheckMutation>;
export type CreditCheckMutationResult = Apollo.MutationResult<CreditCheckMutation>;
export type CreditCheckMutationOptions = Apollo.BaseMutationOptions<CreditCheckMutation, CreditCheckMutationVariables>;
export const VerifyFacialIdentityDocument = gql`
    mutation VerifyFacialIdentity($userId: String!) {
  verifyFacialIdentity(userId: $userId) {
    error
  }
}
    `;
export type VerifyFacialIdentityMutationFn = Apollo.MutationFunction<VerifyFacialIdentityMutation, VerifyFacialIdentityMutationVariables>;

/**
 * __useVerifyFacialIdentityMutation__
 *
 * To run a mutation, you first call `useVerifyFacialIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyFacialIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyFacialIdentityMutation, { data, loading, error }] = useVerifyFacialIdentityMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVerifyFacialIdentityMutation(baseOptions?: Apollo.MutationHookOptions<VerifyFacialIdentityMutation, VerifyFacialIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyFacialIdentityMutation, VerifyFacialIdentityMutationVariables>(VerifyFacialIdentityDocument, options);
      }
export type VerifyFacialIdentityMutationHookResult = ReturnType<typeof useVerifyFacialIdentityMutation>;
export type VerifyFacialIdentityMutationResult = Apollo.MutationResult<VerifyFacialIdentityMutation>;
export type VerifyFacialIdentityMutationOptions = Apollo.BaseMutationOptions<VerifyFacialIdentityMutation, VerifyFacialIdentityMutationVariables>;